import request from '@/utils/request'

// 获取价格涨幅列表
export function getLastPrice() {
    return request({
        url: '/app/cryptoData/lastPrice',
        method: 'get'
    })
}

// 获取盈利列表
export function getProfitRatesList() {
  return request({
    url: '/app/profitRates/list',
    method: 'get'
  })
}

// 下单
export function postOrder(data) {
  return request({
    url: '/app/order',
    method: 'post',
    data: data
  })
}

// 获取订单列表
export function getOrderList(data) {
  return request({
    url: '/app/order/list',
    method: 'get',
    params: data
  })
}

// 获取k线图数据
export function getKline(data) {
  return request({
    url: '/app/cryptoData/kline',
    method: 'get',
    params: data
  })
}

//
export function getKlines(data) {
  return request({
    url: '/app/cryptoData/klines',
    method: 'get',
    params: data
  })
}


//
export function currencyConversion(data) {
  return request({
    url: '/app/wallet/currencyConversion',
    method: 'put'
  })
}

export function sendWelcome(data) {
  return request({
    url: '/app/socket/sendWelcome',
    method: 'post',
    data
  })
}


export function getTime() {
  return request({
    url: '/app/index/getTime',
    method: 'get'
  })
}

export function getButtonList() {
  return request({
    url: '/app/button/list',
    method: 'get'
  })
}

export function cryptoDataMappingList() {
  return request({
    url: '/app/cryptoDataMapping/list',
    method: 'get'
  })
}
export function dataShamList(data) {
  return request({
    url: '/app/dataSham/list',
    method: 'get',
    params: data
  })
}
export function addOrderShare(data) {
  return request({
    url: '/app/orderShare/addOrderShare',
    method: 'post',
    data: data
  })
}
export function orderShareList(data) {
  return request({
    url: '/app/orderShare/list',
    method: 'get',
    params: data
  })
}

export function sellOrderShare(data) {
  return request({
    url: '/app/orderShare/sellOrderShare',
    method: 'put',
    data
  })
}


