export default {
    text1: "Inicio",
text2: "Suscripción a ICO",
text3: "Verificar",
text4: "Excavación en la nube",
text5: " Cotizaciones ",
text6: " Monedas ",
text7: " Comercio ",
text8: " Mío ",
text9: "Recarga rápida",
text10: "Recargar",
text11: "Nombre",
text12: "Precio más reciente",
text13: "Alza y baja",
text14: "Volumen ",
text15: "Comprar",
text16: "Vender",
text17: "Precio límite",
text18: "Precio de mercado",
text19: "Precio",
text20: "Número",
text21: "Tarifa de manejo",
text22: "Por favor ingrese el número",
text23: "Disponible",
text24: "Monto de la transacción",
text25: "Comprar BTC",
text26: "Vender BTC",
text27: "Nuevo acuerdo>",
text28: "Delegado actual",
text29: "Más",
text30: "Aún no hay registros",
text31: "Por favor ingrese el precio",
text32: "cancelar",
text33: "Operando al precio óptimo actual",
text34: "Contrato de entrega",
text35: "Alto",
text36: "Bajo",
text37: "24H",
text38: "segundo",
text39: "Tasa de beneficio",
text40: "Modo de transacción",
text41: "Cantidad de apertura",
text42: "Cuenta del segundo contrato",
text43: "Comprar arriba",
text44: "Comprar abajo",
text45: "En transacción",
text46: "Posición de negociación",
text47: "Por favor inicia sesión",
text48: "Bienvenido a QCM",
text49: "Crédito",
text50: "Cerrar sesión",
text51: "Mis activos",
text52: "Todos los activos",
text53: "Disponible",
text54: "Cuentas",
text55: "Universal",
text56: "Depósito",
text57: "Retirar monedas",
text58: "Transferencia de cuenta",
text59: "Intercambio",
text60: "Mi enlace compartido",
text61: "Centro de seguridad",
text62: "Vincular la dirección de retiro",
text63: "Libro blanco",
text64: "Enviar ticket",
text65: "Modo nocturno",
text66: " Depositar monedas ",
text67: "Seleccione el siguiente canal de recarga y la dirección de la billetera se actualizará periódicamente.",
text68: "Canal de recarga cooperativo oficial",
text69: " registro ",
text70: "registro de correo electrónico",
text71: "no se puede modificar después del registro",
text72: "Por favor ingrese su correo electrónico",
text73: "Por favor ingrese la contraseña",
text74: "Por favor ingrese la contraseña de confirmación",
text75: "Por favor ingrese el código de verificación",
text76: "Por favor ingrese su número de teléfono",
text77: "código de invitación",
text78: "Recordar contraseña",
text79: "Estoy de acuerdo",
text80: "Acuerdo de usuario y política de privacidad",
text81: "enviar",
text82: "¿Olvidaste tu contraseña?",
text83: "iniciar sesión",
text84: "¿Todavía no eres QCM?",
text85: " ¿Olvidaste tu contraseña? ",
text86: "Siguiente",
text87: "Recuperación de buzón",
text88: "Restablecer contraseña",
text89: "Las contraseñas ingresadas dos veces no son iguales",
text90: "Por favor acepta el acuerdo",
text91: "Cierre de sesión exitoso",
text92: "Cerrar sesión",
text93: "¿Quieres confirmar tu cierre de sesión?",
text94: "Confirmar",
text95: "Cancelar",
text96: "Avisos del sistema",
text97: "El estado de inicio de sesión ha expirado, por favor inicie sesión nuevamente",
text98: "La interfaz de backend está conectada de manera anormal",
text99: "La solicitud de interfaz del sistema ha caducado",
text100: "anormal",
text101: "Interfaces del sistema",
text102: "Verificación de identidad",
text103: "Complete la verificación de identidad para acceder a los servicios",
text104: "Licencia de conducir",
text105: "Documento de identificación",
text106: "Pasaporte",
text107: "Permiso de residencia",
text108: "Requisitos",
text109: "Utilice su documento de identificación emitido por el gobierno válido para la verificación",
text110: "Frente",
text111: "Reverso",
text112: "Enviar",
text113: "minuto",
text114: "hora",
text115: "día",
text200: "Depositar monedas",
text201: "Dirección de la moneda",
text202: "Copiar dirección",
text203: "Llegar a la dirección de la billetera",
text204: "Por favor, ingrese la dirección de llegada",
text205: "Número de depósitos",
text206: "Por favor, ingrese la cantidad del depósito",
text207: "Subir una imagen",
text208: "haga clic para subir imágenes",
text209: "Subir captura de pantalla de la transferencia",
text210: "Copia exitosa",
text211: "Retirar monedas",
text212: "transferir",
text213: "desde",
text214: "a",
text215: "Cuenta de monedas",
text216: "Cuenta de contrato",
text217: "Cuenta de futuros",
text218: "Cancelar",
text219: "Hecho",
text220: "Cantidad de transferencia",
text221: "Por favor, ingrese la cantidad de transferencia",
text222: "Todo",
text223: "disponible",
text224: "registro de transferencia",
text225: "número",
text226: "Registro",
text227: "Hora",
text228: "Retirar",
text229: "Retiro",
text230: "Registro de retiros",
text231: "Tipo de cadena",
text232: "Dirección de retiro",
text233: "contraseña de transacción",
text234: "Cantidad recibida",
text235: "Por favor, configure su dirección de retiro primero",
text236: "Ir a configurar",
text237: "Cantidad mínima de retiro 10.000000",
text238: "Por favor, ingrese la contraseña de la transacción",
text239: "Por favor, cargue su comprobante",
text240: "La presentación es exitosa y está esperando revisión",
text241: "Confirmar orden",
text242: "Tipo",
text243: "La compra fue exitosa",
text244: "ganar",
text245: "perder",
text246: "empatar",
text247: "Cuenta de futuros",
text248: "Total de activos convertidos",
text249: "En revisión",
text250: "plegado",
text251: "congelar",
text252: "registros financieros",
text253: "recargar",
text254: "pagos",
text255: "hacer un pedido",
text256: "cuenta de contrato",
text257: "Restablecer contraseña",
text258: "contraseña de inicio de sesión",
text259: "modificar",
text260: "Por favor ingrese la contraseña antigua",
text261: "Por favor ingrese la contraseña",
text262: "Por favor confirme la contraseña",
text263: "La modificación se realizó con éxito, por favor inicie sesión nuevamente",
text264: "Por favor ingrese la cantidad",
text265: "Saldo insuficiente",
text266: "Estado del pago",
text267: "Auditoría",
text268: "Aprobado",
text269: "Rechazado",
text270: "Motivo del rechazo",
text271: "Detalles",
text272: "Contratos perpetuos",
text273: "Operar al mejor precio actual",
text274: "Lotes de negociación",
text275: "ir largo",
text276: "corto",
text277: "Operar",
text278: "Saldo",
text279: "lotes",
text280: "Múltiple",
text281: "Margen",
text282: "Por favor seleccione o ingrese el número de manos",
text283: "Por favor ingrese el precio de comisión",
text284: "Por favor contacte al servicio al cliente",
text285:'Servicio',
text286:'convertir',
text287:'ETH Convertir a USDT',
text288:'Centro comercial del molino',
text289:'Se espera que Nissan',
text290:'Alquiler de molinos',
text291:'Suscribirse',
text292:'Período de minería',
text293:'La compra máxima',
text294:'Bloqueado, por favor contacte al servicio al cliente',
text296:'Introducción del producto',
text297:'Cantidad de bloqueo',
text298:'Lista de suscripción',
text299: "Bajo revisión",
text300: "Por pagar",
text301: "Bloqueado",
text302: "Cancelado",
text303: "rescatado",
text304:'Cantidad',
text305:'El tamaño del archivo no puede superar los 10M',
text306:'Excavar tesoros y realizar pedidos',
text307: 'Retorno del principal', // 
text308: 'Ganancias de caza del tesoro', //
text309: 'Retorno de la caza del tesoro',
text310:'Haga clic para obtener más registros de chat',
text311:'No queda nada más',
text312:'Hola, ¿puedo ayudarte',
text313:"Songschrift {n}",
text314:"Beneficio",
"text315": "Límite de crédito",
  "text316": "Usado",
  "text317": "Pago",
  "text318": "Cargo de servicio",
  "text319": "Tasa de servicio",
  "text320": "Pago exitoso",
  "text321": "Regalo del sistema",
  "text322": "Uso del límite de crédito",
  "text323": "Recuperación del límite",
  "text234": "Cantidad congelada",
  "text325": "Congelar",
  "text326": "Descongelación",
// text313: "Recarga del backend",
// text314: "Retiro V2",
// text315: "Rechazo de retiro",
// text316: "Ganar una orden",
// text317: "Sencillo plano",
userTitle: "Política de privacidad de QCM",
userAgreement:`
<p>1. QCM&nbsp;Intercambio</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>QCM&nbsp;Intercambio(en adelante, "plataforma", "nosotros" o "nosotros") permiten a los usuarios (en adelante, "usuarios" o "usted") comerciar con activos digitales (definidos en este acuerdo) y proporcionar otros servicios relacionados con activos digitales (en adelante, servicios). Por conveniencia, usted y nosotros nos llamamos "partes" en este acuerdo y "partes" por separado.</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>La plataforma es un ecosistema que incluye sitios web de la plataforma, aplicaciones móviles, clientes y otras aplicaciones desarrolladas para proporcionar servicios, así como plataformas, sitios web y clientes que funcionan de forma independiente dentro del ecosistema. Si las condiciones de uso pertinentes de la plataforma mencionada son incompatibles con el presente acuerdo, prevalecerán las condiciones aplicables respectivas de la plataforma.</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>"QCM&nbsp;Operadores" Se refiere a todas las Partes que operan la plataforma, incluidas, pero no limitadas a, las personas jurídicas, las organizaciones no jurídicas y los equipos que prestan y son responsables de los servicios. Para evitar dudas, las referencias a "plataforma", "nosotros" o "nosotros" incluyen qcm; Operadores Según el acuerdo, el operador de qcm; La red puede cambiar a medida que nuestro negocio se ajuste. En este caso, el operador modificado cumplirá con sus obligaciones en virtud de este acuerdo y le prestará servicios, y el operador cambiado no afectará sus derechos en virtud de este acuerdo. Además, el alcance de qcm; Los operadores autorizados pueden ampliarse por la prestación de nuevos servicios. En este caso, si continúa utilizando el servicio, se considerará que está de acuerdo en ejecutar este acuerdo con el qcm recién agregado; Operadores autorizados. En caso de controversia, debe determinar el tema de este acuerdo con usted y la otra parte en disputa en función del servicio específico Lo que utiliza y las operaciones específicas que afectan sus derechos.</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>Entiende que este sitio web (definido en este acuerdo) solo sirve como plataforma para que use el servicio. Debe evaluar cuidadosamente la autenticidad, legitimidad y validez de los activos digitales y / o información relevantes y asumir las posibles responsabilidades y pérdidas resultantes.</p>
<p>
	<br>
</p>
<p>Ninguna de las opiniones, materiales, discusiones, análisis, precios y otra información mostrada en la Plataforma debe interpretarse como una propuesta de inversión. No asumimos ninguna responsabilidad por cualquier daño (incluido, pero no limitado a, cualquier beneficio perdido) causado directa o indirectamente por confiar en la información anterior.</p>
<p>
	<br>
</p>
<p>A menos que tenga una cuenta de plataforma (según se define en este acuerdo), no puede usar los servicios prestados a los usuarios con cuentas.</p>
<p>
	<br>
</p>
<p>1.1 Peligro</p>
<p>
	<br>
</p>
<p>El uso del servicio a través de la plataforma significa que ha aceptado los riesgos relacionados con el servicio. Destacamos:</p>
<p>
	<br>
</p>
<p>(I) los activos digitales proporcionados en la plataforma pueden no ser emitidos por nosotros, instituciones financieras u otras entidades jurídicas;</p>
<p>
	<br>
</p>
<p>(II) el mercado de activos digitales está surgiendo, es incierto y puede que no crezca;</p>
<p>
	<br>
</p>
<p>(III) los activos digitales son altamente especulativos y no son tan maduros como otras categorías de activos;</p>
<p>
	<br>
</p>
<p>(Iv) los activos digitales son inestables y pueden negociarse continuamente con fluctuaciones ilimitadas de los precios;</p>
<p>
	<br>
</p>
<p>(v) las transacciones de activos digitales pueden causar que pierda parte o la totalidad de su capital;</p>
<p>
	<br>
</p>
<p>(VI) comportamiento de terceros, como los creadores de mercado y los gobiernos, puede tener un impacto significativo en los activos digitales;</p>
<p>
	<br>
</p>
<p>(Vii) las transacciones de activos digitales no son adecuadas para la gran mayoría de las personas;</p>
<p>
	<br>
</p>
<p>(viii) aunque nos esforzamos por cumplir con todas las leyes, reglas y regulaciones aplicables, es posible que no estemos regulados en todas las jurisdicciones donde operamos;</p>
<p>
	<br>
</p>
<p>(9) además de los riesgos mencionados, pueden existir otros riesgos impredecibles o derivados.</p>
<p>
	<br>
</p>
<p>Antes de usar el servicio, considere su propia situación financiera y, si es necesario, busque asesoramiento legal, financiero, fiscal, contable y de otro tipo independiente.</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>1.Cláusula general</p>
<p>
	<br>
</p>
<p>Acuerdo de usuario ("acuerdo" o "estos términos y condiciones") se actualiza de vez en cuando, incluyendo este acuerdo de usuario, apéndice, política de privacidad, reglas legales, usuarios que conocen su política de cliente, política contra el blanqueo de dinero, acuerdos de servicio o términos y condiciones, cualquier otra guía, reglas, declaraciones y explicaciones publicadas de vez en cuando en la plataforma.</p>
<p>
	<br>
</p>
<p>Antes de usar el servicio a través de la plataforma, debe leer cuidadosamente este acuerdo y buscar asesoramiento legal independiente si es necesario. Si no está de acuerdo o no entiende este acuerdo, cancele su cuenta de inmediato y deje de usar el servicio. Al hacer clic en "estoy de acuerdo", crear una cuenta, obtener el ID de su cuenta, establecer una contraseña, iniciar sesión en su cuenta o usar el servicio, se considerará que entiende, cumple y está de acuerdo plenamente con este acuerdo (incluidas cualquier modificación de este acuerdo de vez en cuando).</p>
<p>
	<br>
</p>
<p>Usted es responsable de cumplir con las leyes locales y todas las leyes, reglamentos y regulaciones aplicables relacionados con el uso de los servicios. Al utilizar el servicio, reconoce y reconoce que todas sus fuentes de financiación son legales y no provienen de actividades ilegales. Usted está de acuerdo en que tenemos derecho a recopilar la información necesaria y ponerla a disposición de las autoridades competentes para verificar las fuentes de financiación y la legalidad del uso de los fondos.</p>
<p>
	<br>
</p>
<p>Todos los títulos de este acuerdo son solo por conveniencia y no tienen como objetivo ampliar o limitar el contenido o el alcance de los términos de este acuerdo. Toda la información en la Plataforma y en este acuerdo está disponible "como está" y "está disponible", y ya no asumimos ningún compromiso. Los títulos de este acuerdo son solo por conveniencia, independientemente del significado o la interpretación de cualquier cláusula de este acuerdo.</p>
<p>
	<br>
</p>
<p>2. Definición</p>
<p>
	<br>
</p>
<p>"Cuenta" se refiere a su cuenta en la plataforma.</p>
<p>
	<br>
</p>
<p>"protocolo" se refiere al Protocolo de usuario de la Plataforma firecoin.</p>
<p>
	<br>
</p>
<p>"anexo" enumera los acuerdos individuales de servicio de productos que la plataforma ofrece a los usuarios.</p>
<p>
	<br>
</p>
<p>"Aml / ctf" se refiere a la lucha contra el blanqueo de dinero / la financiación del terrorismo.</p>
<p>
	<br>
</p>
<p>"Día hábil" significa un día que no sea sábado, domingo o un día bancario declarado como día festivo público.</p>
<p>
	<br>
</p>
<p>"Precio de compra" tiene el significado en la cláusula 7.1.</p>
<p>
	<br>
</p>
<p>"Indemnización" tiene el significado dado en la cláusula 15.1.</p>
<p>
	<br>
</p>
<p>"Procesamiento de cuenta" tiene el significado establecido en la cláusula 4.4.</p>
<p>
	<br>
</p>
<p>"Activo digital" significa una representación digital de valor que puede ser transferida, almacenada y negociada digitalmente en la Plataforma.</p>
<p>
	<br>
</p>
<p>"Disputa" tiene el significado en la cláusula 17.11.</p>
<p>
	<br>
</p>
<p>"Tipo de cambio" tiene el significado dado en la cláusula 7.1.</p>
<p>
	<br>
</p>
<p>"Transacción en moneda fiat" se refiere a una transacción entre pares, depositando moneda fiat en una cuenta para comprar rápidamente activos digitales a través de proveedores de liquidez o plataformas de terceros.</p>
<p>
	<br>
</p>
<p>"Bifurcación" tiene el significado en la cláusula 6.2.</p>
<p>
	<br>
</p>
<p>"Corte Internacional de Arbitraje de la ICC" tiene el significado establecido en el Artículo 17.11.</p>
<p>
	<br>
</p>
<p>"Información de identificación" tiene el significado establecido en el Artículo 10.</p>
<p>
	<br>
</p>

<p>Consulte el Artículo 11 para el significado de "Derechos de Propiedad Intelectual".</p>
<p>
	<br>
</p>
<p>"Resolución Interna" tiene el significado dado en la cláusula 17.11.</p>
<p>
	<br>
</p>
<p>"Información de Inicio de Sesión" tiene el significado en la Sección 4.3.</p>
<p>
	<br>
</p>
<p>El significado de "Tarifa del Minero" se establece en el Artículo 6.1.</p>
<p>
	<br>
</p>
<p>"Parte" significa las partes de este Acuerdo, el Usuario y la Plataforma.</p>
<p>
	<br>
</p>
<p>"Plataforma" significa la Plataforma Global de Huobi.</p>
<p>
	<br>
</p>
<p>Consulte la cláusula 4.4 para el significado de "tiempo antes de cerrar una posición".</p>
<p>
	<br>
</p>
<p>"Información de Registro" tiene el significado en el Artículo 4.1.</p>
<p>
	<br>
</p>
<p>"Estado Sancionado" tiene el significado dado en el Artículo 17.8.</p>
<p>
	<br>
</p>
<p>"Persona Sancionada" tiene el significado dado en el Artículo 17.8.</p>
<p>
	<br>
</p>
<p>"Precio de Venta" tiene el significado en la cláusula 7.1.</p>
<p>
	<br>
</p>
<p>"Servicios" se refieren a los productos y servicios proporcionados a los usuarios a través de la plataforma (consulte el apéndice para más detalles).</p>
<p>
	<br>
</p>
<p>"Tarifa de Transacción" tiene el significado establecido en la Cláusula 8.</p>
<p>
	<br>
</p>
<p>"Transacción" tiene el significado establecido en la cláusula 4.2.</p>
<p>
	<br>
</p>
<p>"Usuario" se refiere al usuario de la Plataforma Global de Huobi.</p>
<p>
	<br>
</p>
<p>"Sitio" significa un sitio web u otro portal, como una aplicación móvil, a través del cual los usuarios pueden acceder a la Plataforma y utilizar los Servicios.</p>
<p>
	<br>
</p>
<p>3. Cuenta</p>
<p>
	<br>
</p>
<p>3.1 Crear una cuenta</p>
<p>
	<br>
</p>
<p>Para ser elegible para crear una cuenta, debe confirmar que es una persona natural, jurídica o cualquier otra entidad autorizada para firmar este Acuerdo y utilizar los Servicios de acuerdo con todas las leyes, reglas y regulaciones aplicables. No puede crear una cuenta con el propósito de violar ninguna ley, regla y/o regulación aplicable y/o interrumpir los servicios proporcionados por la plataforma.</p>
<p>
	<br>
</p>
<p>Si no es elegible para crear una cuenta, nos reservamos el derecho de rechazar su registro de cuenta; si ha creado una cuenta, nos reservamos el derecho de suspender, terminar y/o cerrar su cuenta sin previo aviso, y requerir que usted sea responsable de cualquier consecuencia adversa que surja de ello. Nos reservamos el derecho, a nuestra entera discreción, de rechazar cualquier solicitud para crear una cuenta (incluidas, entre otras, las cuentas asociadas con figuras políticas y/o figuras públicas), o para cumplir con las leyes y regulaciones contra el lavado de dinero/financiamiento del terrorismo.</p>
<p>
	<br>
</p>
<p>Para crear una cuenta, debe proporcionar la siguiente información necesaria:</p>
<p>
	<br>
</p>

<p>(i) una dirección de correo electrónico válida;</p>
<p>
	<br>
</p>
<p>(ii) nombre real;</p>
<p>
	<br>
</p>
<p>(iii) prueba de identidad;</p>
<p>
	<br>
</p>
<p>(iv) cualquier otra información requerida para crear y mantener la Cuenta;</p>
<p>
	<br>
</p>
<p>Colectivamente denominados "Materiales de Registro".</p>
<p>
	<br>
</p>
<p>Si su número de teléfono móvil requiere registro con nombre real, debe completar dicho registro antes de proporcionarnos su número de teléfono móvil. Usted será responsable de cualquier pérdida directa o indirecta y de cualquier consecuencia adversa que resulte de la no inscripción.</p>
<p>
	<br>
</p>
<p>Es su responsabilidad actualizar su información de registro para asegurarse de que permanezca precisa. Usted es responsable de la autenticidad, integridad y exactitud de la información de registro, y soporta cualquier pérdida directa o indirecta y consecuencias adversas causadas por información de registro inexacta.</p>
<p>
	<br>
</p>
<p>Después de que la creación de la cuenta sea verificada y aprobada, recibirá un ID de cuenta y una contraseña para usar los Servicios. Usted acepta recibir correos electrónicos y/o información de nosotros relacionada con la administración y operación de la Plataforma.</p>
<p>
	<br>
</p>
<p>3.2 Uso de la cuenta</p>
<p>
	<br>
</p>
<p>Usted tiene derecho a usar su cuenta para acceder a la plataforma y usar los servicios, incluyendo:</p>
<p>
	<br>
</p>
<p>(i) navegar la cotización en tiempo real e información de transacciones de productos de activos digitales en este sitio web;</p>
<p>
	<br>
</p>
<p>(ii) enviar una transacción de activos digitales, ya sea para realizar una transacción o hacer otra instrucción (cada una, una "Transacción");</p>
<p>
	<br>
</p>
<p>(iii) Completar la Transacción;</p>
<p>
	<br>
</p>
<p>(iv) usar la cuenta para ver la información proporcionada al usuario;</p>
<p>
	<br>
</p>
<p>(v) participar en eventos organizados por nosotros de acuerdo con las reglas de eventos relevantes; y/o</p>
<p>
	<br>
</p>
<p>(vi) otros servicios que podamos proporcionarle de vez en cuando.</p>
<p>
	<br>
</p>
<p>3.3 Seguridad de la cuenta</p>
<p>
	<br>
</p>
<p>Usted es el único responsable de la información de inicio de sesión asociada con su Cuenta, incluyendo nombre de usuario, contraseña, número de teléfono móvil, contraseña de transacción y códigos de verificación recibidos a través de su teléfono móvil ("Información de Inicio de Sesión"). Si su información de inicio de sesión se ve comprometida y/o su cuenta es accedida por un tercero no autorizado, puede notificarnos de inmediato y solicitar la suspensión de la cuenta. Después de recibir tal solicitud, procesaremos su solicitud dentro de un tiempo razonable. Sin embargo, no somos responsables de las consecuencias que ocurran antes de que su cuenta sea suspendida.</p>
<p>
	<br>
</p>
<p>No puede donar, prestar, arrendar, ceder o transferir de otra manera la Cuenta a ninguna otra persona excepto con nuestro consentimiento.</p>
<p>
	<br>
</p>
<p>No estamos obligados a publicar ningún activo digital sustituto si algún activo digital, contraseña o clave se pierde, es robado, no funciona, es destruido o no está disponible de otra manera.</p>
<p>
	<br>
</p>
<p>3.4 Suspensión, Terminación o Cierre</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de suspender parte o la totalidad de los servicios proporcionados a la cuenta, congelar activos digitales en la cuenta, terminar la cuenta, cerrar la cuenta y/o notificar a las autoridades apropiadas ("Manejo de Cuenta") si:</p>
<p>
	<br>
</p>
<p>(i) tenemos motivos para creer que necesitamos hacerlo para proteger nuestra reputación;</p>
<p>
	<br>
</p>
<p>(ii

<p>(x) usted utiliza la cuenta para participar en manipulación del mercado, operaciones comerciales impropias u otras actividades comerciales ilegales, como el lavado de dinero, el contrabando y el soborno comercial; y/o</p>
<p>
	<br>
</p>
<p>(xi) Su incumplimiento de este Acuerdo u cualquier otra ley, norma y regulación aplicable.</p>
<p>
	<br>
</p>
<p>No obstante lo anterior, nos reservamos el derecho de cerrar y terminar su cuenta con 7 días hábiles de anticipación. Luego, tiene 7 días hábiles para tomar cualquier acción necesaria, incluyendo la cancelación de cualquier orden o el cierre de posiciones ("Hora de Cierre Previo"). Después de que termine el período de pre-cierre, tenemos el derecho de cancelar su orden, cerrar la posición y devolverle los activos digitales en su cuenta. No obstante cualquier disposición en este Acuerdo, si su cuenta ha sido cerrada de acuerdo con estos términos, todos los saldos de la cuenta (incluidos los honorarios y deudas adeudadas a nosotros) se nos pagarán de inmediato.</p>
<p>
	<br>
</p>
<p>No somos responsables de ninguna consecuencia adversa y/o pérdidas relacionadas con el procesamiento de la cuenta.</p>
<p>
	<br>
</p>
<p>En caso de suspensión o cierre de cuenta debido a investigaciones de fraude, investigaciones ilegales o incumplimientos de este Acuerdo, tendremos custodia total de los activos digitales, fondos e información/datos del usuario que puedan ser entregados a las autoridades gubernamentales.</p>
<p>
	<br>
</p>
<p>4. Depósito, Depósito en Garantía, Retiro y Transferencia</p>
<p>
	<br>
</p>
<p>Los servicios solo están disponibles para activos digitales que respaldamos, y tales activos digitales pueden cambiar de vez en cuando. En ningún caso podrá utilizar su cuenta para almacenar, enviar, solicitar o recibir activos digitales en cualquier forma no respaldada por nosotros. No somos responsables ni nos hacemos responsables de ningún intento de utilizar la cuenta para activos digitales que no respaldamos. Le proporcionamos servicios y no somos comprador ni vendedor de ninguna transacción de activos digitales. Los servicios no incluyen ningún depósito, retiro o custodia de moneda fiduciaria.</p>
<p>
	<br>
</p>
<p>Todos los activos digitales en la cuenta son alojados por nosotros, lo que significa:</p>
<p>
	<br>
</p>
<p>(i) El título de los activos digitales permanece con usted y no se transfiere a nosotros. Dado que el propietario de los activos digitales es su cuenta, usted debe asumir todo el riesgo de pérdida de dichos activos digitales. No afirmamos que los activos en la cuenta nos pertenezcan, ni serán para uno mismo;</p>
<p>
	<br>
</p>
<p>(ii) no podemos otorgar intereses de seguridad en los activos digitales en la Cuenta;</p>
<p>
	<br>
</p>
<p>(iii) no venderemos, transferiremos, prestaremos, hipotecaremos ni transferiremos de otra manera los activos en la Cuenta a menos que lo exijan las autoridades pertinentes o se disponga de otra manera en este Acuerdo, excepto según lo indique usted o lo exija un tribunal de jurisdicción competente;</p>
<p>
	<br>
</p>
<p>(iv) Usted controla los activos digitales en la Cuenta. Puede retirar sus activos digitales en cualquier momento enviándolos a otra dirección de blockchain controlada por usted o un tercero, sujeto a interrupciones, tiempo de inactividad y otras políticas aplicables;</p>
<p>
	<br>
</p>
<p>(v) Con el fin de mantener de manera más segura los activos en la cuenta, separe sus activos digitales de nuestros propios activos digitales o fondos a través de entradas de contabilidad de libro mayor separadas para la cuenta de usuario y nuestra cuenta. No obstante lo anterior, no estamos obligados a utilizar diferentes direcciones de blockchain para almacenar sus activos digitales y los de otros clientes o los nuestros.</p>
<p>
	<br>
</p>
<p>Reservamos el derecho de establecer y ajustar los límites diarios de volumen de transacciones y retiros en función de consideraciones internas, incluida la seguridad y el estado de la red de activos digitales subyacente.</p>
<p>
	<br>
</p>
<p>6. Red de Activos Digitales</p>
<p>
	<br>
</p>
<p>6.1 Transacción de Activos Digitales</p>
<p>
	<br>
</p>
<p>Procesaremos la transacción según sus instrucciones. Debe verificar toda la información de la transacción antes de darnos instrucciones. No garantizamos la identidad de ningún usuario, destinatario, solicitante u otro tercero, ni tenemos ninguna responsabilidad u obligación de garantizar que la información que proporcione sea precisa y completa. Una vez que una transacción se propaga a la red de activos digitales relevante, no se puede revertir.</p>
<p>
	<br>
</p>
<p>Podemos cobrar tarifas de red ("tarifas de minero") para realizar transacciones en su nombre. Calcularemos la tarifa del minero por nuestra cuenta, pero le informaremos de la tarifa del minero cuando (o antes) autorice transacciones de activos digitales. Cuando usted o un tercero envíen activos digitales a una cuenta a través de una billetera externa que no esté alojada por la plataforma, el originador de la transacción es el único responsable de la ejecución correcta de la transacción, lo que puede incluir el pago de tarifas de minero para la finalización exitosa de la transacción. La falta de pago de tarifas de minero puede dejar sus transacciones en un estado pendiente fuera de nuestro control, y no somos responsables de retrasos o pérdidas debido a errores en la iniciación de la transacción, ni estamos obligados a ayudar a remediar tales transacciones.</p>
<p>
	<br>
</p>
<p>Una vez que se envía una transacción a la red de activos digitales, la transacción permanecerá sin confirmar durante un período de tiempo hasta que la red de activos digitales confirme completamente la transacción. Una transacción está pendiente, lo que significa que la transacción no se ha completado. En consecuencia, los activos digitales asociados con transacciones que están en un estado pendiente se indicarán y no se incluirán en los saldos de cuenta y no se podrán realizar transacciones hasta que la red los confirme.</p>
<p>
	<br>
</p>
<p>También podemos negarnos a celebrar o cancelar cualquier transacción pendiente según lo exija la ley, la regulación o cualquier tribunal u otra autoridad de jurisdicción competente si se sospecha de lavado de dinero, financiación del terrorismo, fraude o cualquier otro delito financiero.</p>
<p>
	<br>
</p>
<p>6.2 Funcionamiento del Protocolo de Activos Digitales</p>
<p>
	<br>
</p>
<p>No poseemos ni controlamos los protocolos de software subyacentes que rigen las operaciones de los activos digitales admitidos en la plataforma. Típicamente, el protocolo subyacente es un protocolo "de código abierto" que puede ser utilizado, copiado, modificado y distribuido por todos.</p>
<p>
	<br>
</p>
<p>No somos responsables del funcionamiento del protocolo subyacente y no podemos garantizar la funcionalidad o seguridad del funcionamiento de la red. Usted reconoce y acepta que los acuerdos de software subyacentes relacionados con cualquier activo digital que almacene en su cuenta pueden cambiar.</p>
<p>
	<br>
</p>
<p>En particular, el protocolo subyacente puede verse afectado por cambios repentinos ("bifurcaciones") en las reglas de operación. Cualquier cambio operacional material de este tipo puede afectar materialmente la disponibilidad, el valor, la funcionalidad y/o el nombre de los Activos Digitales que almacena en su Cuenta. No tenemos control sobre el momento y la naturaleza de estos cambios operativos importantes. Es su responsabilidad estar al tanto de los cambios operativos inminentes. Al decidir si continúa utilizando su cuenta para negociar activos digitales afectados, debe considerar cuidadosamente la información disponible públicamente y la información que podamos proporcionar. En caso de tales cambios operativos, nos reservamos el derecho de tomar las medidas necesarias para mantener la seguridad y la protección de los activos en la plataforma, incluidas las medidas necesarias como suspender temporalmente la operación de los activos digitales relevantes.</p>
<p>
	<br>
</p>
<p>Haremos nuestro mejor esfuerzo para notificarle nuestras acciones sobre cualquier cambio operativo importante. Sin embargo, estos cambios están fuera de nuestro control y pueden ocurrir sin nuestro conocimiento. Actuaremos a nuestra entera discreción con respecto a cualquier cambio operativo importante, incluida la decisión de no admitir nuevos activos digitales, bifurcaciones u otras operaciones.</p>
<p>
	<br>
</p>
<p>Usted reconoce y acepta los riesgos derivados de los cambios en el funcionamiento del acuerdo de activos digitales, y acepta que no seremos responsables de tales cambios en el funcionamiento, ni seremos responsables de ninguna pérdida de valor que sufra como resultado de tales cambios en las reglas de operación. Usted reconoce y acepta que tenemos el derecho de actuar sobre cualquier cambio operativo a nuestra entera discreción y que no tenemos la obligación de ayudarlo con activos o protocolos no admitidos.</p>
<p>
	<br>
</p>
<p>7. Reglas de Negociación</p>
<p>
	<br>
</p>
<p>Cuando negocie activos digitales en la plataforma, debe cumplir con las siguientes reglas:</p>
<p>
	<br>
</p>
<p>(i) Debe leer, comprender y aceptar completamente la información en la plataforma, incluidos, entre otros, los precios, las tarifas y si se están comprando o vendiendo activos digitales, antes de ejecutar la transacción;</p>
<p>
	<br>
</p>
<p>(ii) Cuando ejecute una transacción y envíe una transacción, autoriza a la plataforma a actuar como intermediario de la transacción relevante y a emparejar la contraparte relevante de la transacción sin previo aviso;</p>
<p>
	<br>
</p>
<p>(iii) tiene derecho a cancelar o modificar la transacción en cualquier momento antes de ejecutarla;</p>
<p>
	<br>
</p>
<p>(iv) Puede ver los detalles de la transacción y los registros en la Plataforma.</p>
<p>
	<br>
</p>

<p>7.1 Tipo de cambio</p>
<p>
	<br>
</p>
<p>Cada compra o venta de un activo digital está afectada por el tipo de cambio de esa transacción en particular. "Tipo de cambio" significa el precio de un activo digital específico admitido cotizado en moneda fiduciaria en la Plataforma. El tipo de cambio se denomina "precio de compra" o "precio de venta", y representa el precio al cual puede comprar o vender un activo digital, respectivamente.</p>
<p>
	<br>
</p>
<p>Usted reconoce que, en cualquier momento dado, el tipo de cambio del precio de compra puede diferir del tipo de cambio del precio de venta y que puede haber una diferencia o "spread" en el tipo de cambio cotizado. Al autorizar la transacción, acepta aceptar el tipo de cambio. No garantizamos ningún tipo de cambio. No garantizamos que pueda comprar y/o vender sus activos digitales en el mercado abierto a un precio particular o en un momento particular.</p>
<p>
	<br>
</p>
<p>8. Tarifas</p>
<p>
	<br>
</p>
<p>Usted acepta ser responsable de pagar todas las tarifas. Se cobra una tarifa ("tarifa de transacción") por cada compra o venta de activos digitales. Las tarifas de transacción aplicables se mostrarán en la plataforma antes de cada transacción, y cada recibo que le enviemos también indicará la tarifa de transacción aplicable. Podemos ajustar las tarifas de transacción en cualquier momento.</p>
<p>
	<br>
</p>
<p>9. Cumplimiento</p>
<p>
	<br>
</p>
<p>Nuestra Política de Conozca a su Cliente, Contra el Lavado de Dinero/Contra el Financiamiento del Terrorismo está diseñada para cumplir con las leyes y normas internacionales relacionadas con el Conozca a su Cliente y el Contra el Lavado de Dinero/Contra el Financiamiento del Terrorismo, incluidas las leyes locales de su jurisdicción. Cumpliremos con nuestras propias políticas de "conozca a su cliente", contra el lavado de dinero/contra el financiamiento del terrorismo, y haremos todo lo posible para tomar medidas y tecnologías para proporcionarle servicios de manera segura y confiable para prevenir cualquier pérdida debido al lavado de dinero.</p>
<p>
	<br>
</p>
<p>Usted nos autoriza a realizar tales investigaciones, directa o indirectamente a través de terceros, como consideremos necesarias para verificar su identidad o para protegerlo a usted y/o a nosotros de fraude u otro delito financiero, y a tomar tales medidas como consideremos razonablemente necesarias basadas en los resultados de las investigaciones. Cuando realicemos investigaciones, usted reconoce y acepta que su información personal puede ser revelada a agencias de crédito, agencias de prevención de fraude o delitos financieros, que pueden responder completamente a nuestras consultas. Esto es solo una verificación de identidad y no debería afectar negativamente su calificación crediticia. Además, podemos requerir que espere un período de tiempo después de completar una transacción antes de permitirle continuar utilizando los Servicios y/o permitirle hacer transacciones más allá de un límite de cantidad determinado.</p>
<p>
	<br>
</p>
<p>Si descubrimos que algún activo digital o fondos recibidos en su cuenta han sido robados o no están en su posesión legítima, podemos (pero no estamos obligados a) tomar control administrativo de los fondos afectados y su cuenta. Si colocamos algunos o todos sus activos digitales, fondos o cuentas en espera administrativa, podemos mantener la retención administrativa hasta que se resuelva la disputa y se proporcione evidencia de nuestra resolución aceptable en una forma aceptable para nosotros. No participaremos en tales disputas o resolución de disputas. Usted acepta que no somos responsables o responsables de dicha retención, ni de su incapacidad para retirar activos digitales, fondos o ejecutar transacciones durante el período de dicha retención.</p>
<p>
	<br>
</p>
<p>10. "Conozca a su cliente", AML/CFT</p>
<p>
	<br>
</p>
<p>Para el Conozca a su Cliente, AML/CFT, nosotros:</p>
<p>
	<br>
</p>
<p>(i) Las políticas de Conozca a su Cliente, Contra el Lavado de Dinero/Contra el Financiamiento del Terrorismo deben ser publicadas y actualizadas para cumplir con los estándares establecidos por las leyes, normas y regulaciones aplicables;</p>
<p>
	<br>
</p>
<p>(ii) Las directrices y reglas relacionadas con el funcionamiento de la plataforma se publicarán y actualizarán, y nuestro personal le brindará servicios de acuerdo con estas directrices y reglas;</p>
<p>
	<br>
</p>
<p>(iii) se deben diseñar e implementar procedimientos de monitoreo y control de transacciones (incluidos, entre otros, procedimientos estrictos de verificación de identidad), con un equipo dedicado para tratar problemas de lavado de dinero;</p>
<p>
	<br>
</p>
<p>(iv) adoptar un enfoque basado en el riesgo para la debida diligencia y el monitoreo de los usuarios;</p>
<p>
	<br>
</p>
<p>(v) el derecho a revisar e inspeccionar las transacciones hacia y desde nuestra plataforma;</p>
<p>
	<br>
</p>
<p>(vi) el derecho a informar a las autoridades pertinentes sobre las transacciones que juzguemos a nuestra entera discreción como sospechosas; y</p>
<p>
	<br>
</p>
<p>(vii) La Información de Inicio de Sesión y la Información de Identidad (según se define en este Acuerdo) se conservarán durante al menos 6 años.</p>
<p>
	<br>
</p>
<p>(viii) Los datos de registro e información de identificación (según se define en este Acuerdo) se conservarán durante al menos 6 años.</p>
<p>
	<br>
</p>
<p>Si usted es una persona física, recopilaremos cierta información de usted, incluida, entre otras, su "Información Personalmente Identificable":</p>
<p>
	<br>
</p>
<p>(i) nombre;</p>
<p>
	<br>
</p>
<p>(ii) la dirección (recopile la dirección permanente si es diferente de la suya). Su dirección (o dirección permanente) se verificará de la manera especificada por nosotros;</p>
<p>
	<br>
</p>
<p>(iii) una dirección de correo electrónico válida;</p>
<p>
	<br>
</p>
<p>(iv) fecha de nacimiento;</p>
<p>
	<br>
</p>
<p>(v) nacionalidad;</p>
<p>
	<br>
</p>
<p>(vi) su fotografía y documentos de identificación;</p>
<p>
	<br>
</p>
<p>(vii) documentos de identificación oficiales, incluidos pasaportes, tarjetas de identificación (anverso y reverso) y otros documentos de identificación emitidos por las autoridades pertinentes;</p>
<p>
	<br>
</p>
<p>(viii) otra información que podamos solicitarle de vez en cuando.</p>

<p>
	<br>
</p>
<p>Si es una entidad legal y no una persona física, recopilaremos cierta información de usted, incluida, entre otras, su "información de identidad empresarial":</p>
<p>
	<br>
</p>
<p>(i) documentos de registro e incorporación;</p>
<p>
	<br>
</p>
<p>(ii) los estatutos sociales o acta constitutiva;</p>
<p>
	<br>
</p>
<p>(iii) la estructura de propiedad y su descripción;</p>
<p>
	<br>
</p>
<p>(iv) una resolución del consejo de administración que designe a una persona autorizada responsable de la cuenta;</p>
<p>
	<br>
</p>
<p>(v) Documentos de identificación de directores, accionistas importantes y autorizadores. En general, es necesario identificar a los accionistas que poseen al menos el 10% de los derechos de voto de la entidad relevante;</p>
<p>
	<br>
</p>
<p>(vi) su dirección; y</p>
<p>
	<br>
</p>
<p>(vii) otra información que podamos solicitarle de vez en cuando,</p>
<p>
	<br>
</p>
<p>La "Información de Identidad Corporativa" y la "Información Personalmente Identificable" se denominan colectivamente "Información de Identidad".</p>
<p>
	<br>
</p>
<p>La información de identificación que le pedimos que proporcione puede variar según su jurisdicción, y también podemos solicitarle que tenga copias de los documentos que proporcione notariadas. Si no proporciona su información de identidad en inglés o chino, tenemos derecho a solicitar una versión en inglés notariada de su información de identidad.</p>
<p>
	<br>
</p>
<p>10.1 Informe de transacciones</p>
<p>
	<br>
</p>
<p>Monitorearemos su cuenta en busca de actividades de transacciones sospechosas. Si determinamos que hay una transacción sospechosa relacionada con su cuenta, podemos suspender, detener o cancelar la transacción, revertir la transacción y/o informar sobre la transacción a las autoridades pertinentes sin notificarle. Ninguna acción que tomemos con respecto a transacciones sospechosas resultará en un incumplimiento de este Acuerdo.</p>
<p>
	<br>
</p>
<p>11. Propiedad Intelectual</p>
<p>
	<br>
</p>
<p>Todos los derechos de propiedad intelectual relacionados con la Plataforma (incluidos, entre otros, el nombre del sitio web, el fondo de comercio, las marcas comerciales, los logotipos, las bases de datos, el diseño del sitio web, texto y gráficos, software, fotografías, video, música, sonido y cualquier combinación de lo anterior), y el software La propiedad intelectual ("Propiedad Intelectual") de la compilación, el código fuente asociado y el software (incluidas pequeñas aplicaciones y scripts) serán de nuestra propiedad. No puede reproducir, modificar, transmitir o utilizar ninguna propiedad intelectual con fines comerciales. Su uso de los Servicios no le otorga ningún derecho sobre los derechos de propiedad intelectual.</p>
<p>
	<br>
</p>
<p>Al aceptar este acuerdo, se considera que ha cedido exclusivamente a nosotros todos los derechos de autor en cualquier forma de información que publique en este sitio web, y tenemos el derecho de demandar por cualquier infracción de dicho derecho de autor y obtener una compensación completa por la conducta. Estos derechos de autor incluyen, entre otros, derechos de reproducción, derechos de distribución, derechos de alquiler, derechos de exhibición, derechos de interpretación, derechos de proyección, derechos de radiodifusión, derechos de comunicación a través de redes de información, derechos de filmación, derechos de adaptación, derechos de traducción y derechos de compilación disfrutados por el propietario del derecho de autor. transferencia de derechos. Este acuerdo se aplica a cualquier contenido que publique en este sitio web que esté protegido por la ley de derechos de autor, ya sea que ese contenido se haya generado antes o después de la firma de este acuerdo.</p>
<p>
	<br>
</p>
<p>No debe usar ni disponer ilegalmente de nuestra propiedad intelectual ni de la propiedad intelectual de ninguna otra persona al utilizar los Servicios. No puede publicar ni autorizar a otros sitios web o medios a utilizar la propiedad intelectual de ninguna manera.</p>
<p>
	<br>
</p>
<p>12. Derechos y obligaciones</p>
<p>
	<br>
</p>
<p>No obstante cualquier cosa en este Acuerdo, nos reservamos el derecho, a nuestra sola discreción:</p>
<p>
	<br>
</p>
<p>(i) modificar la información mostrada en el sitio web en caso de errores o actualizaciones;</p>
<p>
	<br>
</p>
<p>(ii) modificar, suspender o terminar cualquier o todos los servicios ofrecidos en cualquier momento sin previo aviso a usted. En caso de terminación, la terminación será efectiva en la fecha en que se anuncie la terminación en el sitio web;</p>
<p>
	<br>
</p>
<p>(iii) suspender o terminar permanentemente su acceso a la Cuenta y la Plataforma o su uso de cualquiera o todos los Servicios;</p>
<p>
	<br>
</p>
<p>(iv) notificarle que corrija o actualice la información que nos ha proporcionado si sospechamos o determinamos que la información que nos ha proporcionado es incorrecta, falsa, inválida y/o incompleta; si no corrige o actualiza la información relevante, suspenderemos o terminaremos la prestación de servicios a usted;</p>
<p>
	<br>
</p>
<p>(v) sin previo aviso a usted, eliminar cualquier contenido o información en la Plataforma que no cumpla con las leyes, normas y regulaciones aplicables;</p>
<p>
	<br>
</p>
<p>(vi) de acuerdo con las leyes, regulaciones y disposiciones aplicables
	<br>
</p>
<p>Por ejemplo, pedirle que proporcione información. Usted está obligado a ayudarnos en el procesamiento de dichas solicitudes;</p>

<p></p>
<p>
	<br>
</p>
<p>(vii) En beneficio de los usuarios, modificar los servicios proporcionados en caso de circunstancias especiales que impidan a la plataforma brindar servicios (como fallas del sistema, fallas de red o condiciones de mercado extremas). Se le notificará de tales modificaciones a través de un aviso en el sitio web.</p>
<p>
	<br>
</p>
<p>A menos que se disponga lo contrario en este Acuerdo, nosotros:</p>
<p>
	<br>
</p>
<p>(i) Tomaremos los medios técnicos y medidas de gestión necesarios para garantizar el funcionamiento normal de la plataforma y proporcionar un entorno necesario y confiable para que usted utilice el servicio;</p>
<p>
	<br>
</p>
<p>(ii) mantendremos los servicios proporcionados a usted;</p>
<p>
	<br>
</p>
<p>(iii) fortaleceremos nuestras capacidades técnicas y medidas de seguridad para garantizar que sus activos digitales estén en un estado seguro;</p>
<p>
	<br>
</p>
<p>(iv) le informaremos con anticipación sobre los riesgos de seguridad previsibles asociados con su cuenta.</p>
<p>
	<br>
</p>
<p>13. DECLARACIONES Y GARANTÍAS</p>
<p>
	<br>
</p>
<p>Al aceptar este Acuerdo, usted declara, garantiza y/o se compromete a que:</p>
<p>
	<br>
</p>
<p>(i) no ha suspendido o cancelado previamente su uso de los Servicios;</p>
<p>
	<br>
</p>
<p>(ii) es mayor de edad, competente y autorizado para celebrar este Acuerdo y utilizar los Servicios de conformidad con todas las leyes, normas y regulaciones aplicables;</p>
<p>
	<br>
</p>
<p>(iii) su uso de los Servicios y la actividad de su cuenta cumplen con todas las leyes, normas y regulaciones aplicables;</p>
<p>
	<br>
</p>
<p>(iv) ha adquirido legalmente y posee legalmente todos los activos digitales;</p>
<p>
	<br>
</p>
<p>(v) es el único responsable de las acciones que realice utilizando la Cuenta;</p>
<p>
	<br>
</p>
<p>(vi) es responsable de todos los beneficios, pérdidas o responsabilidades derivadas de transacciones y del uso de la cuenta;</p>
<p>
	<br>
</p>
<p>(vii) toda la información que nos proporcione es verdadera, precisa y completa;</p>
<p>
	<br>
</p>
<p>(viii) debe informar la situación a las autoridades pertinentes para fines fiscales;</p>
<p>
	<br>
</p>
<p>(ix) debe abstenerse de participar en cualquier actividad ilegal mediante el uso de los Servicios;</p>
<p>
	<br>
</p>
<p>(x) siempre debe abstenerse de participar o participar en cualquier conducta o actividad que sea perjudicial para los intereses de la Plataforma, ya sea relacionada o no con los servicios proporcionados en este sitio web;</p>
<p>
	<br>
</p>
<p>(xi) no debe actuar de manera contraria al uso previsto de la Plataforma, como facilitar la transmisión ilegal de información, difundir información falsa sobre la Plataforma, infringir los derechos de nosotros u otros usuarios, invitar a la participación en esquemas piramidales o transmitirnos información falsa o a otros usuarios transmitir información falsa;</p>
<p>
	<br>
</p>
<p>(xii) no debe interferir maliciosamente con el funcionamiento normal de la plataforma o el uso del servicio por parte de otros usuarios mediante medios técnicos u otros medios;</p>
<p>
	<br>
</p>
<p>(xiii) no debe hacer ni difundir información falsa y difamatoria sobre nosotros;</p>
<p>
	<br>
</p>
<p>(xiv) Si tiene disputas con otros usuarios, solo puede solicitar información de nosotros a través de medios judiciales u otros relacionados con el gobierno;</p>
<p>
	<br>
</p>
<p>(xv) es responsable de todos los honorarios e impuestos asociados con cualquier hardware, software y otros servicios de terceros que incurra en el curso de su uso de los Servicios;</p>
<p>
	<br>
</p>
<p>(xvi) debe cumplir con este Acuerdo y con otras directrices y reglas publicadas por nosotros en relación con la Plataforma.</p>
<p>
	<br>
</p>
<p>14. Responsabilidad e Indemnización</p>
<p>
	<br>
</p>
<p>Usted comprende y acepta que no somos responsables y que usted es el único responsable de cualquiera de los siguientes:</p>
<p>
	<br>
</p>
<p>(i) pérdida de ingresos;</p>
<p>
	<br>
</p>
<p>(ii) pérdida de ganancias comerciales;</p>
<p>
	<br>
</p>
<p>(iii) pérdidas contractuales;</p>
<p>
	<br>
</p>
<p>(iv) interrupción del negocio;</p>
<p>
	<br>
</p>
<p>(v) pérdidas relacionadas con la moneda;</p>

<p>
	<br>
</p>
<p>(vi) pérdida o corrupción de información o datos;</p>
<p>
	<br>
</p>
<p>(vii) oportunidades perdidas;</p>
<p>
	<br>
</p>
<p>(viii) pérdida de precio o valor del activo digital;</p>
<p>
	<br>
</p>
<p>(ix) cargos incurridos por la compra de productos y servicios fuera del alcance de los Servicios;</p>
<p>
	<br>
</p>
<p>(x) cualquier falla de software, hardware, conexión a Internet y cualquier distorsión y retraso resultante;</p>
<p>
	<br>
</p>
<p>(xi) cualquier pérdida o daño indirecto, especial o incidental derivado de cualquier delito (incluida la negligencia) y/o incumplimiento de contrato o cualquier otra causa, ya sea que pudiéramos haber previsto razonablemente tal pérdida o daño y si sabíamos o no de antemano que tal pérdida o daño podía ocurrir;</p>
<p>
	<br>
</p>
<p>(xii) cualquier programa malicioso, como virus o troyanos, con los que pueda infectarse mientras utiliza los Servicios;</p>
<p>
	<br>
</p>
<p>(xiii) la información, productos o negocios de sitios web de terceros vinculados a este sitio web;</p>
<p>
	<br>
</p>
<p>(xiv) la validez, precisión, corrección, confiabilidad, calidad, estabilidad, integridad, puntualidad, idoneidad (incluida para un fin particular), sin errores, omisión y consistencia de cualquier información o tecnología en la Plataforma pérdidas incurridas;</p>
<p>
	<br>
</p>
<p>(xv) errores en nuestro cálculo de transacciones en la Plataforma;</p>
<p>
	<br>
</p>
<p>(xvi) los siguientes daños:</p>
<p>
	<br>
</p>
<p>(a) daños a su reputación o reputación;</p>
<p>
	<br>
</p>
<p>(b) daños a usted cuando tengamos motivos razonables para creer que sus transacciones y/o conducta en la Plataforma pueden involucrar cualquier actividad ilegal o incumplimiento de este Acuerdo;</p>
<p>
	<br>
</p>
<p>(c) daños resultantes de cualquier gasto o pérdida derivada de la compra o adquisición de datos, información o moneda digital a través de los Servicios;</p>
<p>
	<br>
</p>
<p>(d) derivados de su malentendido del servicio; y/o</p>
<p>
	<br>
</p>
<p>(e) Daños causados por cualquier otra pérdida relacionada con los Servicios pero no atribuible a nosotros.</p>
<p>
	<br>
</p>
<p>14.1 Alcance de la Responsabilidad</p>
<p>
	<br>
</p>
<p>No obstante cualquier disposición en este Acuerdo, nuestra responsabilidad hacia usted no excederá el costo total incurrido por usted en el período de 3 meses antes de la ocurrencia del evento que dé lugar a responsabilidad hasta la ocurrencia del evento relevante.</p>
<p>
	<br>
</p>
<p>No renunciamos a ningún derecho no mencionado en este Acuerdo y limitamos, excluimos o compensamos nuestra responsabilidad por daños en la medida máxima permitida por la ley aplicable.</p>
<p>
	<br>
</p>
<p>En caso de disputa entre usted y uno o más usuarios del Servicio (que no seamos nosotros), usted acepta que nosotros, nuestras filiales, proveedores de servicios y cualquiera de sus respectivos funcionarios, directores, agentes, empresas conjuntas, empleados y representantes no serán responsables de ningún reclamo, demanda y daños de cualquier tipo o naturaleza (directos o indirectos, reales y consecuentes) que surjan de o estén relacionados de alguna manera con tales disputas.</p>
<p>
	<br>
</p>
<p>14.2 Indemnización</p>
<p>
	<br>
</p>
<p>Cualquier costo incurrido razonablemente en relación con cualquier reclamo, demanda y daños que surjan de o en relación con su incumplimiento de este Acuerdo y/o nuestra aplicación de este Acuerdo o su incumplimiento de cualquier ley, norma o regulación o su infracción de los derechos de cualquier tercero (incluidos honorarios de abogados y multas, tarifas o sanciones impuestas por cualquier autoridad reguladora), usted acepta indemnizarnos a nosotros, a nuestras filiales, proveedores de servicios y sus respectivos funcionarios, directores, agentes, empleados y representantes.</p>
<p>
	<br>
</p>
<p>14.3 Nuestra garantía</p>
<p>
	<br>
</p>
<p>Las garantías y compromisos establecidos en este Acuerdo son las únicas garantías y representaciones que hacemos con respecto a los servicios que proporcionamos en este sitio web de conformidad con este Acuerdo, y sustituyen a todas y cada una de las garantías y compromisos escritos, orales, expresas e implícitas de cualquier otra manera y forma. . Todas estas garantías y compromisos representan solo nuestras propias garantías y compromisos y no garantizan que ningún tercero cumpla con las garantías y compromisos contenidos en este Acuerdo.</p>
<p>
	<br>
</p>
<p>15. Terminación y Remedios por Incumplimiento</p>
<p>
	<br>
</p>
<p>Tenemos el derecho de terminar todos los servicios que le proporcionamos en cualquier momento de conformidad con este Acuerdo, y este Acuerdo también se terminará en la fecha relevante de terminación de todos los servicios. Usted tiene el derecho de terminar el uso de los servicios que le proporcionamos en cualquier momento, y este acuerdo también se terminará en la fecha relevante cuando usted termine el uso de los servicios. Después de la terminación de este Acuerdo, usted acepta que no tiene derecho a exigirnos que continuemos brindándole servicios o que realicemos cualquier otra obligación, incluida, entre otras, la de exigirnos que retengamos cualquier información en su cuenta o divulguemos dicha información a usted, o a usted o a cualquier tercero. Divulgar cualquier información no leída o no enviada en la cuenta.</p>
<p>
	<br>
</p>
<p>15.1 Incumplimiento del Acuerdo</p>

<p>
	<br>
</p>
<p>Si usted viola este Acuerdo o cualquier ley, norma o regulación aplicable, deberá pagarnos al menos $2 millones en daños y será responsable de todos los costos, incluidos los honorarios legales ("Indemnización"), que incurramos como resultado de dicha infracción. Si la compensación es insuficiente para cubrir nuestras pérdidas, usted será responsable por cualquier monto más allá de la compensación.</p>
<p>
	<br>
</p>
<p>Para evitar dudas, la terminación de este Acuerdo no impedirá que la parte no observante requiera que la parte incumplidora asuma otras responsabilidades que surjan fuera de este Acuerdo.</p>
<p>
	<br>
</p>
<p>16. Notificaciones y Comunicaciones</p>
<p>
	<br>
</p>
<p>Los usuarios se comprometen a verificar los anuncios, materiales e información en la plataforma de manera oportuna y regular. No seremos responsables de ninguna forma de compensación si sufre pérdidas personales debido a la negligencia al no leer los anuncios, materiales e información.</p>
<p>
	<br>
</p>
<p>17. Otros</p>
<p>
	<br>
</p>
<p>17.1 Cesión</p>
<p>
	<br>
</p>
<p>Los derechos y obligaciones estipulados en este Acuerdo son igualmente vinculantes para los cesionarios, sucesores, ejecutores y gerentes que se beneficien de tales derechos y obligaciones. Usted no puede ceder ninguno de sus derechos u obligaciones en virtud de este Acuerdo a ningún tercero sin nuestro consentimiento, sin embargo, podemos transferir nuestros derechos u obligaciones en virtud de este Acuerdo en cualquier momento con 30 días de aviso previo a usted. Los derechos y obligaciones se asignan a cualquier tercero.</p>
<p>
	<br>
</p>
<p>17.2 Fuerza Mayor</p>
<p>
	<br>
</p>
<p>Debido al mantenimiento de equipos de red de información, falla de conexión de red de información, errores informáticos, fallas del sistema como comunicaciones, cortes de energía, condiciones climáticas, accidentes, acciones laborales, disputas laborales, rebeliones, levantamientos, disturbios, falta de productividad o materiales de producción, incendios, inundaciones, tormentas, explosiones, guerras, fallas de bancos u otros socios, colapso de mercados de activos digitales, acciones de gobiernos, autoridades judiciales o ejecutivas, otras acciones fuera de nuestro control o más allá de nuestro control, o debido a terceros, En caso de nuestra falla o demora en la provisión de servicios, no seremos responsables de dicha falla o demora en la provisión de servicios para usted, ni de ninguna pérdida que pueda sufrir como resultado de dicha falla o demora en la provisión de servicios.</p>
<p>
	<br>
</p>
<p>17.3 Relación entre las partes</p>
<p>
	<br>
</p>
<p>Este Acuerdo solo es vinculante para los derechos y obligaciones entre usted y nosotros, y no implica relaciones legales ni disputas legales que surjan de o estén relacionadas con transacciones entre usuarios de este sitio web y otras partes con usted.</p>
<p>
	<br>
</p>
<p>Salvo que se disponga lo contrario en este Acuerdo, nada en este Acuerdo se considerará que nos hace, implica que somos, o de otra manera nos trata como su agente, fiduciario u otro representante.</p>
<p>
	<br>
</p>
<p>17.4 Acuerdo Completo</p>
<p>
	<br>
</p>
<p>Si alguna disposición de este Acuerdo es considerada por algún tribunal competente como inaplicable, inválida o ilegal, la validez de las disposiciones restantes de este Acuerdo no se verá afectada.</p>
<p>
	<br>
</p>
<p>17.5 Modificaciones</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de determinar, enmendar o modificar cualquier contenido de este Acuerdo en cualquier momento a nuestra entera discreción. La fecha y hora mostradas en la sección "Última actualización" de este Acuerdo se refieren al momento en que ocurrieron los cambios en los términos contenidos en este Acuerdo y el contenido de este Acuerdo (https://www.huobi.li/support/en-us/detail/360000298561) . El acuerdo revisado entrará en vigencia inmediatamente después de su publicación en la plataforma. Si bien hemos tomado medidas razonables para garantizar la precisión de la información en la Plataforma, no garantizamos el nivel de precisión y no somos responsables de ningún retraso o falla causados por la información en la Plataforma o por la imposibilidad de conectarse a Internet, transmitir o recibir cualquier aviso e información y cualquier pérdida incurrida directa o indirectamente.</p>
<p>
	<br>
</p>
<p>Es su responsabilidad estar al tanto de las últimas revisiones de este Acuerdo. Si no está de acuerdo con el contenido revisado, deje de usar el servicio inmediatamente. Al continuar utilizando el servicio, se considera que acepta los términos de este acuerdo.</p>
<p>
	<br>
</p>
<p>17.6 Supervivencia</p>
<p>
	<br>
</p>
<p>Todas las disposiciones de este Acuerdo que por su naturaleza sobrevivan a la expiración o terminación de este Acuerdo sobrevivirán a la expiración o terminación de este Acuerdo y sobrevivirán a la expiración o terminación de este Acuerdo.</p>
<p>
	<br>
</p>
<p>17.7 Protección de Datos</p>
<p>
	<br>
</p>
<p>Sus datos personales serán protegidos y mantenidos de manera adecuada y confidencial, pero tenemos el derecho de recopilar, procesar, usar o divulgar sus datos personales de acuerdo con este Acuerdo o la ley aplicable. Dependiendo del producto o servicio en cuestión, sus datos personales pueden ser divulgados a los siguientes terceros:</p>
<p>
	<br>
</p>
<p>(i) su contraparte;</p>
<p>
	<br>
</p>
<p>(ii) nuestros accionistas, socios, inversores, directores, supervisores, funcionarios y empleados;</p>
<p>
	<br>
</p>
<p>(iii) nuestras empresas conjuntas, socios de alianzas y socios comerciales;</p>
<p>
	<br>
</p>
<p>(iv) nuestros agentes, contratistas, proveedores, proveedores de servicios de terceros y asesores profesionales, incluidos los que nos proporcionan servicios administrativos, financieros, partes de investigación, operaciones, tecnología de la información;</p>
<p>
	<br>
</p>
<p>(v) socios comerciales de terceros que proporcionan bienes y servicios o patrocinan concursos u otras promociones, ya sea que estén con nosotros o no;</p>
<p>
	<br>
</p>
<p>(vi) compañías de seguros o investigadores de seguros y agencias de informes de crédito;</p>
<p>
	<br>
</p>
<p>(vii) burós de crédito o cualquier agencia de cobranza de deudas o centros de resolución de disputas que traten disputas;</p>
<p>
	<br>
</p>
<p>(viii) consultores profesionales como auditores y abogados;</p>
<p>
	<br>
</p>
<p>(ix) reguladores gubernamentales relevantes o agencias de aplicación de la ley en cumplimiento de leyes o regulaciones impuestas por autoridades gubernamentales;</p>

<p>
	<br>
</p>
<p>(x) cesionarios de nuestros derechos y obligaciones;</p>
<p>
	<br>
</p>
<p>(xi) bancos, compañías de tarjetas de crédito y sus respectivos proveedores de servicios;</p>
<p>
	<br>
</p>
<p>(xii) según lo identificado por usted o el contrato aplicable y con su consentimiento.</p>
<p>
	<br>
</p>
<p>En lo que respecta a la recopilación, uso y protección del almacenamiento de su información personal, prevalecerá la política de privacidad publicada en este sitio web.</p>
<p>
	<br>
</p>
<p>17.8 Control de Exportaciones</p>
<p>
	<br>
</p>
<p>El uso de los Servicios está sujeto a controles de exportación internacionales y requisitos de sanciones económicas. Al utilizar los Servicios para enviar, recibir, comprar, vender, comerciar o almacenar activos digitales, usted acepta cumplir con estos requisitos. No puede adquirir activos digitales ni utilizar ningún servicio en las siguientes circunstancias:</p>
<p>
	<br>
</p>
<p>(i) Usted se encuentra en Cuba, República Democrática del Congo, República Democrática Popular de Corea
	<br>
</p>
<p>países, Irán, Libia, Somalia, Sudán del Sur, Sudán, Yemen, Siria o cualquier otro país sujeto a embargos estadounidenses, sanciones de la ONU, la Unión Europea, la Autoridad Monetaria de Singapur o el régimen de sanciones financieras del Tesoro del Reino Unido (todos referidos como "país sancionado"), controlado por o siendo un nacional o residente de un país sancionado, o si está en una lista de sanciones económicas publicada por autoridades relevantes de vez en cuando (incluidas, entre otras, la Lista de Individuos y Entidades Designados de la Autoridad Monetaria de Singapur; Departamento de Comercio de los EE. UU. personas en la Lista de Personas Denegadas, Lista No Verificada, Lista de Entidades; Régimen de sanciones financieras de la UE) ("Personas Sancionadas"); o</p>
<p></p>
<p>
	<br>
</p>
<p>(ii) tiene la intención de proporcionar cualquier activo digital adquirido o almacenado o servicios a un país sancionado (o a un nacional o residente de un país sancionado) o a una persona sancionada.</p>
<p>
	<br>
</p>
<p>17.9 Renuncia</p>
<p>
	<br>
</p>
<p>La renuncia por parte de nosotros o usted del derecho a perseguir la responsabilidad de la otra parte por incumplimiento de este Acuerdo o cualquier otra responsabilidad acordada en este Acuerdo no se interpretará ni se considerará como una renuncia del derecho de la otra parte a perseguir otro incumplimiento de contrato. No ejercer ningún derecho o tomar ningún remedio no se interpretará de ninguna manera como una renuncia de dicho derecho o remedio.</p>
<p>
	<br>
</p>
<p>17.10 Ley Aplicable</p>
<p>
	<br>
</p>
<p>Este Acuerdo y la relación con nosotros se rigen y se interpretan de acuerdo con las leyes de Seychelles.</p>
<p>
	<br>
</p>
<p>17.11 Resolución de Disputas</p>
<p>
	<br>
</p>
<p>Usted reconoce y acepta que antes de plantear cualquier disputa o reclamo, se pondrá en contacto con nosotros en support@huobigroup.com y resolveremos la disputa relevante internamente ("Resolución Interna") lo antes posible. Las partes acuerdan negociar y resolver cualquier disputa de buena fe (tales negociaciones serán confidenciales y protegidas por las leyes, normas y regulaciones aplicables, y no se utilizarán como evidencia en ningún litigio).</p>
<p>
	<br>
</p>
<p>Usted reconoce y acepta que en caso de cualquier disputa, controversia, desacuerdo o reclamo que no pueda resolverse mediante resolución interna, incluida cualquier disputa relacionada con la existencia, validez, interpretación, ejecución, incumplimiento o terminación de estos Términos o relacionados con o que surjan de estos Términos Cualquier disputa que surja de los Términos (colectivamente, "Disputas") será remitida por las partes a la Corte Internacional de Arbitraje de la CCI de conformidad con las Reglas de Arbitraje de la CCI actualmente vigentes. El arbitraje se llevará a cabo en Seychelles y estará regido por la ley aplicable determinada de acuerdo con las Reglas de Arbitraje de la CCI. A menos que las partes acuerden lo contrario, bajo las Reglas de Arbitraje de la CCI, solo se puede designar a un árbitro. El árbitro tendrá la autoridad exclusiva para emitir un laudo de acuerdo con su propia jurisdicción, incluyendo, sin limitación, cualquier objeción a la existencia, alcance o validez del acuerdo de arbitraje o la arbitrabilidad de cualquier reclamo o contra reclamo. El arbitraje será en inglés. Independientemente del modo de arbitraje, el árbitro emitirá un laudo razonable por escrito que establezca el laudo arbitral y sus conclusiones subyacentes y las conclusiones relacionadas, si las hubiera. El laudo arbitral será definitivo y vinculante para las partes y ejecutable por cualquier tribunal competente.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>17.12 Propiedad sin dueño</p>
<p>
	<br>
</p>
<p>Si tenemos activos digitales en su nombre y no podemos contactarlo y no tenemos registro de su uso de los Servicios a lo largo de los años, la ley aplicable puede exigirnos que informemos los activos digitales como propiedad sin dueño a las autoridades apropiadas en ciertas jurisdicciones . Nos esforzaremos por localizarlo en la dirección que figura en nuestros registros, pero si no podemos localizarlo, cualquier activo digital de este tipo puede ser necesario que se entregue a las autoridades apropiadas en ciertas jurisdicciones como propiedad sin dueño. Cuando lo permita la ley aplicable, nos reservamos el derecho de deducir tarifas de inactividad u otras tarifas administrativas de dichos activos digitales sin dueño.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>17.13 Renuncia a Acciones Colectivas</p>
<p>
	<br>
</p>
<p>Acepta presentar cualquier reclamo relacionado con este Acuerdo (ya sea basado en contrato, delito, estatuto, fraude, representación indebida o cualquier otra teoría legal, y ya sea que el reclamo surja durante o después de la terminación de este Acuerdo). Además, acepta renunciar, en la medida en que lo permita la ley aplicable, a cualquier derecho de presentar, escuchar o arbitrar dichos reclamos como una acción colectiva, acción de clase, acción de agencia o acción de abogado privado general. Las arbitrajes individuales no pueden consolidarse o agruparse en un solo arbitraje sin el consentimiento de las partes, incluidos nosotros.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>
	<br>
</p>
<p>Apéndice 1: Servicios de la Plataforma</p>
<p>
	<br>
</p>
<p>Después de completar el registro de la cuenta y la verificación de identidad, puede utilizar nuestros diversos servicios, incluidos, entre otros, el comercio de fiat, el comercio al contado, derivados, "Huobi Earn", productos de participación, servicios de préstamos, ecosistema Huobi , programas/promociones temporales que organizamos en la Plataforma bajo este Acuerdo, y cualquier otro acuerdo separado que pueda aplicarse a los Servicios.</p>
<p>
	<br>
</p>
<p>El usuario acepta que las pautas y los acuerdos enumerados o vinculados a continuación son documentos legalmente vinculantes y el usuario acepta quedar obligado por ellos en el uso de todos y cada uno de los Servicios de la Plataforma.</p>

<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>Apéndice 2: Acuerdo de Servicio de Huobi Futures</p>
<p>
	<br>
</p>
<p>1. Plataforma de Trading de Huobi Futures</p>
<p>
	<br>
</p>
<p>La Plataforma de Trading de Huobi Futures (en adelante referida como "Plataforma", "nosotros" o "nos") permite a sus usuarios (en adelante referidos como "Usuarios" o "Usted") realizar transacciones de futuros (en adelante referidas como "Usuarios" o "Usted") a través de cuentas de futuros (según se define en este Acuerdo). "Servir"). El Acuerdo de Servicio de Huobi Futures (en adelante referido como el "Acuerdo") se aplica a todos los servicios proporcionados en la plataforma. Para mayor comodidad, Usted y nosotros somos referidos colectivamente como las "Partes" y individualmente como las "Partes".</p>
<p>
	<br>
</p>
<p>Todos los términos y referencias utilizados en este Acuerdo y definidos en el Acuerdo del Usuario de la Plataforma Huobi ("Acuerdo del Usuario") pero no definidos en este Acuerdo tendrán el mismo significado e interpretación en los términos de este Acuerdo.</p>
<p>
	<br>
</p>
<p>Este Acuerdo es además del Acuerdo del Usuario y sus términos deben ser leídos en conjunto con el Acuerdo del Usuario, directrices, Reglas de la Plataforma, anuncios o cualquier otra información que podamos publicar en la Plataforma de vez en cuando. En caso de conflicto entre los términos de este Acuerdo y los términos del Acuerdo del Usuario, prevalecerá el Acuerdo del Usuario.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>2. Términos Generales</p>
<p>
	<br>
</p>
<p>Antes de utilizar los Servicios a través de la Plataforma, debe leer este Acuerdo cuidadosamente y buscar asesoramiento legal independiente si es necesario. Si no está de acuerdo o no entiende este acuerdo, por favor deje de usar el servicio inmediatamente. Al hacer clic en "Acepto", "Confirmar", crear una cuenta de futuros, acceder a una cuenta de futuros o utilizar el servicio, se considerará que ha entendido, cumplido y aceptado completamente este acuerdo (incluidas las modificaciones realizadas a este acuerdo de vez en cuando).</p>
<p>
	<br>
</p>
<p>Todas las cabeceras en este Acuerdo están escritas solo por conveniencia y no están destinadas a ampliar o limitar el contenido o alcance de los términos y condiciones de este Acuerdo. Toda la información en la Plataforma y este Acuerdo se proporciona "tal cual" y "según esté disponible" y nosotros ya no hacemos ningún compromiso. Las cabeceras en este acuerdo son solo para conveniencia y no gobiernan el significado o la interpretación de ningún término de este acuerdo.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>3. Definiciones</p>
<p>
	<br>
</p>
<p>"Acuerdo" significa el Acuerdo de Servicio de Huobi Futures.</p>
<p>
	<br>
</p>
<p>Consulte la cláusula 16 para el significado de "recompensa por lanzamiento aéreo".</p>
<p>
	<br>
</p>
<p>"AML/CTF" significa Contra el Lavado de Dinero/Contra el Financiamiento del Terrorismo.</p>
<p>
	<br>
</p>
<p>El significado de "Tasa Básica" se establece en el Artículo 2 del Anexo 3.</p>
<p>
	<br>
</p>
<p>"Día Hábil" significa un día que no sea sábado, domingo o un día hábil bancario declarado como feriado público.</p>
<p>
	<br>
</p>
<p>"Indemnización" tiene el significado en el Artículo 19.</p>
<p>
	<br>
</p>
<p>Para el significado de "Cuenta de Margen Cruzado", consulte el Artículo 2 del Anexo 1.</p>
<p>
	<br>
</p>
<p>Consulte la cláusula 4.4 para el significado de "procesamiento de cuentas de futuros".</p>
<p>
	<br>
</p>
<p>"Activo Digital" significa una representación digital de valor que puede transferirse, almacenarse y negociarse digitalmente en la Plataforma.</p>
<p>
	<br>
</p>
<p>Consulte la cláusula 14 para el significado de "liquidación anticipada".</p>
<p>
	<br>
</p>
<p>"Tarifa" tiene el significado dado en la cláusula 13.</p>
<p>
	<br>
</p>
<p>Consulte el Artículo 10 para el significado de "Liquidación Forzada".</p>
<p>
	<br>
</p>
<p>Para el significado de "Línea de Liquidación Forzada", consulte el Artículo 7 del Anexo 1.</p>
<p>
	<br>
</p>
<p>"Futuros" significa productos de futuros que ofrecemos en la Plataforma de vez en cuando para negociar.</p>
<p>
	<br>
</p>
<p>"Cuenta de Futuros" significa su cuenta de futuros en la Plataforma.</p>
<p>
	<br>
</p>
<p>El significado de "deducción de HT" se establece en el Artículo 2 del Anexo 3.</p>
<p>
	<br>
</p>
<p>"Índice" tiene el significado dado en la cláusula 7.</p>
<p>
	<br>
</p>
<p>"Datos de Índice" tiene el significado dado en la cláusula 7.</p>
<p>
	<br>
</p>
<p>"Evento No Elegible" tiene el significado establecido en la Cláusula 16.</p>
<p>
	<br>
</p>
<p>El significado de "tasa de interés" se establece en el Artículo 2 del Anexo 3.</p>
<p>
	<br>
</p>
<p>El significado de "Cuenta de Margen Aislado" se establece en el Artículo 3 del Anexo 2.</p>

<p>
	<br>
</p>
<p>"KYC" significa Conozca a su Cliente.</p>
<p>
	<br>
</p>
<p>Consulte la Cláusula 10 para el significado de "Nivel de Liquidación".</p>
<p>
	<br>
</p>
<p>"Préstamo" tiene el significado en la cláusula 9.</p>
<p>
	<br>
</p>
<p>"Margen" tiene el significado en la cláusula 8.</p>
<p>
	<br>
</p>
<p>Para el significado de "Factor de Ajuste de Margen", consulte el Artículo 2 del Anexo 1.</p>
<p>
	<br>
</p>
<p>"Ratio de Margen" tiene el significado en la cláusula 9.</p>
<p>
	<br>
</p>
<p>"Requisito de Margen" tiene el significado en la cláusula 8.</p>
<p>
	<br>
</p>
<p>"Servicio de Margen" tiene el significado en la cláusula 8.</p>
<p>
	<br>
</p>
<p>Consulte el Artículo 17 para el significado de "conducta de mercado".</p>
<p>
	<br>
</p>
<p>"Orden" tiene el significado en la cláusula 6.</p>
<p>
	<br>
</p>
<p>"Restricción de Orden" tiene el significado en la cláusula 6.</p>
<p>
	<br>
</p>
<p>"Prevención de Restricción de Orden" tiene el significado en la cláusula 6.</p>
<p>
	<br>
</p>
<p>"Tipo de Orden" significa el tipo de orden que podemos ofrecerle para ejecutar una orden en la Plataforma.</p>
<p>
	<br>
</p>
<p>El significado de "Política de Pago" se establece en el Artículo 2 del Anexo 3.</p>
<p>
	<br>
</p>
<p>"Plataforma" significa la Plataforma de Trading de Huobi Futures.</p>
<p>
	<br>
</p>
<p>Consulte el Artículo 2 del Anexo 3 para el significado de "deducción de puntos de tarjeta".</p>
<p>
	<br>
</p>
<p>Consulte la cláusula 4.4 para el significado de "tiempo antes del cierre de una posición".</p>
<p>
	<br>
</p>
<p>El significado de "política de tasas de interés" se establece en el Artículo 2 del Anexo 3.</p>
<p>
	<br>
</p>
<p>"Información de Registro" tiene el significado en el Artículo 4.1.</p>
<p>
	<br>
</p>
<p>"Servicios" significa los servicios relacionados con futuros que le proporcionamos en la Plataforma de vez en cuando.</p>
<p>
	<br>
</p>
<p>"Liquidación" tiene el significado en la cláusula 14.</p>
<p>
	<br>
</p>
<p>El significado de "tasa de interés escalonada" se establece en el Artículo 2 del Anexo 3.</p>
<p>
	<br>
</p>
<p>"Transacción" tiene el significado establecido en la cláusula 4.2.</p>
<p>
	<br>
</p>
<p>"Acuerdo del Usuario" significa el Acuerdo del Usuario de la Plataforma Huobi.</p>
<p>
	<br>
</p>
<p>"Usuario" significa un Usuario de la Plataforma.</p>
<p>
	<br>
</p>
<p>El significado de "cordón" se establece en el Artículo 7 del Anexo 1.</p>
<p>
	<br>
</p>
<p>El significado de "información de alerta" se da en el Artículo 7 del Anexo 1.</p>
<p>
	<br>
</p>
<p>4. Cuenta de Futuros</p>
<p>
	<br>
</p>
<p>4.1 Crear una cuenta de futuros</p>
<p>
	<br>
</p>
<p>Para ser elegible para crear una cuenta de futuros, debe confirmar que es una persona natural, jurídica o cualquier otra entidad autorizada para firmar este Acuerdo y utilizar los Servicios de acuerdo con todas las leyes, reglas y regulaciones aplicables. No puede crear una cuenta de futuros con el fin de violar ninguna ley, regla o regulación aplicable y/o interrumpir los servicios proporcionados por la plataforma.</p>
<p>
	<br>
</p>
<p>Si no es elegible para crear una cuenta de futuros, nos reservamos el derecho de negarle el registro de una cuenta de futuros; si ha creado una cuenta de futuros, tenemos el derecho de suspender, terminar y/o cerrar su cuenta sin previo aviso y Usted debe ser responsable de cualquier consecuencia adversa que se derive de ello. Nos reservamos el derecho, a nuestra entera discreción, de rechazar cualquier solicitud para crear una cuenta de futuros (incluidas, entre otras, las cuentas de futuros asociadas con figuras políticas y/o públicas), o para cumplir con las leyes y regulaciones contra el lavado de dinero/financiamiento del terrorismo.</p>
<p>
	<br>
</p>
<p>Crear una cuenta de futuros requiere proporcionar la información necesaria para completar el proceso de "conozca a su cliente" de la plataforma, que puede incluir:</p>

<p>
	<br>
</p>
<p>(i) una dirección de correo electrónico válida;</p>
<p>
	<br>
</p>
<p>(ii) número de teléfono móvil;</p>
<p>
	<br>
</p>
<p>(iii) nombre real;</p>
<p>
	<br>
</p>
<p>(iv) prueba de identidad;</p>
<p>
	<br>
</p>
<p>(v) cualquier otra información requerida para crear y mantener una cuenta de futuros;</p>
<p>
	<br>
</p>
<p>Colectivamente referidos como "Materiales de Registro".</p>
<p>
	<br>
</p>
<p>Si su número de móvil requiere registro con nombre real, debe completar dicho registro antes de proporcionarnos su número de móvil. Usted será responsable y deberá responder por cualquier pérdida directa o indirecta y cualquier consecuencia adversa resultante de la no inscripción.</p>
<p>
	<br>
</p>
<p>Es su responsabilidad actualizar su información de registro para asegurarse de que siga siendo precisa. Usted es responsable de la autenticidad, integridad y precisión de la información de registro, y asume cualquier pérdida directa o indirecta y consecuencias adversas causadas por información de registro inexacta.</p>
<p>
	<br>
</p>
<p>Después de que se verifique y apruebe la creación de la cuenta de futuros, puede transferir activos digitales a la cuenta de futuros y utilizar el servicio. Usted acepta recibir correos electrónicos y/o información de nuestra parte relacionada con la administración y operación de la Plataforma.</p>
<p>
	<br>
</p>
<p>4.2 Uso de las cuentas de futuros</p>
<p>
	<br>
</p>
<p>Usted tiene derecho a utilizar la cuenta de futuros para acceder a la plataforma y utilizar servicios, incluyendo:</p>
<p>
	<br>
</p>
<p>(i) navegar por la cotización en tiempo real e información de transacción de productos de activos digitales en este sitio web;</p>
<p>
	<br>
</p>
<p>(ii) enviar una transacción de activos digitales, ya sea para realizar una transacción o dar otras instrucciones (cada una, una "Transacción");</p>
<p>
	<br>
</p>
<p>(iii) Completar la Transacción;</p>
<p>
	<br>
</p>
<p>(iv) utilizar la cuenta para ver la información proporcionada al usuario;</p>
<p>
	<br>
</p>
<p>(v) participar en eventos organizados por nosotros de acuerdo con las reglas de eventos relevantes; y/o</p>
<p>
	<br>
</p>
<p>(vi) otros servicios que podamos ofrecerle de vez en cuando.</p>
<p>
	<br>
</p>
<p>4.3 Seguridad de la cuenta de futuros</p>
<p>
	<br>
</p>
<p>No puede donar, prestar, arrendar, ceder o transferir de otro modo la Cuenta de Futuros a cualquier otra persona excepto con nuestro consentimiento.</p>
<p>
	<br>
</p>
<p>No estamos obligados a publicar ningún activo digital sustituto si se pierde, se roba, deja de funcionar, se destruye o no está disponible de otra manera cualquier activo digital, contraseña o clave.</p>
<p>
	<br>
</p>
<p>4.4 Suspensión, Terminación o Cierre</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de suspender la provisión de algunos o todos los servicios a la cuenta de futuros, congelar los activos digitales en la cuenta de futuros, terminar la cuenta de futuros, cerrar la cuenta de futuros y/o notificar a las autoridades pertinentes ("Gestión de la Cuenta de Futuros") si:</p>
<p>
	<br>
</p>
<p>(i) tenemos razones para creer que necesitamos hacerlo para proteger nuestra reputación;</p>
<p>
	<br>
</p>
<p>(ii) el uso de su cuenta de futuros está sujeto a cualquier litigio pendiente, investigación o procedimiento gubernamental, y/o creemos que la actividad de su cuenta de futuros está en alto riesgo de violar leyes o regulaciones;</p>
<p>
	<br>
</p>
<p>(iii) no es elegible para crear una cuenta de futuros según la cláusula 4.1;</p>
<p>
	<br>
</p>
<p>(iv) crea una cuenta de futuros en nombre de una entidad legal que no sea una persona autorizada para actuar en nombre de esa entidad legal;</p>
<p>
	<br>
</p>
<p>(v) a nuestro exclusivo criterio, usted no es adecuado para usar los Servicios;</p>
<p>
	<br>
</p>
<p>(vi) usted no es la persona mencionada en la información de registro de la cuenta de futuros pertinente;</p>
<p>
	<br>
</p>
<p>(vii) No ha iniciado sesión en su cuenta de futuros durante un año seguido;</p>
<p>
	<br>
</p>
<p>(viii) utiliza la Cuenta de Futuros para participar en actividades ilegales o impropias, como el lavado de dinero y el soborno;</p>
<p>
	<br>
</p>
<p>(ix) utiliza la Cuenta para participar en manipulación del mercado, operaciones comerciales impropias u otras actividades comerciales ilegales, como el lavado de dinero, el contrabando y el soborno comercial; y/o</p>
<p>
	<br>
</p>
<p>(x) su incumplimiento de este Acuerdo o cualquier otra ley, regla y regulación aplicable.</p>
<p>
	<br>
</p>
<p>No obstante lo anterior, nos reservamos el derecho de cerrar y terminar su cuenta de futuros con 7 días hábiles de anticipación. Luego, tiene 7 días hábiles para tomar cualquier acción necesaria, incluyendo la cancelación de cualquier orden o el cierre de posiciones ("Tiempo de Pre-Cierre"). Después del período de pre-cierre, tenemos el derecho de cancelar su orden, cerrar la posición y devolver los activos digitales en su cuenta de futuros. No obstante cualquier cosa en este Acuerdo, si su Cuenta de Futuros ha sido cerrada de acuerdo con estos Términos, todos los saldos de la cuenta (incluidas las tarifas y deudas adeudadas a nosotros) nos serán pagados de inmediato.</p>
<p>
	<br>
</p>
<p>No somos responsables de ninguna consecuencia adversa y/o pérdidas asociadas con el procesamiento de cuentas de futuros.</p>
<p>
	<br>
</p>
<p>Si los futuros se suspenden o cierran debido a una investigación por fraude, una investigación por violación o una investigación por incumplimiento de este Acuerdo, tendremos la custodia exclusiva de los activos digitales, los fondos y la información/datos del usuario que pueden ser entregados a las autoridades gubernamentales.</p>
<p>
	<br>
</p>
<p>5. Depósito, Custodia, Retiro y Transferencia</p>
<p>
	<br>
</p>
<p>Los servicios solo están disponibles para activos digitales que admitimos, y dichos activos digitales pueden cambiar de vez en cuando. En ningún caso debe usar una cuenta de futuros para almacenar, enviar, solicitar o recibir activos digitales en cualquier forma que no admitamos. No somos responsables ni responsables de ningún intento de utilizar cuentas de futuros para activos digitales que no admitamos. Le proporcionamos servicios y no somos compradores o vendedores de ninguna transacción de activos digitales. Los servicios no incluyen ningún depósito, retiro o custodia de ninguna moneda fiduciaria.</p>
<p>
	<br>
</p>
<p>Todos los activos digitales mantenidos en cuentas de futuros son mantenidos por nosotros en custodia, a menos que la Plataforma o este Acuerdo dispongan lo contrario. Es decir:</p>

<p>
	<br>
</p>
<p>(i) El título de los activos digitales permanece contigo y no se transfiere a nosotros. Dado que el propietario de los activos digitales es tu cuenta de futuros, debes asumir todo el riesgo de pérdida de dichos activos digitales. No representamos que los activos en la cuenta de futuros nos pertenezcan, ni consideramos dichos activos como propios;</p>
<p>
	<br>
</p>
<p>(ii) No venderemos, transferiremos, prestaremos, hipotecaremos o transferiremos de otro modo los activos en la Cuenta de Futuros a menos que lo exijan las autoridades pertinentes o se disponga de otra manera en este Acuerdo, excepto según lo indiques tú o lo ordene un tribunal competente;</p>
<p>
	<br>
</p>
<p>(iii) Tú eres responsable de los activos digitales en tu cuenta de futuros. Puedes retirar tus activos digitales en cualquier momento enviándolos a otra dirección de blockchain controlada por ti o por un tercero, sujeto a interrupciones, tiempo de inactividad y otras políticas aplicables;</p>
<p>
	<br>
</p>
<p>(iv) Se llevan a cabo entradas de contabilidad por separado para las cuentas de usuario y nuestras cuentas para separar tus activos digitales de nuestros propios activos digitales o fondos. No estamos obligados a utilizar direcciones de blockchain diferentes para almacenar tus activos digitales y los de otros clientes o nuestros activos digitales.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de establecer y ajustar los límites de volumen de transacciones diarias y los límites de retiro en función de consideraciones internas, incluida cualquier investigación de las autoridades pertinentes, la seguridad y el estado de la red de activos digitales relevante y/o cualquier préstamo vencido y pagadero (según lo definido en este Acuerdo).</p>
<p>
	<br>
</p>
<p>6. Trading de Futuros</p>
<p>
	<br>
</p>
<p>Después de registrarte con éxito en una cuenta de futuros, puedes transferir activos digitales a la cuenta de futuros para realizar un pedido ("Orden") en la plataforma. Puedes ejecutar órdenes para ingresar y/o salir de tus posiciones en activos digitales disponibles en la plataforma a través de los tipos de órdenes disponibles en la plataforma.</p>
<p>
	<br>
</p>
<p>Cada usuario es responsable de cualquier pedido realizado en la Plataforma, incluyendo asegurarse de que los pedidos se envíen correctamente en la Plataforma a través del sitio web relevante, aplicación móvil u otros medios permitidos por nosotros de vez en cuando. De vez en cuando, la plataforma puede rechazar un pedido y es tu responsabilidad asegurarte de que se ejecute el pedido. No somos responsables de ninguna pérdida causada por tu fallo en enviar cualquier pedido o por el rechazo del pedido por la plataforma. El envío de pedidos a la Plataforma es bajo tu propio riesgo. Tenemos derecho a confiar en todos los pedidos enviados a través de la cuenta de futuros y en todas las transacciones ejecutadas a través de la cuenta de futuros, y quedas obligado por tales pedidos y transacciones.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de modificar, suspender o terminar cualquier o todos los tipos de órdenes y activos digitales negociables en la Plataforma en cualquier momento a nuestra entera discreción; no somos responsables de ninguna pérdida que sufras como resultado de dicha modificación, suspensión o terminación.</p>
<p>
	<br>
</p>
<p>Para evitar la manipulación del mercado, podemos establecer límites de posición y tamaño de orden en las cuentas de futuros ("Límites de Orden"). Si excedes tu límite de orden, nos reservamos el derecho, a nuestra entera discreción, de reducir tu posición o liquidar o cancelar tu pedido ("Mecanismo de Prevención de Límites de Orden"). Eres el único responsable de cualquier pérdida que puedas sufrir como resultado del Mecanismo de Prevención de Restricciones de Orden.</p>
<p>
	<br>
</p>
<p>7. Índice</p>
<p>
	<br>
</p>
<p>Para cada activo digital negociable en la Plataforma, la Plataforma utiliza un índice basado en el precio del activo digital relevante en ciertos otros intercambios ("Datos del Índice") (el "Índice") para determinar un índice para cada activo digital en el precio de la Plataforma. Los datos del índice se recuperan a través de conexiones API con otros intercambios relevantes.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho, a nuestra entera discreción y sin previo aviso, de ajustar los componentes del índice y sus pesos relativos en el índice. Si hay un error en la recuperación de los datos del índice, o hay una gran desviación en los componentes del índice, lo que resulta en una gran desviación entre el precio del índice y el precio real de transacción de activos digitales en la plataforma, no seremos responsables de ninguna pérdida que surja de ello.</p>
<p>
	<br>
</p>
<p>8. Margen</p>
<p>
	<br>
</p>
<p>El servicio de margen proporcionado por la Plataforma ("Servicio de Margen") te permite usar activos digitales ("Margen") en tu cuenta de futuros para pedir prestados otros activos digitales para ejecutar operaciones en la Plataforma. Al utilizar los servicios de margen, aceptas que comprendes completamente los riesgos asociados con los servicios de margen y aceptas que usarás los servicios de margen bajo tu propio riesgo.</p>
<p>
	<br>
</p>
<p>Debes depositar y mantener Activos Digitales en la Cuenta de Futuros en la forma y cantidad que nosotros exijamos, mediante lo cual podemos solicitar servicios de margen de mantenimiento de vez en cuando a nuestra entera discreción. La lista de activos digitales aprobados por nosotros como elegibles para margen será especificada por nosotros, y ciertos activos digitales solo pueden ser valorados de acuerdo con el factor de ajuste de margen (según se define en este Acuerdo). No estamos obligados a informarte de ningún cambio en la valoración de activos digitales utilizados como margen.</p>
<p>
	<br>
</p>
<p>Tenemos la exclusiva discreción para determinar los requisitos de margen en la Plataforma ("Requisitos de Margen") y nos reservamos el derecho de cambiar los Requisitos de Margen de vez en cuando sin previo aviso. A menos que se indique lo contrario, tales cambios en los requisitos de margen serán efectivos inmediatamente después de que proporcionemos un aviso por escrito de dichos cambios. Sujeto a tales cambios, es posible que se te exija agregar margen de inmediato a tu cuenta de futuros para ejecutar cualquier operación o mantener cualquier posición en la plataforma. No estamos obligados a considerar activos digitales fuera de tu cuenta de futuros si necesitas una llamada de margen.</p>
<p>
	<br>
</p>
<p>Todos los depósitos serán mantenidos por nosotros como seguridad continua. Dichos depósitos están sujetos a gravámenes generales y derechos de compensación a nuestro favor con respecto a cualquier y todas las obligaciones, responsabilidades o dinero adeudado por ti en cualquier momento y adeudados a nosotros en virtud de este Acuerdo o de otra manera. Tenemos derecho a honrar tu depósito de seguridad de acuerdo con las disposiciones de este Acuerdo.</p>
<p>
	<br>
</p>
<p>Tenemos el derecho de depositar, empeñar, volver a empeñar, ceder en prenda, volver a hipotecar, invertir o prestar cualquier margen que nos proporciones, pero no estamos obligados a informarte de ningún interés, ingreso o beneficio que pueda derivarse de ello. No te pagaremos ningún interés sobre ningún depósito que deposites bajo este Acuerdo y retendremos todo dicho interés y beneficio para nuestro propio beneficio. No somos responsables de ninguna pérdida de margen, directa o indirectamente, como resultado del cierre, la quiebra, la insolvencia, la liquidación, la intervención judicial u otro evento de cualquier tercero en el que se deposite o empeñe el margen.</p>
<p>
	<br>
</p>
<p>9. Préstamos</p>
<p>
	<br>
</p>
<p>De acuerdo con los términos de este Acuerdo, acordamos proporcionarte un préstamo de activos digitales para respaldar tus transacciones en la Plataforma, siempre que cumplas con la relación de margen (según se define en este Acuerdo) de tu Cuenta de Futuros ("Préstamo"). Nos reservamos el derecho de cambiar o cancelar, en parte o en su totalidad, cualquier cantidad de préstamo que te hagamos. No obstante cualquier disposición en este Acuerdo, no estamos obligados a proporcionar o continuar proporcionando ningún préstamo a ti. Aceptas utilizar cualquier préstamo proporcionado únicamente para servicios en la plataforma.</p>
<p>
	<br>
</p>
<p>Aceptas mantener un monto mínimo de margen en tu Cuenta de Futuros en todo momento para cumplir con la Relación de Margen de la Plataforma ("Relación de Margen") como lo especificamos de vez en cuando. Eres el único responsable de monitorear los requisitos de relación de margen para tu cuenta de futuros. Si el margen en la cuenta de futuros es insuficiente para cumplir con la relación de margen mínima, es tu responsabilidad transferir el margen adicional a la cuenta de futuros sin importar si recibes una llamada de margen para cumplir con la relación de margen mínima.</p>

<p>
	<br>
</p>
<p>Reconoces y aceptas que si la relación de margen de la cuenta de futuros es inferior al nivel mínimo de margen requerido por la plataforma ("Nivel de Liquidación"), aceptas que podemos, a nuestra entera discreción, liquidar cualquier margen en la cuenta de futuros, liquidar total o parcialmente cualquier posición abierta con margen en la cuenta de futuros y/o cancelar cualquier posición abierta en cuentas de futuros para que tu Relación de Margen sea mayor que el Nivel de Posición Cerrada (“Liquidación Forzada”). No estamos obligados a darte aviso antes de comenzar una liquidación de margen en una cuenta de futuros. En caso de tal liquidación forzada, aceptas:</p>
<p>
	<br>
</p>
<p>(i) ser responsable de todos los costos asociados con cualquier liquidación;</p>
<p>
	<br>
</p>
<p>(ii) ser responsable de cualquier pérdida (ya sea realizada o no realizada) resultante de la liquidación forzada;</p>
<p>
	<br>
</p>
<p>(iii) No somos responsables de ninguna pérdida (ya sea realizada o no realizada) resultante de la liquidación forzada.</p>
<p>
	<br>
</p>
<p>Aceptas que serás responsable de cualquier cantidad pendiente adeudada a nosotros si el margen vendido es insuficiente para pagar el préstamo de margen y cualquier interés acumulado. Dependiendo del plazo del préstamo y la cantidad que te ofrecemos, incurrirás en tasas de interés más altas.</p>
<p>
	<br>
</p>
<p>11. Apalancamiento</p>
<p>
	<br>
</p>
<p>La plataforma te permite ejecutar operaciones apalancadas basadas en la cantidad proporcionada por la plataforma en el momento de la transacción. Después de seleccionar el límite de apalancamiento, todas tus posiciones deben usar el mismo límite de apalancamiento. Al ejecutar una transacción apalancada, aceptas y comprendes que:</p>
<p>
	<br>
</p>
<p>(i) los riesgos asociados con el uso de apalancamiento para operar activos digitales;</p>
<p>
	<br>
</p>
<p>(ii) las posiciones apalancadas requieren un mayor nivel de gestión del riesgo y tolerancia al riesgo, y has evaluado estos riesgos por tus propios medios;</p>
<p>
	<br>
</p>
<p>(iii) asumes todos los riesgos y pérdidas asociados con el uso de apalancamiento al operar activos digitales en la plataforma;</p>
<p>
	<br>
</p>
<p>(iv) No somos responsables de ninguna pérdida que puedas sufrir al operar posiciones apalancadas en la Plataforma.</p>
<p>
	<br>
</p>
<p>12. Cálculo</p>
<p>
	<br>
</p>
<p>No obstante cualquier disposición en este Acuerdo y las Reglas de Operación de la Plataforma, a menos que indiquemos lo contrario, aceptas que todos los cálculos realizados por la Plataforma son cálculos finales y concluyentes.</p>
<p>
	<br>
</p>
<p>13. Tarifas, Financiamiento, Intereses y Comisiones</p>
<p>
	<br>
</p>
<p>Podemos cobrar intereses sobre cualquier préstamo que te hagamos a una tasa que podamos determinar de vez en cuando.</p>
<p>
	<br>
</p>
<p>Debes pagar puntualmente cualquier tarifa, financiamiento, interés y comisión ("Tarifas") adeudada a nosotros y/o a otros usuarios de la manera que podamos estipular de vez en cuando con respecto al uso de los Servicios. Nos reservamos el derecho de modificar las tarifas a nuestra entera discreción sin previo aviso, y es tu responsabilidad asegurarte de estar al tanto de las tarifas asociadas con los Servicios.</p>
<p>
	<br>
</p>
<p>Aceptas que cualquier tarifa pendiente puede ser liquidada debitando el monto relevante de tu cuenta de futuros u otras cuentas que puedas tener con nosotros; también aceptas que las tarifas pendientes son inmediatamente exigibles y pagaderas a las partes pertinentes.</p>
<p>
	<br>
</p>
<p>Si no puedes pagar ninguna tarifa pendiente debida en el activo digital relevante, nos reservamos el derecho de pagar cualquier otro número retenido en tu cuenta de futuros u otra cuenta que tengas con nosotros al tipo de cambio que determinemos en el momento de pago de los activos son pagados o reembolsados.</p>
<p>
	<br>
</p>
<p>Todos los impuestos, derechos, gastos, costos y/o otras tarifas que incurramos como resultado de tu uso de los Servicios son tu responsabilidad.</p>
<p>
	<br>
</p>
<p>14. Liquidación y Entrega</p>
<p>
	<br>
</p>
<p>La liquidación ocurre cuando tu posición se cierra o expira al precio de liquidación, y la ganancia o pérdida se acredita o debita de la cuenta de futuros ("liquidación"). Nos reservamos el derecho de anunciar una liquidación anticipada ("Liquidación Anticipada") a nuestra entera discreción sin previo aviso debido a preocupaciones sobre los activos digitales subyacentes o para garantizar un mercado justo y ordenado. No somos responsables de ninguna pérdida relacionada con la liquidación anticipada. En el caso de una liquidación anticipada, todas las posiciones relevantes se liquidarán en un momento determinado por nosotros.</p>
<p>
	<br>
</p>
<p>15. Acceso a API</p>
<p>
	<br>
</p>
<p>Dependiendo de tus calificaciones, se te puede otorgar acceso a API para usar servicios en la plataforma. Nos reservamos el derecho de limitar, suspender o cancelar cualquier acceso a API concedido a ti. Si utilizas la API para acceder a los Servicios, aceptas que no seremos responsables de ninguna pérdida que sufras por tu uso de la API relevante.</p>
<p>
	<br>
</p>
<p>16. Sin Recompensas por Airdrop</p>
<p>
	<br>
</p>
<p>Aceptas que eres elegible para recibir cualquier Recompensa por Airdrop ("Recompensas por Airdrop") y que no tienes derechos legales o equitativos con respecto a tales Recompensas por Airdrop:</p>
<p>
	<br>
</p>
<p>(i) cualquier activo digital que pidas prestado; y/o</p>
<p>
	<br>
</p>
<p>(ii) cualquier posición que tengas en la Plataforma,</p>
<p>
	<br>
</p>
<p>(colectivamente, "Eventos No Elegibles").</p>

<p>
	<br>
</p>
<p>Si recibes cualquier recompensa por airdrop basada en cualquier evento no elegible, es tu responsabilidad devolver cualquier recompensa por airdrop a nosotros, y nos reservamos el derecho de transferir las recompensas por airdrop de tu cuenta de futuros a nuestra propia billetera propietaria a nuestra entera discreción. Podemos, a nuestra entera discreción, restringir tu retiro de activos digitales de la Plataforma antes de realizar dichas transferencias.</p>
<p>
	<br>
</p>
<p>17. Conducta en el Mercado</p>
<p>
	<br>
</p>
<p>Bajo ninguna circunstancia deberás participar en lo que consideramos ser una conducta en el mercado ("Conducta en el Mercado"), incluyendo pero no limitado a:</p>
<p>
	<br>
</p>
<p>(i) participar en cualquier forma de manipulación del mercado, como órdenes falsificadas, transacciones falsas o manipulación de precios;</p>
<p>
	<br>
</p>
<p>(ii) tomar cualquier acción que cree una representación falsa o engañosa de una transacción o precio;</p>
<p>
	<br>
</p>
<p>(iii) tomar cualquier acción para mantener precios artificiales o manipular precios;</p>
<p>
	<br>
</p>
<p>(iv) divulgar información falsa o engañosa sobre la Plataforma; y/o</p>
<p>
	<br>
</p>
<p>(v) tomar o abstenerse de tomar acciones que puedan constituir fraude.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de utilizar sistemas de monitoreo para supervisar tu actividad en la Plataforma y tomar cualquier acción que consideremos necesaria para abordar cualquier conducta en el mercado real o potencial.</p>
<p>
	<br>
</p>
<p>18. Terminación y Remedios por Incumplimiento</p>
<p>
	<br>
</p>
<p>Tenemos el derecho de terminar todos los servicios que te proporcionamos en cualquier momento de acuerdo con este Acuerdo, y este Acuerdo también terminará en la fecha relevante de terminación de todos los servicios. Tienes el derecho de terminar el uso de los servicios que te proporcionamos en cualquier momento, y este acuerdo también terminará en la fecha relevante cuando termines el uso de los servicios. Después de la terminación de este Acuerdo, aceptas que no tienes derecho a exigirnos que continuemos proporcionándote servicios o para cumplir con cualquier otra obligación, incluyendo pero no limitado a exigirnos que retengamos cualquier información en la cuenta de futuros o divulguemos dicha información a ti o a cualquier tercero La tercera parte divulga cualquier información no leída o no enviada en la cuenta de futuros.</p>
<p>
	<br>
</p>
<p>19. Incumplimiento del Acuerdo</p>
<p>
	<br>
</p>
<p>Si violas este Acuerdo o cualquier ley, regla o regulación aplicable, deberás pagarnos al menos $2 millones en daños y perjuicios y serás responsable de todos los costos, incluidos los honorarios legales ("Indemnización"), que incurramos como resultado de dicho incumplimiento. Si la compensación es insuficiente para cubrir nuestras pérdidas, serás responsable de cualquier monto más allá de la compensación.</p>
<p>
	<br>
</p>
<p>Para evitar dudas, la terminación de este Acuerdo no impedirá que la parte no observante requiera que la parte incumplidora asuma otras responsabilidades que surjan fuera de este Acuerdo.</p>
<p>
	<br>
</p>
<p>20. Notificaciones y Comunicaciones</p>
<p>
	<br>
</p>
<p>Los usuarios se comprometen a verificar los anuncios, materiales e información en la plataforma de manera oportuna y regular. No seremos responsables de ninguna forma de compensación si sufres pérdidas personales debido a la negligente falta de lectura de anuncios, materiales e información.</p>
<p>
	<br>
</p>
<p>21. Disposiciones</p>
<p>
	<br>
</p>
<p>(i) Cesión</p>
<p>
	<br>
</p>
<p>Los derechos y obligaciones estipulados en este Acuerdo son igualmente vinculantes para los cesionarios, sucesores, albaceas y gerentes que se beneficien de tales derechos y obligaciones. No puedes ceder ninguno de tus derechos u obligaciones bajo este Acuerdo a ningún tercero sin nuestro consentimiento, sin embargo, podemos transferir nuestros derechos u obligaciones bajo este Acuerdo en cualquier momento con 30 días de anticipación a ti. Los derechos y obligaciones se asignan a cualquier tercero.</p>
<p>
	<br>
</p>
<p>(ii) Fuerza Mayor</p>
<p>
	<br>
</p>
<p>Debido al mantenimiento del equipo de red de información, falla de la conexión de red de información, errores informáticos, fallas del sistema como comunicaciones, fallas de energía, condiciones climáticas, accidentes, acciones laborales, disputas laborales, rebeliones, sublevaciones, disturbios, falta de productividad o materiales de producción, incendios, inundaciones, tormentas, explosiones, guerras, fallas de bancos u otros socios, colapso de mercados de activos digitales, acciones de gobiernos, autoridades judiciales o ejecutivas, otras acciones fuera de nuestro control o fuera de nuestro control, o debido a terceros, En caso de nuestro fallo o retraso en la prestación de servicios, no seremos responsables de dicho fallo o retraso en la prestación de servicios a ti, ni de ninguna pérdida que puedas sufrir como resultado de dicho fallo o retraso en la prestación de servicios .</p>
<p>
	<br>
</p>
<p>(iii) Relación entre las partes</p>
<p>
	<br>
</p>
<p>Este Acuerdo solo es vinculante en los derechos y obligaciones entre tú y nosotros, y no implica relaciones legales ni disputas legales derivadas de o relacionadas con transacciones entre usuarios de este sitio web y otras partes contigo.</p>
<p>
	<br>
</p>
<p>Salvo que se disponga lo contrario en este Acuerdo, nada en este Acuerdo se considerará que nos hace, implica que somos, o de otra manera nos trata como tu agente, fiduciario u otro representante.</p>
<p>
	<br>
</p>
<p>(iv) Acuerdo Completo</p>
<p>
	<br>
</p>
<p>Si alguna disposición de este Acuerdo es considerada por cualquier tribunal de jurisdicción competente como inaplicable, inválida o ilegal, la validez de las disposiciones restantes de este Acuerdo no se verá afectada.</p>
<p>
	<br>
</p>
<p>(v) Enmiendas</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de determinar, enmendar o modificar cualquier contenido de este Acuerdo en cualquier momento a nuestra entera discreción. La fecha y hora mostradas en la sección "Última actualización" de este Acuerdo se refiere al momento en que ocurrieron los cambios en los términos contenidos en este Acuerdo y el contenido de este Acuerdo. El acuerdo revisado entrará en vigencia inmediatamente después de su publicación en la plataforma. Si bien hemos tomado medidas razonables para garantizar la precisión de la información en la Plataforma, no garantizamos el nivel de precisión y no somos responsables de ningún retraso o falla causados por la información en la Plataforma o por la imposibilidad de conectarse a Internet, transmitir o recibir cualquier aviso e información y cualquier pérdida incurrida directa o indirectamente.</p>
<p>
	<br>
</p>
<p>Es tu responsabilidad estar al tanto de las últimas revisiones de este Acuerdo. Si no estás de acuerdo con el contenido revisado, detén inmediatamente el uso del servicio. Al continuar utilizando el servicio, se considera que aceptas los términos de este acuerdo.</p>
<p>
	<br>
</p>
<p>(vi) Supervivencia</p>
<p>
	<br>
</p>
<p>Todas las disposiciones de este Acuerdo que por su naturaleza sobrevivan a la expiración o terminación de este Acuerdo sobrevivirán a la expiración o terminación de este Acuerdo y sobrevivirán a la expiración o terminación de este Acuerdo.</p>
<p>
	<br>
</p>
<p>(vii) No Renuncia</p>
<p>
	<br>
</p>
<p>La renuncia por nuestra parte o por ti del derecho a ejercer la responsabilidad de la otra parte por incumplimiento de este Acuerdo o cualquier otra responsabilidad acordada en este Acuerdo no se interpretará o se considerará una renuncia del derecho de la otra parte a perseguir otro incumplimiento de contrato. El no ejercicio de ningún derecho o la adopción de cualquier remedio no se interpretará de ninguna manera como una renuncia a dicho derecho o remedio.</p>
<p>
	<br>
</p>
<p>(viii) Ley Aplicable</p>
<p>
	<br>
</p>
<p>La relación entre este Acuerdo y nosotros se rige y se interpreta de acuerdo con las leyes de Seychelles.</p>

<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>Anexo 1: Reglas para el Trading con Margen Cruzado</p>
<p>
	<br>
</p>
<p>1. Visión General</p>
<p>
	<br>
</p>
<p>El Anexo 1 es un complemento a este Acuerdo y se aplica a todas las funciones relacionadas con el apalancamiento con margen cruzado en la plataforma.</p>
<p>
	<br>
</p>
<p>2. Apalancamiento con Margen Cruzado</p>
<p>
	<br>
</p>
<p>Debes proporcionar un margen para el trading con margen cruzado, y la cantidad de margen es igual al patrimonio de los activos digitales en la cuenta de margen cruzado ("Cuenta de Margen") multiplicado por el factor de ajuste de margen correspondiente ("Factor de Ajuste de Margen"). El factor de ajuste de margen se refiere al factor que convierte el margen de tokens a su precio de mercado al calcular el monto del margen. Nos reservamos el derecho, sin previo aviso a ti, de vez en cuando:</p>
<p>
	<br>
</p>
<p>(i) ajustar el factor de ajuste de margen;</p>
<p>
	<br>
</p>
<p>(ii) determinar los tipos de tokens relevantes que pueden ser prestados;</p>
<p>
	<br>
</p>
<p>(iii) Establecer límites de margen para tokens específicos.</p>
<p>
	<br>
</p>
<p>3. Préstamos</p>
<p>
	<br>
</p>
<p>El monto máximo del préstamo es la cantidad máxima de tokens que puedes pedir prestado de nosotros. La plataforma calculará tu monto máximo de préstamo actual en función del monto máximo de préstamo que puedes usar para el apalancamiento con margen cruzado, los factores de ajuste de margen, las reglas de la plataforma y otras limitaciones que podamos considerar de vez en cuando.</p>
<p>
	<br>
</p>
<p>Después de obtener un préstamo apalancado, los activos digitales prestados serán transferidos inmediatamente a tu cuenta de margen cruzado, y la plataforma comenzará a cobrar intereses inmediatamente después de la transferencia.</p>
<p>
	<br>
</p>
<p>4. Apalancamiento</p>
<p>
	<br>
</p>
<p>El monto máximo del préstamo depende de los activos digitales en la cuenta de futuros y del apalancamiento que elijas.</p>
<p>
	<br>
</p>
<p>5. Interés</p>
<p>
	<br>
</p>
<p>Los intereses del préstamo se calcularán a la tasa de servicio por hora. Si el tiempo es inferior a 60 minutos, se considerará como 1 hora. Una vez que el préstamo se acredite en la cuenta de futuros, los intereses comenzarán a acumularse. Puedes pagar el préstamo en cualquier momento. Cualquier tarifa pendiente debe pagarse antes de que se reembolse el depósito de seguridad.</p>
<p>
	<br>
</p>
<p>6. Reembolso</p>
<p>
	<br>
</p>
<p>Si tienes múltiples préstamos en la plataforma, puedes elegir qué préstamo pagar primero.</p>
<p>
	<br>
</p>
<p>7. Control de Riesgos</p>
<p>
	<br>
</p>
<p>La plataforma tiene el derecho de establecer el límite de posición para un solo tipo de token, que se utiliza para calcular la tasa de riesgo, el límite de compra y el monto que se puede transferir fuera de la cuenta de margen cruzado.</p>
<p>
	<br>
</p>
<p>Solo los activos digitales en una cuenta de margen cruzado pueden ser utilizados como margen de préstamo. Supervisaremos la proporción de margen de la cuenta apalancada con margen cruzado. Para los fines de este Anexo 1, tu Proporción de Margen se determinará de la siguiente manera:</p>
<p>
	<br>
</p>
<p>Proporción de margen = valor total de activos digitales dentro del límite de posición / (total de pasivos + tarifa de servicio impaga)</p>
<p>
	<br>
</p>
<p>El valor de los activos se denomina en Tether (USDT), es decir, el valor de mercado total de los activos digitales = el valor de mercado total actual de los activos digitales dentro del límite de posición de la cuenta de margen cruzado. Al calcular la proporción de margen, no se incluye el valor de los activos digitales que exceden el límite de posición.</p>
<p>
	<br>
</p>
<p>Total de Pasivos = Valor de Mercado Total Actual de Préstamos Pendientes en la Cuenta de Margen Cruzado</p>
<p>
	<br>
</p>
<p>Tarifas de Servicio Impagas = Cantidad por Préstamo * Plazo del Préstamo en el momento del cálculo * Tasa de Servicio por Hora - Compensación / Tarifas de Servicio Pagadas</p>
<p>
	<br>
</p>
<p>Si la proporción de margen de la cuenta de margen cruzado alcanza el 120% ("línea de advertencia"), el sistema te enviará un mensaje ("mensaje de advertencia") de acuerdo con la información de contacto que proporcionaste.</p>
<p>
	<br>
</p>
<p>Si la tasa de riesgo de la cuenta de margen cruzado alcanza el 110% (“línea de liquidación forzada”), el sistema activará automáticamente la liquidación forzada, cerrará las posiciones en la cuenta de margen cruzado que poseas y automáticamente reembolsará todos tus préstamos. Si tienes más de un préstamo, los reembolsos seguirán un orden cronológico, con el préstamo que ocurra primero pagado primero. Si todos los activos en la cuenta de margen cruzado son insuficientes para pagar todos los préstamos, la plataforma tiene el derecho de perseguir cualquier deuda pendiente de tu parte.</p>
<p>
	<br>
</p>
<p>La plataforma tiene el derecho de limitar el monto de compra de un solo tipo de token, para evitar la reducción de la tasa de riesgo o incluso la liquidación forzada debido a la compra de tokens que exceden el límite de posición.</p>
<p>
	<br>
</p>
<p>Debes comprender los riesgos del trading con apalancamiento. Eres el único responsable de todas las pérdidas causadas por la liquidación forzada, incluidas las pérdidas causadas por tu falta de tomar medidas apropiadas para reducir tus posiciones a tiempo después de recibir el mensaje de advertencia.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de gestionar el valor total del préstamo, el monto máximo del préstamo, el factor de ajuste de margen, las líneas de advertencia y las líneas de liquidación.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de restringir cualquier transferencia hacia y/o desde tu cuenta con nosotros si el saldo de la Cuenta de Margen Cruzado es negativo después de una liquidación forzada. Cualquier transferencia a una Cuenta de Margen Cruzado también se utilizará para pagar cualquier saldo pendiente.</p>

<p>
	<br>
</p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>Anexo 2: Reglas de Trading con Margen Aislado</p>
<p>
	<br>
</p>
<p>1. Visión General</p>
<p>
	<br>
</p>
<p>El Anexo 2 es un complemento a este Acuerdo y se aplica a todas las funciones relacionadas con el apalancamiento con margen aislado en la plataforma.</p>
<p>
	<br>
</p>
<p>2. Apalancamiento con Margen Aislado</p>
<p>
	<br>
</p>
<p>Debes proporcionar un margen aislado para cada par de operaciones que planees realizar. La plataforma debe configurar cuentas para cada par de transacciones para cada préstamo.</p>
<p>
	<br>
</p>
<p>3. Préstamos</p>
<p>
	<br>
</p>
<p>El monto máximo del préstamo es el límite máximo de préstamo para el par de trading relevante, calculado por la plataforma en función del monto máximo de préstamo para cada cuenta de margen aislado ("cuenta de margen aislado") y las reglas y restricciones de la plataforma.</p>
<p>
	<br>
</p>
<p>Después de obtener un préstamo apalancado, los activos digitales prestados serán transferidos inmediatamente a tu cuenta de margen aislado, y la plataforma comenzará a cobrar intereses inmediatamente después de la transferencia.</p>
<p>
	<br>
</p>
<p>4. Apalancamiento</p>
<p>
	<br>
</p>
<p>El monto máximo del préstamo depende de los activos digitales en la cuenta de futuros y del apalancamiento que elijas.</p>
<p>
	<br>
</p>
<p>5. Interés</p>
<p>
	<br>
</p>
<p>Los intereses del préstamo se calcularán a la tasa de servicio por hora. Si el tiempo es inferior a 60 minutos, se considerará como 1 hora. Una vez que el préstamo se acredite en la cuenta de futuros, los intereses comenzarán a acumularse. Puedes pagar el préstamo en cualquier momento. Cualquier tarifa pendiente debe pagarse antes de que se reembolse el depósito de seguridad.</p>
<p>
	<br>
</p>
<p>6. Reembolso</p>
<p>
	<br>
</p>
<p>Si tienes múltiples préstamos en la plataforma, puedes elegir qué préstamo pagar primero.</p>
<p>
	<br>
</p>
<p>7. Control de Riesgos</p>
<p>
	<br>
</p>
<p>Los activos digitales en cada cuenta de margen aislado no deben incluirse en otras cuentas de margen aislado como margen. Supervisaremos la proporción de margen de la cuenta de margen aislado en tiempo real y nos reservamos el derecho de tomar medidas basadas en cambios en la proporción de margen.</p>
<p>
	<br>
</p>
<p>Proporción de Margen = Total de Activos Digitales / (Total de Pasivos + Tarifas de Servicio Impagas)</p>
<p>
	<br>
</p>
<p>El valor de los activos debe denominarse en Bitcoin (BTC).</p>
<p>
	<br>
</p>
<p>Total de Activos = Valor de Mercado Total Actual de todos los activos digitales en la cuenta de margen aislado</p>
<p>
	<br>
</p>
<p>Total de Pasivos = Valor de Mercado Total Actual de todos los préstamos pendientes en la cuenta de margen aislado</p>
<p>
	<br>
</p>
<p>Tarifas de Servicio Impagas = Cantidad por Préstamo * Plazo del Préstamo en el momento del cálculo * Tasa de Servicio por Hora - Tarifa de Servicio Deducida/Reembolsada</p>
<p>
	<br>
</p>
<p>Cuando la proporción de margen de la cuenta de margen aislado alcanza la línea de advertencia, el sistema te enviará un mensaje de advertencia de acuerdo con la información de contacto que proporcionaste.</p>
<p>
	<br>
</p>
<p>Si la tasa de riesgo de la cuenta de margen aislado alcanza la línea de liquidación forzada, el sistema activará automáticamente la liquidación forzada, cerrará las posiciones en la cuenta de margen aislado que poseas y automáticamente reembolsará todos tus préstamos. Si tienes más de un préstamo, los reembolsos seguirán un orden cronológico, con el préstamo que ocurra primero pagado primero. Si todos los activos en la cuenta de margen aislado son insuficientes para pagar todos los préstamos, la plataforma tiene el derecho de perseguir cualquier deuda pendiente de tu parte.</p>
<p>
	<br>
</p>
<p>Debes comprender los riesgos del trading con apalancamiento. Eres el único responsable de todas las pérdidas causadas por la liquidación forzada, incluidas las pérdidas causadas por tu falta de tomar medidas apropiadas para reducir tus posiciones a tiempo después de recibir el mensaje de advertencia.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de gestionar el valor total del préstamo, el monto máximo del préstamo, las líneas de advertencia y las líneas de liquidación.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de restringir cualquier transferencia hacia y/o desde tu cuenta con nosotros si el saldo de la cuenta de apalancamiento aislado es negativo después de una liquidación. Cualquier transferencia a una cuenta de Margen Cruzado también se utilizará para pagar cualquier saldo pendiente.</p>

<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>Anexo 3: Tasas de Interés</p>
<p>
	<br>
</p>
<p>1. Visión General</p>
<p>
	<br>
</p>
<p>El Anexo 3 es un complemento a este Acuerdo y se aplica a todas las funciones relacionadas con préstamos en la Plataforma.</p>
<p>
	<br>
</p>
<p>2. La Plataforma aplica dos tasas de interés ("Tasa") y tres políticas de pago ("Política de Pago"):</p>
<p>
	<br>
</p>
<p>(i) una tasa base del 0,098% (la "Tasa Base");</p>
<p>
	<br>
</p>
<p>(ii) Tasas Escalonadas ("Tasas Escalonadas");</p>
<p>
	<br>
</p>
<p>(iii) Pagos en Activos Digitales Prestados;</p>
<p>
	<br>
</p>
<p>(iv) Créditos de Tarjeta de Puntos ("Créditos de Tarjeta de Puntos");</p>
<p>
	<br>
</p>
<p>(v) Créditos HT ("Créditos HT"),</p>
<p>
	<br>
</p>
<p>Referidos colectivamente como "política de tasa de interés".</p>
<p>
	<br>
</p>
<p>La tasa base para el préstamo es del 0,098% diario. Los usuarios elegibles también pueden disfrutar de tasas escalonadas que se actualizan diariamente. Las tasas escalonadas se basan en tu volumen de operaciones en la plataforma y el HT en tu cuenta con nosotros.</p>
<p>
	<br>
</p>
<p>Si cumples con los requisitos de la plataforma, podrías ser elegible para la deducción de la tarjeta de puntos o la deducción de HT de acuerdo con las reglas actuales de la plataforma. La deducción de la tarjeta de puntos y la deducción de HT no pueden utilizarse al mismo tiempo.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de ajustar la política de tasas de interés de vez en cuando en función de las condiciones del mercado y otros factores que consideremos apropiados, mediante la actualización de este acuerdo y/o publicando anuncios en la plataforma de vez en cuando.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>Anexo 4: Mensajes de Alerta</p>
<p>
	<br>
</p>
<p>1. Visión General</p>
<p>
	<br>
</p>
<p>El Anexo 4 es un complemento a este Acuerdo y se aplica a todas las funciones relacionadas con Mensajes de Alerta en la Plataforma.</p>
<p>
	<br>
</p>
<p>2. Alerta de Saldo Cero</p>
<p>
	<br>
</p>
<p>Si la proporción de margen alcanza el 120%, se enviará un mensaje de alerta de saldo cero a tu cuenta.</p>
<p>
	<br>
</p>
<p>3. Alerta de Proporción de Margen</p>
<p>
	<br>
</p>
<p>Si la proporción de margen alcanza el 110%, se enviará un mensaje de alerta de proporción de margen a tu cuenta.</p>
<p>
	<br>
</p>
<p>4. Advertencia de Transacción con Límite</p>
<p>
	<br>
</p>
<p>Si la proporción de margen cae por debajo del 110%, se enviará un mensaje de alerta de transacción con límite a tu cuenta. En el momento de enviar este mensaje de precaución, nos reservamos el derecho de limitar tus transacciones y órdenes y de pagar cualquier préstamo pendiente y tarifas adeudadas.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>Apéndice 3: Acuerdo de Servicio de Opciones de Huobi</p>
<p>
	<br>
</p>
<p>1. Plataforma de Trading de Opciones de Huobi</p>
<p>
	<br>
</p>
<p>La Plataforma de Trading de Opciones de Huobi (en adelante referida como "Plataforma", "nosotros" o "nos") permite a sus usuarios (en adelante referidos como "Usuarios" o "tú") operar opciones (en adelante referidas como "Usuarios" o "Tú") a través de cuentas de opciones (según se define en este Acuerdo). "Servir"). El Acuerdo de Servicio de Opciones de Huobi (en adelante referido como el "Acuerdo") se aplica a todos los servicios proporcionados por la plataforma. Por conveniencia, tú y nosotros somos referidos conjuntamente como las "Partes" y individualmente como las "Partes".</p>
<p>
	<br>
</p>
<p>Todos los términos y referencias utilizados en este Acuerdo y definidos en el "Acuerdo de Usuario de la Plataforma de Huobi" ("Acuerdo de Usuario") pero no definidos en este Acuerdo tendrán el mismo significado e interpretación en los términos de este Acuerdo.</p>
<p>
	<br>
</p>
<p>Este Acuerdo es adicional al Acuerdo de Usuario y sus términos deben leerse en conjunto con el Acuerdo de Usuario. En caso de cualquier conflicto entre los términos de este Acuerdo y los términos del Acuerdo de Usuario, prevalecerá el Acuerdo de Usuario.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>2. Términos Generales</p>
<p>
	<br>
</p>
<p>Antes de utilizar los Servicios a través de la Plataforma, debes leer este Acuerdo detenidamente y buscar asesoramiento legal independiente si es necesario. Si no estás de acuerdo o no entiendes este acuerdo, cancela inmediatamente la cuenta de opciones y deja de usar el servicio. Al hacer clic en "Confirmar", "Acepto", crear una Cuenta de Opciones, acceder a una Cuenta de Opciones o usar los Servicios, se considerará que has entendido, cumplido y aceptado completamente este Acuerdo (incluidas las modificaciones realizadas a este Acuerdo de vez en cuando).</p>
<p>
	<br>
</p>
<p>Todos los encabezados en este Acuerdo están escritos solo por conveniencia y no tienen la intención de ampliar o limitar el contenido o el alcance de los términos y condiciones de este Acuerdo. Plataforma y este Acuerdo
	<br>
</p>
<p>Toda la información en el sitio web se proporciona "tal cual" y "según esté disponible" sin ningún compromiso adicional. Los encabezados en este acuerdo son solo para conveniencia y no rigen el significado o la interpretación de ningún término de este acuerdo.</p>
<p></p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>3. Definiciones</p>
<p>
	<br>
</p>
<p>"Acuerdo" significa el Acuerdo de Servicio de Opciones de Huobi.</p>
<p>
	<br>
</p>
<p>"AML/CTF" significa Anti-Lavado de Dinero/Contra-Financiamiento del Terrorismo.</p>
<p>
	<br>
</p>
<p>"Día Hábil" significa un día que no sea sábado, domingo o un día hábil bancario declarado como feriado público.</p>
<p>
	<br>
</p>
<p>"Indemnización" tiene el significado otorgado en el Artículo 12.</p>
<p>
	<br>
</p>
<p>"Procesamiento de Cuentas de Opciones" tiene el significado establecido en la Sección 4.4.</p>
<p>
	<br>
</p>
<p>"Activo Digital" significa una representación digital de valor que puede transferirse, almacenarse y negociarse digitalmente en la Plataforma.</p>
<p>
	<br>
</p>
<p>Consulta el artículo 9 para el significado de "liquidación anticipada".</p>
<p>
	<br>
</p>
<p>"Tarifa" tiene el significado otorgado en el artículo 8.</p>
<p>
	<br>
</p>
<p>"KYC" significa Conoce a Tu Cliente.</p>
<p>
	<br>
</p>
<p>Consulta el Artículo 10 para el significado de "conducta indebida en el mercado".</p>
<p>
	<br>
</p>
<p>"Opciones" significa productos de opciones que ofrecemos en la Plataforma de vez en cuando para negociar.</p>
<p>
	<br>
</p>
<p>"Cuenta de Opciones" significa tu cuenta de opciones en la Plataforma.</p>
<p>
	<br>
</p>
<p>Consulta el artículo 6 para el significado de "Tipo de Opción".</p>
<p>
	<br>
</p>
<p>"Plataforma" significa la Plataforma de Trading de Opciones de Huobi.</p>
<p>
	<br>
</p>
<p>Consulta la cláusula 4.4 para el significado de "tiempo antes de cerrar una posición".</p>
<p>
	<br>
</p>
<p>"Oferta" tiene el significado en la cláusula 6.</p>
<p>
	<br>
</p>
<p>"Información de Registro" tiene el significado en el Artículo 4.1.</p>
<p>
	<br>
</p>
<p>"Solicitud" tiene el significado en la cláusula 6.</p>
<p>
	<br>
</p>
<p>"Servicios" significa servicios relacionados con opciones que te proporcionamos en la Plataforma de vez en cuando.</p>
<p>
	<br>
</p>
<p>"Liquidación" tiene el significado en la cláusula 9.</p>
<p>
	<br>
</p>
<p>"Transacción" tiene el significado establecido en la cláusula 4.2.</p>
<p>
	<br>
</p>
<p>"Acuerdo de Usuario" significa el Acuerdo de Usuario de la Plataforma de Huobi.</p>
<p>
	<br>
</p>
<p>"Usuario" significa un Usuario de la Plataforma.</p>

<p>
	<br>
</p>
<p>4. Cuenta de Opciones</p>
<p>
	<br>
</p>
<p>4.1 Crear cuenta de opciones</p>
<p>
	<br>
</p>
<p>Para ser elegible para crear una Cuenta de Opciones, debes confirmar que eres una persona natural, jurídica o cualquier otra entidad autorizada para firmar este Acuerdo y usar los Servicios de acuerdo con todas las leyes, normativas y regulaciones aplicables. No puedes crear una cuenta de opciones con el fin de violar ninguna ley, normativa y/o interrumpir los servicios proporcionados por la plataforma.</p>
<p>
	<br>
</p>
<p>Si no eres elegible para crear una cuenta de opciones, nos reservamos el derecho de negarte el registro de una cuenta de opciones; si has creado una cuenta de opciones, nos reservamos el derecho de suspender, terminar y/o cerrar tu cuenta sin previo aviso, y exigirte que seas responsable de cualquier consecuencia adversa que surja de ello. Nos reservamos el derecho, a nuestra entera discreción, de rechazar cualquier solicitud para crear una cuenta de opciones (incluidas, entre otras, las cuentas de opciones asociadas con figuras políticas y/o públicas), o para cumplir con las leyes y regulaciones contra el lavado de dinero/financiamiento del terrorismo.</p>
<p>
	<br>
</p>
<p>Crear una cuenta de opciones requiere proporcionar la información necesaria para completar el proceso de "conoce a tu cliente" de la plataforma, que puede incluir:</p>
<p>
	<br>
</p>
<p>(i) una dirección de correo electrónico válida;</p>
<p>
	<br>
</p>
<p>(ii) número de teléfono móvil;</p>
<p>
	<br>
</p>
<p>(iii) nombre real;</p>
<p>
	<br>
</p>
<p>(iv) prueba de identidad;</p>
<p>
	<br>
</p>
<p>(v) cualquier otra información requerida para crear y mantener una cuenta de opciones;</p>
<p>
	<br>
</p>
<p>Referidos colectivamente como "Materiales de Registro".</p>
<p>
	<br>
</p>
<p>Si tu número de móvil requiere un registro real, debes completar dicho registro antes de proporcionarnos tu número de móvil. Serás responsable y estarás obligado por cualquier pérdida directa o indirecta y cualquier consecuencia adversa resultante de la falta de registro.</p>
<p>
	<br>
</p>
<p>Es tu responsabilidad actualizar tu información de registro para asegurarte de que permanezca precisa. Eres responsable de la autenticidad, integridad y precisión de la información de registro, y asumes cualquier pérdida directa o indirecta y consecuencias adversas causadas por información de registro inexacta.</p>
<p>
	<br>
</p>
<p>Después de que se verifique y apruebe la creación de la cuenta de opciones, puedes transferir activos digitales a la cuenta de opciones y usar los servicios. Aceptas recibir correos electrónicos y/o información de nuestra parte relacionada con la administración y operación de la Plataforma.</p>
<p>
	<br>
</p>
<p>4.2 Uso de la Cuenta de Opciones</p>
<p>
	<br>
</p>
<p>Tienes derecho a utilizar tu cuenta de opciones para acceder a la plataforma y usar los servicios, incluyendo:</p>
<p>
	<br>
</p>
<p>(i) ver cotizaciones en tiempo real e información de negociación de activos digitales y varias opciones en este sitio web;</p>
<p>
	<br>
</p>
<p>(ii) enviar una transacción de activos digitales, ya sea para realizar una transacción o dar otras instrucciones (cada una, una "Transacción");</p>
<p>
	<br>
</p>
<p>(iii) Completar la Transacción;</p>
<p>
	<br>
</p>
<p>(iv) usar la cuenta para ver la información proporcionada al usuario;</p>
<p>
	<br>
</p>
<p>(v) participar en eventos organizados por nosotros de acuerdo con las reglas de eventos relevantes; y/o</p>
<p>
	<br>
</p>
<p>(vi) otros servicios que podamos proporcionarte de vez en cuando.</p>
<p>
	<br>
</p>
<p>4.3 Seguridad de la Cuenta de Opciones</p>
<p>
	<br>
</p>
<p>No puedes donar, prestar, arrendar, ceder o transferir de otra manera la Cuenta de Opciones a ninguna otra persona excepto con nuestro consentimiento.</p>
<p>
	<br>
</p>
<p>No tenemos la obligación de publicar ningún activo digital sustituto si se pierde, se roba, falla, se destruye o no está disponible de otra manera algún activo digital, contraseña o clave.</p>
<p>
	<br>
</p>
<p>4.4 Suspensión, Terminación o Cierre</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de suspender parte o todos los servicios proporcionados a la Cuenta de Opciones, congelar activos digitales en la Cuenta de Opciones, terminar la Cuenta de Opciones, cerrar la Cuenta de Opciones y/o notificar a las autoridades correspondientes ("Manejo de la Cuenta de Opciones") si:</p>
<p>
	<br>
</p>
<p>(i) tenemos motivos para creer que necesitamos hacerlo para proteger nuestra reputación;</p>
<p>
	<br>
</p>
<p>(ii) el uso de tu cuenta de opciones se ve afectado por cualquier litigio pendiente, investigación o procedimiento gubernamental, y/o creemos que la actividad de tu cuenta de opciones tiene un alto riesgo de violar leyes o regulaciones;</p>
<p>
	<br>
</p>
<p>(iii) no eres elegible para crear una cuenta de opciones según se establece en la cláusula 4.1;</p>
<p>
	<br>
</p>
<p>(iv) creas una cuenta de opciones en nombre de una entidad legal que no sea una persona autorizada para actuar en nombre de esa entidad legal;</p>
<p>
	<br>
</p>
<p>(v) a nuestra entera discreción, no eres adecuado para usar los Servicios;</p>
<p>
	<br>
</p>
<p>(vi) no eres una persona incluida en la información de registro de la cuenta de opciones relevante;</p>

<p>
	<br>
</p>
<p>(vii) no has iniciado sesión en tu cuenta de opciones durante 1 año consecutivo;</p>
<p>
	<br>
</p>
<p>(viii) utilizas la Cuenta de Opciones para participar en actividades ilegales o impropias, como el lavado de dinero y el soborno;</p>
<p>
	<br>
</p>
<p>(ix) utilizas tu cuenta de opciones para participar en manipulación de mercado, operaciones impropias u otras actividades comerciales ilegales, como el lavado de dinero, el contrabando y el soborno comercial; y/o</p>
<p>
	<br>
</p>
<p>(x) incumples este Acuerdo o cualquier otra ley, normativa o regulación aplicable.</p>
<p>
	<br>
</p>
<p>No obstante lo anterior, nos reservamos el derecho de cerrar y terminar tu cuenta de opciones con 7 días hábiles de aviso para ti. Luego tendrás 7 días hábiles para tomar cualquier acción necesaria, incluida la cancelación de cualquier orden o el cierre de posiciones ("Tiempo Pre-Cierre"). Después del período de tiempo previo al cierre, tenemos el derecho de cancelar tu orden, cerrar la posición y devolverte los activos digitales en tu cuenta de opciones. No obstante cualquier cosa en este Acuerdo, si tu Cuenta de Opciones ha sido cerrada de acuerdo con estos Términos, todos los saldos de cuenta (incluidas las tarifas y deudas que nos debas) se nos pagarán inmediatamente.</p>
<p>
	<br>
</p>
<p>No somos responsables de ninguna consecuencia adversa y/o pérdidas asociadas con el manejo de cuentas de opciones.</p>
<p>
	<br>
</p>
<p>En caso de suspensión o cierre de una Cuenta de Opciones debido a una investigación por fraude, investigación por violación o incumplimiento de este Acuerdo, tomaremos plena custodia de los activos digitales, fondos e información/datos de usuario que puedan ser entregados a las autoridades gubernamentales.</p>
<p>
	<br>
</p>
<p>5. Depósito, Custodia, Retiro y Transferencia</p>
<p>
	<br>
</p>
<p>Los servicios solo están disponibles para activos digitales que admitimos, y dichos activos digitales pueden cambiar de vez en cuando. En ningún caso debes usar la Cuenta de Opciones para almacenar, enviar, solicitar o recibir activos digitales en cualquier forma que no sea compatible con nosotros. No somos responsables ni responsables de ningún intento de usar la cuenta de opciones para activos digitales que no admitimos. Te brindamos servicios y no somos compradores ni vendedores de ninguna transacción de activos digitales. Los servicios no incluyen ningún depósito o retiro o custodia de ninguna moneda fiat.</p>
<p>
	<br>
</p>
<p>A menos que la Plataforma o este Acuerdo dispongan lo contrario, todos los activos digitales mantenidos en la Cuenta de Opciones son retenidos por nosotros en custodia. Es decir:</p>
<p>
	<br>
</p>
<p>(i) La propiedad de los activos digitales permanece contigo y no se transfiere a nosotros. Dado que el propietario de los activos digitales es tu cuenta de opciones, debes asumir todo el riesgo de pérdida de dichos activos digitales. No afirmamos que los activos en la Cuenta de Opciones nos pertenezcan, ni consideramos que dichos activos sean nuestros;</p>
<p>
	<br>
</p>
<p>(ii) No venderemos, asignaremos, prestaremos, hipotecaremos ni transferiremos de ninguna otra manera los activos en la Cuenta de Opciones a menos que lo exijan las autoridades pertinentes o se disponga de otra manera en este Acuerdo, excepto según lo indiques tú o lo ordene un tribunal de jurisdicción competente;</p>
<p>
	<br>
</p>
<p>(iii) Tú tienes el control de los Activos Digitales en la Cuenta de Opciones. Puedes retirar Activos Digitales en cualquier momento enviando Activos Digitales a otra dirección blockchain en tu Cuenta Huobi controlada por ti o un tercero, sujeto a interrupciones, tiempo de inactividad y otras políticas aplicables;</p>
<p>
	<br>
</p>
<p>(iv) los registros de contabilidad separados para tu cuenta y nuestra cuenta separan tus activos digitales de nuestros propios activos digitales o fondos. No obstante lo anterior, no tenemos la obligación de usar direcciones blockchain diferentes para almacenar tus activos digitales y los de otros clientes o los nuestros.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de establecer y ajustar los límites diarios de volumen de transacciones y retiros en función de consideraciones internas, incluida cualquier investigación realizada por las autoridades pertinentes, la seguridad y/o el estado de la red de activos digitales relevante.</p>
<p>
	<br>
</p>
<p>6. Negociación de Opciones</p>
<p>
	<br>
</p>
<p>Después de registrar exitosamente la cuenta de opciones, puedes transferir activos digitales a la cuenta de opciones. Para comprar una opción, debes solicitar ("Solicitud") una cotización ("Oferta") en la Plataforma. Después de aceptar la oferta, dependiendo del tipo de producto de opción que elijas comprar ("Tipo de Opción"), puedes ejercer la opción en cualquier momento, de lo contrario, la opción se liquidará automáticamente en la fecha de vencimiento.</p>
<p>
	<br>
</p>
<p>Eres responsable de cualquier opción que compres en la Plataforma, incluida la garantía de que las solicitudes se presenten correctamente en la Plataforma a través del sitio web relevante, la aplicación móvil u otros medios según lo permitamos de vez en cuando. La Plataforma puede denegar solicitudes de vez en cuando y es tu responsabilidad asegurarte de que las solicitudes se presenten. No somos responsables de ninguna pérdida causada por tu incapacidad para presentar cualquier solicitud o por el rechazo de la plataforma de la solicitud. La presentación de solicitudes a la Plataforma es bajo tu propio riesgo. Tenemos derecho a confiar en, y estás obligado por, todas las solicitudes, compras y transacciones realizadas por ti a través de la Cuenta de Opciones.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de modificar, suspender o terminar cualquier tipo de opción disponible para negociar en la Plataforma en cualquier momento a nuestra sola discreción y no seremos responsables ante ti por ninguna pérdida sufrida por ti como resultado de dicha modificación, suspensión o terminación.</p>
<p>
	<br>
</p>
<p>7. Cálculo</p>
<p>
	<br>
</p>
<p>No obstante cualquier cosa en este Acuerdo y las Reglas de Negociación de la Plataforma, a menos que indiquemos lo contrario, aceptas que todos los cálculos realizados por la Plataforma son cálculos finales y concluyentes.</p>
<p>
	<br>
</p>
<p>8. Tarifas y Seguros</p>
<p>
	<br>
</p>
<p>Deberás pagar prontamente cualquier tarifa y comisión ("Tarifas") adeudadas a nosotros, a nuestros Proveedores de Servicios y/o a otros usuarios de la manera que podamos estipular de vez en cuando con respecto al uso de los Servicios. Nos reservamos el derecho de modificar las tarifas a nuestra sola discreción sin previo aviso y es tu responsabilidad asegurarte de estar al tanto de las tarifas asociadas con los Servicios.</p>
<p>
	<br>
</p>
<p>Aceptas que cualquier tarifa pendiente puede ser liquidada debitando el monto relevante de tu cuenta de opciones u otra cuenta que puedas tener con nosotros; también aceptas que las tarifas pendientes son inmediatamente exigibles y pagaderas a la parte deudora correspondiente.</p>
<p>
	<br>
</p>
<p>Si no puedes pagar las tarifas pendientes debidas en los Activos Digitales relevantes, nos reservamos el derecho de pagar al tipo de cambio que determinemos en el momento del pago a cualquier otro monto que se mantenga en tu Cuenta de Opciones o en otra cuenta que tengas con nosotros activos digitales para pago o reembolso.</p>
<p>
	<br>
</p>
<p>Cualquier impuesto, arancel, gasto, costo y/o otros cargos aplicables que incurramos como resultado de tu uso de los Servicios son tu responsabilidad.</p>
<p>
	<br>
</p>
<p>9. Liquidación y Entrega</p>
<p>
	<br>
</p>
<p>La liquidación se produce cuando ejerces una opción, o cuando se liquida automáticamente en la fecha de vencimiento, y se acredita o debita un beneficio o pérdida en la cuenta de opciones ("liquidación"). Nos reservamos el derecho de anunciar la liquidación anticipada ("Liquidación Anticipada") a nuestra sola discreción sin previo aviso debido a preocupaciones sobre los activos digitales subyacentes o para garantizar un mercado justo y ordenado. No somos responsables de ninguna pérdida relacionada con la liquidación anticipada. En caso de liquidación anticipada, todas las opciones subyacentes se liquidarán en un momento determinado por nosotros.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>10. Conducta Indebida en el Mercado</p>
<p>
	<br>
</p>
<p>En ningún caso debes participar en lo que nosotros consideramos conducta indebida en el mercado ("Conducta Indebida en el Mercado"), incluyendo pero no limitado a:</p>
<p>
	<br>
</p>
<p>(i) participar en cualquier forma de manipulación del mercado, como órdenes falsificadas, transacciones falsas o manipulación de precios;</p>
<p>
	<br>
</p>
<p>(ii) tomar cualquier acción que cree una representación falsa o engañosa de una transacción o precio;</p>
<p>
	<br>
</p>
<p>(iii) tomar cualquier acción para mantener precios artificiales o manipular precios;</p>
<p>
	<br>
</p>
<p>(iv) divulgar información falsa o engañosa sobre la Plataforma; y/o</p>
<p>
	<br>
</p>
<p>(v) tomar o abstenerse de tomar acciones que puedan constituir fraude.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de utilizar sistemas de monitoreo para supervisar tu actividad en la Plataforma y tomar cualquier acción que consideremos necesaria para abordar cualquier conducta indebida en el mercado real o potencial.</p>
<p>
	<br>
</p>
<p>11. Terminación y Remedios por Incumplimiento</p>
<p>
	<br>
</p>
<p>Tenemos el derecho de terminar todos los servicios que te proporcionamos en cualquier momento de acuerdo con este Acuerdo, y este Acuerdo también terminará en la fecha relevante de terminación de todos los servicios. Tienes el derecho de terminar el uso de los servicios que te proporcionamos en cualquier momento, y este acuerdo también terminará en la fecha relevante cuando termines el uso de los servicios. Después de la terminación de este Acuerdo, aceptas que no tienes derecho a exigirnos que continuemos brindándote servicios o que cumplamos con otras obligaciones, incluido, entre otros, exigirnos que retengamos cualquier información en la Cuenta de Opciones o que divulguemos dicha información a ti, o a ti o cualquier tercero, La tercera parte divulga cualquier información no leída o no enviada en la Cuenta de Opciones.</p>
<p>
	<br>
</p>
<p>12. Incumplimiento del Acuerdo</p>
<p>
	<br>
</p>
<p>Si violas este Acuerdo o cualquier ley, norma o regulación aplicable, deberás pagarnos al menos $2 millones en daños y perjuicios y serás responsable de todos los costos, incluidos honorarios legales ("Indemnización"), que incurramos como resultado de dicho incumplimiento. Si la compensación es insuficiente para cubrir nuestras pérdidas, serás responsable de cualquier cantidad más allá de la compensación.</p>
<p>
	<br>
</p>
<p>Para evitar dudas, la terminación de este Acuerdo no impedirá que la parte no observadora requiera que la parte infractora asuma otras responsabilidades que surjan fuera de este Acuerdo.</p>
<p>
	<br>
</p>
<p>13. Notificaciones y Comunicaciones</p>
<p>
	<br>
</p>
<p>Los usuarios se comprometen a verificar los anuncios, materiales e información en la plataforma de manera oportuna y regular. No seremos responsables de ninguna forma de compensación si sufres pérdidas personales debido al negligente incumplimiento de leer anuncios, materiales e información.</p>
<p>
	<br>
</p>
<p>14. Otros</p>
<p>
	<br>
</p>
<p>(i) Asignación</p>
<p>
	<br>
</p>
<p>Los derechos y obligaciones estipulados en este Acuerdo son igualmente vinculantes para los cesionarios, sucesores, ejecutores y administradores que se beneficien de dichos derechos y obligaciones. No puedes ceder ninguno de tus derechos u obligaciones bajo este Acuerdo a ningún tercero sin nuestro consentimiento, pero nosotros podemos ceder nuestros derechos y obligaciones bajo este Acuerdo a cualquier tercero en cualquier momento dándote un aviso de 30 días.</p>
<p>
	<br>
</p>
<p>(ii) Fuerza Mayor</p>
<p>
	<br>
</p>
<p>Debido al mantenimiento de equipos de red de información, falla de conexión de red de información, errores informáticos, fallos del sistema como comunicaciones, cortes de energía, condiciones climáticas, accidentes, acciones laborales, disputas laborales, rebeliones, levantamientos, disturbios, falta de productividad o materiales de producción, incendios, inundaciones, tormentas, explosiones, guerras, fallos de bancos u otros socios, colapso de mercados de activos digitales, acciones de gobiernos, autoridades judiciales o ejecutivas, otras acciones más allá de nuestro control o fuera de nuestro control, o debido a terceros, en caso de que fallemos o demoremos en brindar servicios, no seremos responsables por dicho fallo o demora en brindar servicios a ti, ni por cualquier pérdida que puedas sufrir como resultado de dicho fallo o demora en brindar servicios.</p>
<p>
	<br>
</p>
<p>(iii) Relación entre las partes</p>
<p>
	<br>
</p>
<p>Este Acuerdo solo es vinculante respecto de los derechos y obligaciones entre tú y nosotros, y no implica relaciones legales y disputas legales que surjan de o estén relacionadas con transacciones entre usuarios de este sitio web y otras partes contigo.</p>
<p>
	<br>
</p>
<p>Excepto cuando se disponga lo contrario en este Acuerdo, nada en este Acuerdo se considerará que nos convierte, implica que somos o nos trata de tu agente, fiduciario u otro representante.</p>
<p>
	<br>
</p>
<p>(iv) Acuerdo Completo</p>
<p>
	<br>
</p>
<p>Si alguna disposición de este Acuerdo es considerada por cualquier tribunal de jurisdicción competente como no ejecutable, inválida o ilegal, la validez de las disposiciones restantes de este Acuerdo no se verá afectada.</p>
<p>
	<br>
</p>
<p>(v) Enmiendas</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de determinar, enmendar o modificar cualquier contenido de este Acuerdo en cualquier momento a nuestra sola discreción. La fecha y hora mostradas en la sección "Última Actualización" de este Acuerdo se refieren al momento en que ocurrieron cualquier cambio en los términos contenidos en este Acuerdo y el contenido de este Acuerdo. El acuerdo revisado entrará en vigor inmediatamente después de su publicación en la plataforma. Aunque hemos tomado medidas razonables para garantizar la precisión de la información en la Plataforma, no garantizamos el nivel de precisión y no somos responsables de ningún retraso o falla causada por la información en la Plataforma o por la imposibilidad de conectarse a Internet, transmitir o recibir cualquier notificación e información y cualquier pérdida incurrida directa o indirectamente.</p>
<p>
	<br>
</p>
<p>Es tu responsabilidad estar al tanto de las últimas revisiones de este Acuerdo. Si no estás de acuerdo con el contenido revisado, deja de usar el servicio inmediatamente. Al seguir utilizando el servicio, se considera que aceptas los términos de este acuerdo.</p>
<p>
	<br>
</p>
<p>(vi) Subsistencia</p>

<p>
	<br>
</p>
<p>Todas las disposiciones de este Acuerdo que, por su naturaleza, sobrevivan a la expiración o terminación de este Acuerdo sobrevivirán a la expiración o terminación de este Acuerdo y sobrevivirán a la expiración o terminación de este Acuerdo.</p>
<p>
	<br>
</p>
<p>(vii) Renuncia</p>
<p>
	<br>
</p>
<p>La renuncia por parte de nosotros o de ti del derecho a perseguir la responsabilidad de la otra parte por incumplimiento de este Acuerdo o cualquier otra responsabilidad acordada en este Acuerdo no se interpretará ni se considerará como una renuncia del derecho de la otra parte a perseguir otro incumplimiento contractual. La falta de ejercicio de cualquier derecho o recurso no se interpretará de ninguna manera como una renuncia a dicho derecho o recurso.</p>
<p>
	<br>
</p>
<p>(viii) Ley Aplicable</p>
<p>
	<br>
</p>
<p>La relación entre este Acuerdo y nosotros se rige por y se interpreta de acuerdo con las leyes de Seychelles.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>Apéndice 4: Acuerdo de Servicio de Trading Spot de Huobi</p>
<p>
	<br>
</p>
<p>1. Plataforma de Trading Spot de Huobi</p>
<p>
	<br>
</p>
<p>La Plataforma de Trading Spot de Huobi (en adelante referida como "Plataforma", "nosotros" o "nuestro") permite a sus usuarios (en adelante referidos como "Usuarios" o "Tú") utilizar cuentas spot, cuentas de margen y cuentas cuantitativas (según se define en este Acuerdo, Colectivamente referidas como "Cuentas de Plataforma") para transacciones de activos digitales (según se define en este Acuerdo) (en adelante referidas como "Servicios"). El Acuerdo de Servicio de Trading Spot de Huobi (en adelante referido como el "Acuerdo") se aplica a todos los servicios proporcionados en la plataforma. Para mayor comodidad, tú y nosotros nos referimos colectivamente como las "Partes" y individualmente como las "Partes".</p>
<p>
	<br>
</p>
<p>Todos los términos y referencias utilizados en este Acuerdo y definidos en el "Acuerdo del Usuario de la Plataforma de Huobi" ("Acuerdo del Usuario") pero no definidos en este Acuerdo tendrán el mismo significado e interpretación en los términos de este Acuerdo.</p>
<p>
	<br>
</p>
<p>Este Acuerdo es además del Acuerdo del Usuario y sus términos deben leerse en conjunto con el Acuerdo del Usuario, directrices, Reglas de la Plataforma, anuncios o cualquier otra información que podamos publicar en la Plataforma de vez en cuando. En caso de cualquier conflicto entre los términos de este Acuerdo y los términos del Acuerdo del Usuario, el Acuerdo del Usuario prevalecerá.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>2. Términos Generales</p>
<p>
	<br>
</p>
<p>Antes de utilizar los Servicios a través de la Plataforma, debes leer este Acuerdo detenidamente y buscar asesoramiento legal independiente si es necesario. Si no estás de acuerdo o no comprendes este acuerdo, cancela tu cuenta de plataforma inmediatamente y deja de utilizar el servicio. Al hacer clic en "Confirmar", "Acepto", crear una cuenta de plataforma, acceder a una cuenta de plataforma o utilizar los servicios, se considerará que has entendido completamente, acatado y aceptado este Acuerdo (incluidas las modificaciones realizadas a este Acuerdo de vez en cuando).</p>
<p>
	<br>
</p>
<p>Todos los encabezados en este Acuerdo están escritos solo por conveniencia y no tienen la intención de ampliar o limitar el contenido o el alcance de los términos y condiciones de este Acuerdo. Toda la información en la Plataforma y este Acuerdo se proporciona "tal cual" y "según esté disponible" y ya no hacemos ningún compromiso. Los encabezados en este acuerdo son solo para conveniencia y no rigen el significado o la interpretación de ningún término de este acuerdo.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>3. Definiciones</p>
<p>
	<br>
</p>
<p>"Acuerdo" significa el Acuerdo de Servicio de Trading Spot de Huobi.</p>
<p>
	<br>
</p>
<p>Ver la cláusula 16 para el significado de "recompensa de lanzamiento aéreo".</p>
<p>
	<br>
</p>
<p>"AML/CTF" significa Anti-Lavado de Dinero/Contra-Financiamiento del Terrorismo.</p>
<p>
	<br>
</p>
<p>"Día Hábil" significa un día que no sea sábado, domingo o un día bancario declarado como feriado público.</p>
<p>
	<br>
</p>
<p>"Indemnización" tiene el significado dado en el Artículo 18.</p>
<p>
	<br>
</p>
<p>El significado de "Procesamiento de la Cuenta de Plataforma" se establece en el Artículo 4.4.</p>
<p>
	<br>
</p>
<p>"Activo Digital" significa una representación digital de valor que puede transferirse, almacenarse y negociarse digitalmente en la Plataforma.</p>
<p>
	<br>
</p>
<p>"Tarifa" tiene el significado dado en la cláusula 13.</p>
<p>
	<br>
</p>
<p>Ver el Artículo 9 para el significado de "Liquidación Forzosa".</p>
<p>
	<br>
</p>
<p>"Evento No Elegible" tiene el significado establecido en la Cláusula 16.</p>
<p>
	<br>
</p>
<p>"KYC" significa Conoce a tu Cliente.</p>
<p>
	<br>
</p>
<p>Ver la Cláusula 9 para el significado de "Nivel de Cierre".</p>
<p>
	<br>
</p>
<p>"Préstamo" tiene el significado en la cláusula 8.</p>
<p>
	<br>
</p>
<p>"Margen" tiene el significado en la cláusula 7.</p>
<p>
	<br>
</p>
<p>Ver la cláusula 7 para el significado de "Factor de Ajuste de Margen".</p>
<p>
	<br>
</p>
<p>"Ratio de Margen" tiene el significado en la cláusula 8.</p>
<p>
	<br>
</p>
<p>"Requisito de Margen" tiene el significado en la cláusula 7.</p>
<p>
	<br>
</p>
<p>"Servicio de Margen" tiene el significado en la cláusula 7.</p>
<p>
	<br>
</p>
<p>Ver el Artículo 14 para el significado de "conducta irregular en el mercado".</p>
<p>
	<br>
</p>
<p>"Orden" tiene el significado en la cláusula 6.</p>

<p>
	<br>
</p>
<p>"Tipo de Orden" significa el tipo de orden que podemos ofrecerte para ejecutar una orden en la Plataforma.</p>
<p>
	<br>
</p>
<p>"Plataforma" significa la Plataforma de Trading Spot de Huobi.</p>
<p>
	<br>
</p>
<p>El significado de "Cuenta de Plataforma" se establece en el Artículo 1.</p>
<p>
	<br>
</p>
<p>Consulta la cláusula 4.4 para el significado de "tiempo antes de cerrar una posición".</p>
<p>
	<br>
</p>
<p>"Información de Registro" tiene el significado en el Artículo 4.1.</p>
<p>
	<br>
</p>
<p>"Servicios" significa los servicios relacionados con el trading spot que te proporcionamos en la Plataforma de vez en cuando.</p>
<p>
	<br>
</p>
<p>"Transacción" tiene el significado establecido en la cláusula 4.2.</p>
<p>
	<br>
</p>
<p>"Acuerdo del Usuario" significa el Acuerdo del Usuario de la Plataforma de Huobi.</p>
<p>
	<br>
</p>
<p>"Usuario" significa un Usuario de la Plataforma.</p>
<p>
	<br>
</p>
<p>4. Cuenta de Plataforma</p>
<p>
	<br>
</p>
<p>4.1 Crear una cuenta de plataforma</p>
<p>
	<br>
</p>
<p>Para ser elegible para crear una Cuenta de Plataforma, debes confirmar que eres una persona natural, jurídica o cualquier otra entidad autorizada para firmar este Acuerdo y usar los Servicios de acuerdo con todas las leyes, normas y regulaciones aplicables. No puedes crear una cuenta de plataforma con el fin de violar ninguna ley, norma y regulación aplicable y/o perturbar los servicios proporcionados por la plataforma.</p>
<p>
	<br>
</p>
<p>Si no estás calificado para crear una cuenta de plataforma, nos reservamos el derecho de negarte el registro de una cuenta de plataforma; si has creado una cuenta de plataforma, tenemos el derecho de suspender, terminar y/o cerrar tu cuenta de plataforma sin previo aviso, y responsabilizarte de cualquier consecuencia adversa que surja de ello. Nos reservamos el derecho, a nuestra entera discreción, de rechazar cualquier solicitud para crear una cuenta de plataforma (incluidas, entre otras, las cuentas de plataforma asociadas con figuras políticas y/o públicas), o para cumplir con las leyes y regulaciones de prevención del lavado de dinero y financiamiento del terrorismo.</p>
<p>
	<br>
</p>
<p>La creación de una cuenta de plataforma requiere proporcionar la información necesaria para completar el proceso de "conoce a tu cliente" de la plataforma, que puede incluir:</p>
<p>
	<br>
</p>
<p>(i) una dirección de correo electrónico válida;</p>
<p>
	<br>
</p>
<p>(ii) número de teléfono móvil;</p>
<p>
	<br>
</p>
<p>(iii) nombre real;</p>
<p>
	<br>
</p>
<p>(iv) prueba de identidad;</p>
<p>
	<br>
</p>
<p>(v) cualquier otra información requerida para crear y mantener una cuenta spot;</p>
<p>
	<br>
</p>
<p>Referidas colectivamente como "Materiales de Registro".</p>
<p>
	<br>
</p>
<p>Si tu número de móvil requiere registro de nombre real, debes completar dicho registro antes de proporcionarnos tu número de móvil. Serás responsable y estarás obligado por cualquier pérdida directa o indirecta y cualquier consecuencia adversa resultante de la no inscripción.</p>
<p>
	<br>
</p>
<p>Es tu responsabilidad actualizar tu información de registro para asegurarte de que permanezca precisa. Eres responsable de la autenticidad, integridad y exactitud de la información de registro, y asumes cualquier pérdida directa o indirecta y consecuencias adversas causadas por información de registro inexacta.</p>
<p>
	<br>
</p>
<p>Después de que se verifique y apruebe la creación de la cuenta de plataforma, puedes transferir activos digitales a la cuenta de plataforma y utilizar el servicio. Aceptas recibir correos electrónicos y/o información de nuestra parte relacionada con la administración y operación de la Plataforma.</p>
<p>
	<br>
</p>
<p>4.2 Uso de la Cuenta de Plataforma</p>
<p>
	<br>
</p>
<p>Tienes derecho a usar la cuenta de plataforma para acceder a la plataforma y usar los servicios, que incluyen:</p>
<p>
	<br>
</p>
<p>(i) consultar cotizaciones en tiempo real e información de transacciones de activos digitales en la plataforma;</p>
<p>
	<br>
</p>
<p>(ii) enviar una transacción de activos digitales, ya sea en una transacción o de otro modo (cada una una "Transacción");</p>
<p>
	<br>
</p>
<p>(iii) Completar la Transacción;</p>

<p>
	<br>
</p>
<p>(iv) usar la cuenta de plataforma para ver la información proporcionada al usuario;</p>
<p>
	<br>
</p>
<p>(v) participar en eventos organizados por nosotros de acuerdo con las reglas relevantes del evento; y/o</p>
<p>
	<br>
</p>
<p>(vi) otros servicios que podamos proporcionarte de vez en cuando.</p>
<p>
	<br>
</p>
<p>4.3 Seguridad de la Cuenta de Plataforma</p>
<p>
	<br>
</p>
<p>No puedes donar, prestar, arrendar, ceder o transferir de otra manera tu Cuenta de Plataforma a ninguna otra persona excepto con nuestro consentimiento.</p>
<p>
	<br>
</p>
<p>No estamos obligados a publicar ningún activo digital sustituto si se pierde, se roba, falla, se destruye o no está disponible de otra manera algún activo digital, contraseña o clave.</p>
<p>
	<br>
</p>
<p>4.4 Suspensión, Terminación o Cierre</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de suspender la provisión de algunos o todos los servicios a la Cuenta de Plataforma, congelar activos digitales en la Cuenta de Plataforma, terminar la Cuenta de Plataforma, cerrar la Cuenta de Plataforma y/o notificar a las autoridades pertinentes (“Gestión de la Cuenta de Plataforma”) en las siguientes circunstancias:</p>
<p>
	<br>
</p>
<p>(i) tenemos motivos para creer que necesitamos hacerlo para proteger nuestra reputación;</p>
<p>
	<br>
</p>
<p>(ii) el uso de tu Cuenta de Plataforma está sujeto a algún litigio pendiente, investigación o proceso gubernamental, y/o creemos que la actividad de tu Cuenta de Plataforma corre un alto riesgo de violar leyes o regulaciones;</p>
<p>
	<br>
</p>
<p>(iii) no cumples los requisitos para crear una cuenta de plataforma según se establece en la cláusula 4.1;</p>
<p>
	<br>
</p>
<p>(iv) creas una Cuenta de Plataforma en nombre de una entidad legal que no sea una persona autorizada para actuar en nombre de esa entidad legal;</p>
<p>
	<br>
</p>
<p>(v) a nuestra entera discreción, no eres adecuado para usar los Servicios;</p>
<p>
	<br>
</p>
<p>(vi) no eres la persona mencionada en la información de registro de cuenta de plataforma pertinente;</p>
<p>
	<br>
</p>
<p>(vii) no has iniciado sesión en la cuenta de plataforma durante un año consecutivo;</p>
<p>
	<br>
</p>
<p>(viii) utilizas la Cuenta de Plataforma para participar en actividades ilegales o impropias, como el lavado de dinero y el soborno;</p>
<p>
	<br>
</p>
<p>(ix) utilizas tu cuenta de plataforma para participar en manipulación del mercado, comercio desleal u otras actividades comerciales ilegales, como el lavado de dinero, el contrabando y el soborno comercial; y/o</p>
<p>
	<br>
</p>
<p>(x) Incumplimiento de este Acuerdo o de cualquier otra ley, norma y regulación aplicable.</p>
<p>
	<br>
</p>
<p>No obstante lo anterior, nos reservamos el derecho de cerrar y terminar tu cuenta de plataforma con un aviso de 7 días hábiles para ti. Luego, tienes 7 días hábiles para tomar cualquier acción necesaria, incluida la cancelación de cualquier orden o la celebración de cualquier operación ("Tiempo Antes de Cerrar la Posición"). Después del tiempo antes de cerrar la posición, tenemos derecho a cancelar tu orden, cerrar la posición y devolverte los activos digitales en tu cuenta de plataforma. No obstante cualquier cosa en este Acuerdo, si tu cuenta de plataforma ha sido cerrada de acuerdo con estos Términos, todos los saldos de la cuenta (incluidas las tarifas y deudas que nos debas) se nos pagarán de inmediato.</p>
<p>
	<br>
</p>
<p>No somos responsables de ninguna consecuencia adversa y/o pérdidas asociadas con el procesamiento de cuentas en la Plataforma.</p>
<p>
	<br>
</p>
<p>En caso de suspensión o cierre de cuentas de plataforma debido a investigaciones de fraude, investigaciones ilegales o incumplimientos de este Acuerdo, tendremos la custodia completa de activos digitales, fondos e información/datos de usuario que puedan ser entregados a las autoridades gubernamentales.</p>
<p>
	<br>
</p>
<p>5. Depósito, Fideicomiso, Retiro y Transferencia</p>
<p>
	<br>
</p>
<p>Los servicios solo están disponibles para activos digitales que admitimos, y dichos activos digitales pueden cambiar de vez en cuando. En ningún caso debes usar tu Cuenta de Plataforma para almacenar, enviar, solicitar o recibir activos digitales en ninguna forma que no admitamos. No somos responsables ni responsables de ningún intento de utilizar cuentas de plataforma para activos digitales que no admitamos. Te proporcionamos servicios y no somos compradores o vendedores de ninguna transacción de activos digitales. Los servicios no incluyen ningún depósito o retiro o custodia de ninguna moneda fiduciaria.</p>
<p>
	<br>
</p>
<p>A menos que la Plataforma o este Acuerdo dispongan lo contrario, todos los activos digitales mantenidos en la Cuenta de Plataforma se mantienen en fideicomiso por nosotros. Es decir:</p>
<p>
	<br>
</p>
<p>(i) La titularidad de los activos digitales permanece contigo y no se transfiere a nosotros. Dado que el propietario de los activos digitales es tu cuenta de plataforma, debes asumir todo el riesgo de pérdida de dichos activos digitales. No afirmamos que los activos en la cuenta de plataforma nos pertenezcan, ni consideramos dichos activos como propios;</p>
<p>
	<br>
</p>
<p>(ii) No venderemos, transferiremos, prestaremos, hipotecaremos o transferiremos de otra manera activos en la Cuenta de Plataforma a menos que lo exijan las autoridades pertinentes o se disponga de otra manera en este Acuerdo, excepto según lo indiques tú o lo ordene un tribunal de jurisdicción competente;</p>
<p>
	<br>
</p>
<p>(iii) Tú eres responsable de los activos digitales en tu cuenta de plataforma. Puedes retirar Activos Digitales en cualquier momento enviando Activos Digitales a otra cuenta de Huobi y/o a otra dirección de blockchain controlada por ti o un tercero, sujeto a interrupciones, tiempo de inactividad y otras políticas aplicables;</p>
<p>
	<br>
</p>
<p>(iv) las entradas de contabilidad separadas en un libro mayor para tu cuenta y nuestra cuenta separan tus activos digitales de nuestros propios activos digitales o fondos. No obstante lo anterior, no estamos obligados a usar diferentes direcciones de blockchain para almacenar tus activos digitales y los de otros clientes o los nuestros.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de establecer y ajustar el volumen diario de transacciones y/o el volumen diario de negociación en función de consideraciones internas, incluida cualquier investigación por parte de las autoridades pertinentes, la seguridad y/o el estado de la red de activos digitales relevante y/o cualquier préstamo vencido y exigible (según se define en este Acuerdo). Límite de retiro.</p>
<p>
	<br>
</p>
<p>6. Trading Spot</p>

<p>
	<br>
</p>
<p>Después de registrarte con éxito en una cuenta spot, puedes transferir activos digitales a la cuenta spot para realizar un pedido ("Orden") en la plataforma. Puedes ejecutar órdenes a través de los tipos de órdenes disponibles en la plataforma ("Tipo de orden individual"), comprar y/o vender tus activos digitales actualmente disponibles en la plataforma.</p>
<p>
	<br>
</p>
<p>Cada usuario es responsable de cualquier orden realizada en la Plataforma, incluyendo asegurarse de que las órdenes se envíen correctamente en la Plataforma a través del sitio web relevante, la aplicación móvil u otros medios permitidos por nosotros de vez en cuando. De vez en cuando, la plataforma puede rechazar una orden y es tu responsabilidad asegurarte de que la orden se ejecute. No somos responsables de ninguna pérdida causada por tu incapacidad para enviar cualquier orden o la orden ser rechazada por la plataforma. El envío de órdenes a la Plataforma es bajo tu propio riesgo. Tenemos el derecho de confiar, y tú estás obligado por, todas las órdenes realizadas y todas las transacciones ejecutadas a través de tu cuenta spot.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de modificar, suspender o terminar cualquier o todos los tipos de órdenes y activos digitales negociables en la Plataforma en cualquier momento a nuestra sola discreción; no somos responsables de ninguna pérdida que sufras como resultado de dicha modificación, suspensión o terminación.</p>
<p>
	<br>
</p>
<p>7. Trading con Margen</p>
<p>
	<br>
</p>
<p>El Servicio de Margen ("Servicio de Margen") proporcionado por la Plataforma te permite utilizar activos digitales en tu Cuenta Spot y/o Cuenta de Margen ("Margen") para pedir prestados otros activos digitales para ejecutar operaciones en la Plataforma. Al utilizar servicios de margen, aceptas que comprendes completamente los riesgos asociados con los servicios de margen y aceptas que utilizarás los servicios de margen bajo tu propio riesgo.</p>
<p>
	<br>
</p>
<p>Debes depositar y mantener Activos Digitales en la Cuenta Spot y/o Cuenta de Margen en la forma y cantidad requerida por nosotros, mediante la cual podemos solicitar Servicios de Margen de Mantenimiento de vez en cuando a nuestra sola discreción. La lista de activos digitales aprobados por nosotros como elegibles para margen será prescrita por nosotros, y ciertos activos digitales solo pueden valorarse de acuerdo con un factor de ajuste de margen ("Factor de Ajuste de Margen"). El factor de ajuste de margen se refiere al factor que convierte el margen de tokens a su precio de mercado al calcular el monto del margen. No estamos obligados a informarte de ningún cambio en la valoración de activos digitales utilizados como margen.</p>
<p>
	<br>
</p>
<p>Tenemos la exclusiva discreción de determinar los requisitos de margen en la Plataforma ("Requisitos de Margen") y nos reservamos el derecho de cambiar los Requisitos de Margen de vez en cuando sin previo aviso a ti. A menos que se indique lo contrario, tales cambios en los requisitos de margen serán efectivos inmediatamente después de que proporcionemos un aviso por escrito de dichos cambios. Sujeto a tales cambios, es posible que se te requiera agregar margen de inmediato a tu cuenta spot y/o cuenta de margen para ejecutar cualquier operación o mantener cualquier posición en la plataforma. Si necesitas una llamada de margen, no estamos obligados a considerar activos digitales fuera de tu cuenta spot y/o cuenta de margen.</p>
<p>
	<br>
</p>
<p>Todos los depósitos serán retenidos por nosotros como garantía continua. Todos estos depósitos están sujetos a gravámenes generales y derechos de compensación en nuestro favor con respecto a cualquier y todas las obligaciones, responsabilidades o dinero adeudado por ti en cualquier momento y que nos corresponda bajo este Acuerdo o de otra manera. Tenemos el derecho de honrar tu depósito de seguridad de acuerdo con las disposiciones de este Acuerdo.</p>
<p>
	<br>
</p>
<p>Tenemos el derecho de depositar, empeñar, volver a empeñar, dar en prenda, volver a hipotecar, invertir o prestar cualquier margen que nos hayas proporcionado, pero no estamos obligados a informarte sobre ningún interés, ingreso o beneficio que pueda derivarse de ello. No te pagaremos intereses sobre los depósitos que realices bajo este Acuerdo y retendremos todo ese interés y beneficio para nuestro propio beneficio. No somos responsables de ninguna pérdida de margen, directa o indirectamente, como resultado del cierre, quiebra, insolvencia, liquidación, administración judicial u otro evento de cualquier tercero en el que se deposite o se dé en prenda el margen.</p>
<p>
	<br>
</p>
<p>8. Préstamos</p>
<p>
	<br>
</p>
<p>De acuerdo con los términos de este Acuerdo, acordamos proporcionarte un préstamo de activos digitales para apoyar tus transacciones en la Plataforma, siempre que cumplas con la relación de margen (según se define en este Acuerdo) de tu Cuenta Spot y/o Cuenta de Margen ("Préstamo"). Nos reservamos el derecho de cambiar o cancelar, en parte o en su totalidad, cualquier monto de préstamo que te hagamos. No obstante cualquier cosa en este Acuerdo, no estamos obligados a proporcionar o continuar proporcionando ningún préstamo a ti. Aceptas utilizar cualquier préstamo que te proporcionemos solo para servicios en la plataforma.</p>
<p>
	<br>
</p>
<p>Aceptas mantener un monto de margen mínimo en la Cuenta Spot y/o Cuenta de Margen en todo momento para alcanzar la Relación de Margen de la Plataforma ("Relación de Margen") especificada por nosotros de vez en cuando. Eres el único responsable de monitorear los requisitos de relación de margen para la Cuenta Spot y/o la Cuenta de Margen. Si el margen en la cuenta spot y/o la cuenta de margen es insuficiente para cumplir con la relación de margen mínima, es tu responsabilidad transferir el margen adicional a la cuenta spot y/o la cuenta de margen independientemente de si recibes una llamada de margen para cumplir con la relación de margen mínima.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>9. Cierre de una posición</p>
<p>
	<br>
</p>
<p>Reconoces y aceptas que si la relación de margen de la cuenta spot y/o de la cuenta de margen es inferior a la relación de margen mínima requerida por la Plataforma ("Nivel de Liquidación"), aceptas que podemos liquidar cualquier margen en la cuenta spot y/o de margen a nuestra sola discreción, y/o cancelar cualquier órdenes abiertas en la Cuenta Spot y/o en la Cuenta de Margen para que tu Relación de Margen sea mayor que el Nivel de Liquidación (“Liquidación"). No estamos obligados a darte aviso antes de iniciar una liquidación de tu Cuenta Spot y/o Margen en tu Cuenta de Margen. En caso de tal liquidación forzada, aceptas:</p>
<p>
	<br>
</p>
<p>(i) eres responsable de todos los costos asociados con cualquier liquidación;</p>
<p>
	<br>
</p>
<p>(ii) eres responsable de cualquier pérdida (ya sea realizada o no) resultante de la liquidación forzada;</p>
<p>
	<br>
</p>
<p>(iii) No somos responsables de ninguna pérdida (ya sea realizada o no) resultante de la liquidación forzada.</p>
<p>
	<br>
</p>
<p>Aceptas que serás responsable de cualquier monto restante adeudado a nosotros si el margen vendido es insuficiente para pagar el préstamo de margen y cualquier interés devengado. Dependiendo del plazo y el monto del préstamo que te ofrecemos, incurrirás en tasas de interés más altas.</p>
<p>
	<br>
</p>
<p>10. Apalancamiento</p>
<p>
	<br>
</p>
<p>La plataforma te permite ejecutar operaciones apalancadas en función del monto proporcionado por la plataforma en el momento de la transacción. Después de seleccionar el límite de apalancamiento, todas tus posiciones deben usar el mismo límite de apalancamiento. Al ejecutar una operación apalancada, aceptas y comprendes que:</p>
<p>
	<br>
</p>
<p>(i) los riesgos asociados con el uso de apalancamiento para operar con activos digitales;</p>
<p>
	<br>
</p>
<p>(ii) las posiciones apalancadas requieren un mayor nivel de gestión de riesgos y tolerancia al riesgo, y has evaluado estos riesgos por tus propios medios;</p>
<p>
	<br>
</p>
<p>(iii) asumes todos los riesgos y pérdidas asociados con el uso de apalancamiento al operar con activos digitales en la plataforma;</p>
<p>
	<br>
</p>
<p>(iv) No somos responsables de ninguna pérdida que puedas sufrir al operar con posiciones apalancadas en la Plataforma.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>11. Robots de Trading</p>
<p>
	<br>
</p>
<p>La función de robot de trading de la cuenta cuantitativa ("Robot de Trading") te permite establecer un rango de precios, y la plataforma te ayudará a establecer órdenes de compra y/o venta de activos digitales ("Grid") dentro del rango de precios especificado. La grilla debe incluir los siguientes parámetros:</p>
<p>
	<br>
</p>
<p>(i) Topes;</p>
<p>
	<br>
</p>
<p>(ii) el límite inferior;</p>
<p>
	<br>
</p>
<p>(iii) el número de grillas;</p>
<p>
	<br>
</p>
<p>(iv) capital inicial;</p>
<p>
	<br>
</p>
<p>(v) nivel de ganancia;</p>
<p>
	<br>
</p>
<p>(vi) nivel de stop loss,</p>
<p>
	<br>
</p>
<p>Colectivamente referidos como "parámetros".</p>
<p>
	<br>
</p>
<p>Puedes establecer parámetros manualmente o basándote en entradas recomendadas por la plataforma, y copiar parámetros de otros usuarios.</p>
<p>
	<br>
</p>
<p>Al utilizar el robot de trading, aceptas:</p>
<p>
	<br>
</p>
<p>(a) Entiendes y aceptas las divulgaciones de riesgos y renuncias pertinentes;</p>
<p>
	<br>
</p>
<p>(b) Has aprobado la Prueba de Conocimientos del Robot de Trading;</p>
<p>
	<br>
</p>
<p>(c) No somos responsables de ninguna pérdida que surja de tu selección de la plataforma para la entrada recomendada por la grilla;</p>
<p>
	<br>
</p>
<p>(d) No somos responsables de ninguna pérdida incurrida como resultado de ejecutar operaciones a través de robots de trading;</p>
<p>
	<br>
</p>
<p>(e) Eres responsable de monitorear y gestionar el Robot de Trading;</p>
<p>
	<br>
</p>
<p>(f) La tasa de rendimiento esperada que se muestra en el sitio web es una estimación y no somos responsables de ninguna diferencia entre la tasa de rendimiento esperada y la ganancia o pérdida real que generarás al usar el robot de trading.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>12. Cálculo</p>
<p>
	<br>
</p>
<p>No obstante cualquier cosa en este Acuerdo y las Reglas de Trading de la Plataforma, a menos que lo indiquemos de otra manera, aceptas que todos los cálculos realizados por la Plataforma son cálculos finales y concluyentes.</p>
<p>
	<br>
</p>
<p>13. Tarifas y Seguros</p>
<p>
	<br>
</p>
<p>Tenemos el derecho de cobrar intereses sobre cualquier préstamo hecho a ti a una tasa que podamos determinar de vez en cuando.</p>
<p>
	<br>
</p>
<p>Debes pagar prontamente cualquier tarifa, interés y comisiones ("Tarifas") adeudadas a nosotros y/o a nuestros Proveedores de Servicios de la manera que podamos estipular de vez en cuando con respecto al uso de los Servicios. Nos reservamos el derecho de modificar las tarifas a nuestra sola discreción sin previo aviso a ti, y es tu responsabilidad asegurarte de estar al tanto de las tarifas asociadas con los Servicios.</p>
<p>
	<br>
</p>
<p>Aceptas que cualquier tarifa pendiente puede liquidarse debitando el monto relevante de tu cuenta en la Plataforma u otras cuentas que puedas tener con nosotros; también aceptas que las tarifas pendientes son inmediatamente exigibles y pagaderas a las partes relevantes endeudadas.</p>
<p>
	<br>
</p>
<p>Si no puedes pagar cualquier tarifa pendiente en los Activos Digitales relevantes, tenemos el derecho de pagar al tipo de cambio que determinemos en el momento del pago a cualquier otro monto que se retenga en tu Cuenta en la Plataforma u otras cuentas que tengas con nosotros en Activos Digitales para el pago o reembolso.</p>
<p>
	<br>
</p>
<p>Cualquier impuesto, arancel, gasto, costo y/o otros cargos aplicables que incurramos como resultado de tu uso de los Servicios son tu responsabilidad.</p>
<p>
	<br>
</p>
<p>14. Conducta en el Mercado</p>
<p>
	<br>
</p>
<p>En ningún caso debes participar en lo que creemos que es una conducta de mercado indebida ("Conducta en el Mercado"), incluyendo pero no limitado a:</p>
<p>
	<br>
</p>
<p>(i) participar en cualquier forma de manipulación de mercado, como órdenes falsificadas, transacciones falsas o manipulación de precios;</p>
<p>
	<br>
</p>
<p>(ii) tomar cualquier acción que cree una representación falsa o engañosa de una transacción o precio;</p>
<p>
	<br>
</p>
<p>(iii) tomar cualquier acción para mantener precios artificiales o manipular precios;</p>
<p>
	<br>
</p>
<p>(iv) divulgar información falsa o engañosa sobre la Plataforma; y/o</p>
<p>
	<br>
</p>
<p>(v) tomar o abstenerse de tomar acciones que puedan constituir fraude.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de utilizar sistemas de monitoreo para supervisar tu actividad en la Plataforma y tomar cualquier acción que consideremos necesaria para abordar cualquier conducta de mercado real o potencial.</p>
<p>
	<br>
</p>
<p>15. Acceso a la API</p>

<p>
	<br>
</p>
<p>Dependiendo de tus calificaciones, es posible que se te otorgue acceso a la API para utilizar los servicios en la plataforma. Nos reservamos el derecho de limitar, suspender o terminar cualquier acceso a la API otorgado a ti. Si utilizas la API para acceder a los Servicios, aceptas que no seremos responsables de ninguna pérdida que sufras por tu uso de la API relevante.</p>
<p>
	<br>
</p>
<p>16. Sin Recompensas por Airdrop</p>
<p>
	<br>
</p>
<p>Aceptas que eres elegible para recibir cualquier Recompensa por Airdrop ("Recompensas por Airdrop"); no tienes derechos legales o equitativos sobre tales Recompensas por Airdrop ("Eventos de No Elegibilidad") con respecto a ningún Activo Digital que hayas tomado prestado.</p>
<p>
	<br>
</p>
<p>Si recibes alguna recompensa por airdrop basada en cualquier evento de no elegibilidad, es tu responsabilidad devolver cualquier recompensa por airdrop a nosotros y nos reservamos el derecho de transferir las recompensas por airdrop de tu cuenta en la plataforma a nuestra propia billetera propietaria a nuestra entera discreción. Podemos, a nuestra entera discreción, restringir tu retiro de activos digitales de la Plataforma antes de realizar dichas transferencias.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>17. Terminación y Remedios por Incumplimiento</p>
<p>
	<br>
</p>
<p>Tenemos el derecho de terminar todos los servicios que te brindamos en cualquier momento de acuerdo con este Acuerdo, y este Acuerdo también terminará en la fecha de terminación relevante de todos los servicios. Tienes el derecho de terminar el uso de los servicios que te proporcionamos en cualquier momento, y este acuerdo también terminará en la fecha relevante cuando termines el uso de los servicios. Después de la terminación de este acuerdo, aceptas que no tienes derecho a exigirnos que sigamos brindándote servicios o cumpliendo con ninguna otra obligación, incluyendo pero no limitado a exigirnos que retengamos cualquier información en la cuenta de la plataforma o divulguemos tal información a ti, o que tú o cualquier tercero El tercero divulga cualquier información no leída o no enviada en la cuenta de la plataforma.</p>
<p>
	<br>
</p>
<p>18. Incumplimiento del Acuerdo</p>
<p>
	<br>
</p>
<p>Si violas este Acuerdo o cualquier ley, regla o regulación aplicable, deberás pagarnos al menos $2 millones en daños y serás responsable de todos los costos, incluidos los honorarios legales ("Indemnización"), que incurramos como resultado de dicho incumplimiento. Si la compensación es insuficiente para cubrir nuestras pérdidas, serás responsable de cualquier monto más allá de la compensación.</p>
<p>
	<br>
</p>
<p>Para evitar dudas, la terminación de este Acuerdo no impedirá que la parte no observante requiera que la parte incumplidora asuma otras responsabilidades que surjan fuera de este Acuerdo.</p>
<p>
	<br>
</p>
<p>19. Notificaciones y Comunicaciones</p>
<p>
	<br>
</p>
<p>Los usuarios se comprometen a verificar los anuncios, materiales e información en la plataforma de manera oportuna y regular. No seremos responsables de ninguna forma de compensación si sufres pérdidas personales debido a la falta negligente de leer anuncios, materiales e información.</p>
<p>
	<br>
</p>
<p>20. Otros</p>
<p>
	<br>
</p>
<p>(i) Cesión</p>
<p>
	<br>
</p>
<p>Los derechos y obligaciones estipulados en este Acuerdo son igualmente vinculantes para los cesionarios, sucesores, ejecutores y gerentes que se beneficien de tales derechos y obligaciones. No puedes ceder ninguno de tus derechos u obligaciones bajo este Acuerdo a ningún tercero sin nuestro consentimiento, sin embargo, podemos transferir nuestros derechos u obligaciones bajo este Acuerdo en cualquier momento con un aviso de 30 días para ti. Los derechos y obligaciones se asignan a cualquier tercero.</p>
<p>
	<br>
</p>
<p>(ii) Fuerza Mayor</p>
<p>
	<br>
</p>
<p>Debido al mantenimiento del equipo de red de información, falla de conexión de red de información, errores informáticos, fallas del sistema como comunicaciones, fallas de energía, condiciones climáticas, accidentes, acciones laborales, disputas laborales, rebeliones, levantamientos, disturbios, falta de productividad o materiales de producción, incendios, inundaciones, tormentas, explosiones, guerras, fallas de bancos u otros socios, colapso de mercados de activos digitales, acciones de gobiernos, autoridades judiciales o ejecutivas, otras acciones fuera de nuestro control o fuera de nuestro control, o debido a terceros, En caso de nuestra falla o demora en la prestación de servicios, no seremos responsables de dicha falla o demora en la prestación de servicios a ti, ni de ninguna pérdida que puedas sufrir como resultado de dicha falla o demora en la prestación de servicios .</p>
<p>
	<br>
</p>
<p>(iii) Relación entre las partes</p>
<p>
	<br>
</p>
<p>Este Acuerdo solo es vinculante respecto de los derechos y obligaciones entre tú y nosotros, y no involucra relaciones legales y disputas legales derivadas de o relacionadas con transacciones entre usuarios de este sitio web y otras partes contigo.</p>
<p>
	<br>
</p>
<p>Excepto según lo dispuesto en este Acuerdo, nada en este Acuerdo se considerará que nos hace, implica que somos, o de otra manera nos trata como tu agente, fiduciario o representante.</p>
<p>
	<br>
</p>
<p>(iv) Acuerdo Completo</p>
<p>
	<br>
</p>
<p>Si alguna disposición de este Acuerdo es considerada por cualquier tribunal competente como inaplicable, inválida o ilegal, la validez de las disposiciones restantes de este Acuerdo no se verá afectada.</p>
<p>
	<br>
</p>
<p>(v) Enmiendas</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de determinar, enmendar o modificar cualquier contenido de este Acuerdo en cualquier momento a nuestra entera discre

ción. La fecha y hora mostradas en la sección "Última actualización" de este Acuerdo se refieren a cualquier cambio en los términos contenidos en este Acuerdo y el contenido de este Acuerdo. El acuerdo revisado entrará en vigencia inmediatamente después de su publicación en la plataforma. Si bien hemos tomado medidas razonables para garantizar la precisión de la información en la Plataforma, no garantizamos el nivel de precisión y no somos responsables de ningún retraso o falla causada por la información en la Plataforma o por la imposibilidad de conectarse a Internet, transmitir o recibir cualquier aviso e información y cualquier pérdida incurrida directa o indirectamente.</p>
<p>
	<br>
</p>
<p>Es tu responsabilidad estar al tanto de las últimas revisiones de este Acuerdo. Si no estás de acuerdo con el contenido revisado, detén inmediatamente el uso del servicio. Al continuar utilizando el servicio, se considera que aceptas los términos de este acuerdo.</p>
<p>
	<br>
</p>
<p>(vi) Subsistencia</p>
<p>
	<br>
</p>
<p>Todas las disposiciones de este Acuerdo que por su naturaleza sobrevivan a la expiración o terminación de este Acuerdo sobrevivirán a la expiración o terminación de este Acuerdo y sobrevivirán a la expiración o terminación de este Acuerdo.</p>
<p>
	<br>
</p>
<p>(vii) No Renuncia</p>
<p>
	<br>
</p>
<p>La renuncia por parte de nosotros o de ti del derecho a perseguir la responsabilidad de la otra parte por incumplimiento de este Acuerdo o cualquier otra responsabilidad acordada en este Acuerdo no se interpretará ni se considerará una renuncia al derecho de la otra parte a perseguir otro incumplimiento del contrato. La falta de ejercicio de cualquier derecho o la adopción de cualquier remedio no se interpretará de ninguna manera como una renuncia a dicho derecho o remedio.</p>
<p>
	<br>
</p>
<p>(viii) Ley Aplicable</p>
<p>
	<br>
</p>
<p>Este Acuerdo y la relación con nosotros están regidos por y se interpretan de acuerdo con las leyes de Seychelles.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>Apéndice 5: Acuerdo de Servicios Financieros de Huobi</p>
<p>
	<br>
</p>
<p>1. Plataforma de Servicios Financieros de Huobi</p>
<p>
	<br>
</p>
<p>La Plataforma de Servicios Financieros de Huobi (en adelante referida como "Plataforma", "nosotros" o "nosotros") permite a sus usuarios (en adelante referidos como "Usuarios" o "Tú") utilizar la cuenta spot, la cuenta "Rendimiento de Huobi" y la cuenta conjunta (definida Consulta este Acuerdo, referido colectivamente como "Cuentas de Plataforma") para utilizar los Productos (según se define en este Acuerdo) (en adelante referidos como "Servicios"). El Acuerdo de Servicios Financieros de Huobi (en adelante referido como el "Acuerdo") se aplica a todos los servicios proporcionados en la plataforma. Para mayor comodidad, tú y nosotros estamos referidos en forma colectiva como las "Partes" y en forma individual como las "Partes".</p>
<p>
	<br>
</p>
<p>Todos los términos y referencias utilizados en este Acuerdo y definidos en el Acuerdo de Usuario de Huobi Platform ("Acuerdo de Usuario") pero no definidos en este Acuerdo tendrán el mismo significado e interpretación en los términos de este Acuerdo.</p>
<p>
	<br>
</p>
<p>Este Acuerdo es además del Acuerdo de Usuario y sus términos deben leerse en conjunto con el Acuerdo de Usuario, pautas, Reglas de la Plataforma, anuncios o cualquier otra información que podamos publicar en la Plataforma de vez en cuando. En caso de conflicto entre los términos de este Acuerdo y los términos del Acuerdo de Usuario, el Acuerdo de Usuario prevalecerá.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>2. Términos Generales</p>
<p>
	<br>
</p>
<p>Antes de utilizar los Servicios a través de la Plataforma, debes leer este Acuerdo detenidamente y buscar asesoramiento legal independiente si es necesario. Si no estás de acuerdo o no entiendes este acuerdo, cancela tu cuenta en la plataforma inmediatamente y deja de usar el servicio. Al hacer clic en "Confirmar", "Acepto", crear una Cuenta en la Plataforma, acceder a una Cuenta en la Plataforma o utilizar los Servicios, se considerará que has entendido completamente, cumplido y aceptado este Acuerdo (incluidas las enmiendas realizadas a este Acuerdo de vez en cuando).</p>
<p>
	<br>
</p>
<p>Todos los encabezados de este Acuerdo se escriben solo por conveniencia y no tienen la intención de ampliar o limitar el contenido o el alcance de los términos y condiciones de este Acuerdo. Toda la información en la Plataforma y este Acuerdo se proporciona "tal cual" y "según esté disponible" y ya no hacemos ningún compromiso. Los encabezados de este acuerdo son solo para conveniencia y no rigen el significado o la interpretación de ningún término de este acuerdo.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>3. Definiciones</p>
<p>
	<br>
</p>
<p>"Acuerdo" significa el Acuerdo de Servicios Financieros de Huobi.</p>
<p>
	<br>
</p>
<p>Consulta la cláusula 14 para el significado de "recompensa por airdrop".</p>
<p>
	<br>
</p>
<p>"AML/CTF" significa Anti-Lavado de Dinero/Contra el Financiamiento del Terrorismo.</p>
<p>
	<br>
</p>
<p>"Día Laborable" significa un día que no sea sábado, domingo o un día bancario declarado como feriado.</p>
<p>
	<br>
</p>
<p>"Indemnización" tiene el significado dado en el Artículo 16.</p>
<p>
	<br>
</p>
<p>"Préstamo de Criptomonedas" tiene el significado establecido en la Cláusula 9.</p>
<p>
	<br>
</p>
<p>El significado de "Procesamiento de la Cuenta de la Plataforma" se establece en el Artículo 4.4.</p>
<p>
	<br>
</p>
<p>"Activo Digital" significa una representación digital de valor que puede transferirse, almacenarse y negociarse digitalmente en la Plataforma.</p>
<p>
	<br>
</p>
<p>"Tarifa" tiene el significado dado en la cláusula 11.</p>
<p>
	<br>
</p>
<p>Consulta el Artículo 8 para el significado de "Ingresos de Huobi".</p>
<p>
	<br>
</p>
<p>Consulta el Artículo 8 para el significado de "Cuenta de Ganancias de Huobi".</p>
<p>
	<br>
</p>
<p>"Evento No Elegible" tiene el significado establecido en la Cláusula 14.</p>
<p>
	<br>
</p>
<p>"KYC" significa Conoce a Tu Cliente.</p>
<p>
	<br>
</p>
<p>"Liquidación" tiene el significado dado en la Cláusula 9.</p>
<p>
	<br>
</p>
<p>"Préstamo" tiene el significado en la cláusula 9.</p>
<p>
	<br>
</p>
<p>"Margen" tiene el significado en la cláusula 9.</p>
<p>
	<br>
</p>
<p>"Ratio de Margen" tiene el significado en la cláusula 9.</p>
<p>
	<br>
</p>
<p>Consulta el Artículo 12 para el significado de "mala conducta en el mercado".</p>
<p>
	<br>
</p>
<p>"Plataforma" significa la Plataforma de Servicios Financieros de Huobi.</p>
<p>
	<br>
</p>
<p>El significado de "Cuenta de la Plataforma" se establece en el Artículo 1.</p>
<p>
	<br>
</p>
<p>"Cuenta Conjunta" tiene el significado dado en la cláusula 7.</p>
<p>
	<br>
</p>
<p>Consulta la cláusula 4.4 para el significado de "tiempo antes de cerrar una posición".</p>
<p>
	<br>
</p>
<p>"Primepool" tiene el significado en la cláusula 7.</p>
<p>
	<br>
</p>
<p>"Productos" incluyen participación, Primepool, "Rendimiento de Huobi", préstamos de criptomonedas y otros servicios que podamos ofrecer en la Plataforma de vez en cuando;</p>
<p>
	<br>
</p>
<p>"Información de Registro" tiene el significado en el Artículo 4.1.</p>
<p>
	<br>
</p>
<p>"Servicios" significa los servicios relacionados con los productos que te proporcionamos en la Plataforma de vez en cuando.</p>
<p>
	<br>
</p>
<p>"Cuenta Spot" tiene el significado en la cláusula 6.</p>
<p>
	<br>
</p>
<p>"Colateral" tiene el significado en la cláusula 6.</p>
<p>
	<br>
</p>
<p>"Transacción" tiene el significado establecido en la cláusula 4.2.</p>
<p>
	<br>
</p>
<p>"Acuerdo de Usuario" significa el Acuerdo de Usuario de la Plataforma Huobi.</p>
<p>
	<br>
</p>
<p>"Usuario" significa un Usuario de la Plataforma.</p>
<p>
	<br>
</p>
<p>4. Cuenta de la Plataforma</p>
<p>
	<br>
</p>
<p>4.1 Crear una cuenta en la plataforma</p>

<p>
	<br>
</p>
<p>Para ser elegible para crear una Cuenta en la Plataforma, debes confirmar que eres una persona natural, jurídica o cualquier otra entidad autorizada para firmar este Acuerdo y usar los Servicios de acuerdo con todas las leyes, normas y regulaciones aplicables. No puedes crear una cuenta en la plataforma con el propósito de violar ninguna ley, norma o regulación aplicable y/o interrumpir los servicios proporcionados por la plataforma.</p>
<p>
	<br>
</p>
<p>Si no estás calificado para crear una cuenta en la plataforma, nos reservamos el derecho de negarte el registro de una cuenta en la plataforma; si has creado una cuenta en la plataforma, tenemos el derecho de suspender, terminar y/o cerrar tu cuenta en la plataforma sin previo aviso, y te haremos responsable de cualquier consecuencia adversa que surja de ello. Nos reservamos el derecho, a nuestra entera discreción, de rechazar cualquier solicitud para crear una cuenta en la plataforma (incluidas, entre otras, las cuentas en la plataforma asociadas con figuras políticas y/o figuras públicas), o de cumplir con las leyes y regulaciones contra el lavado de dinero/financiamiento del terrorismo.</p>
<p>
	<br>
</p>
<p>La creación de una cuenta en la plataforma requiere proporcionar la información necesaria para completar el proceso de "conoce a tu cliente" de la plataforma, que puede incluir:</p>
<p>
	<br>
</p>
<p>(i) una dirección de correo electrónico válida;</p>
<p>
	<br>
</p>
<p>(ii) número de teléfono móvil;</p>
<p>
	<br>
</p>
<p>(iii) nombre real;</p>
<p>
	<br>
</p>
<p>(iv) prueba de identidad;</p>
<p>
	<br>
</p>
<p>(v) cualquier otra información requerida para crear y mantener una cuenta de spot;</p>
<p>
	<br>
</p>
<p>Referido colectivamente como "Materiales de Registro".</p>
<p>
	<br>
</p>
<p>Si tu número de móvil requiere un registro con nombre real, debes completar dicho registro antes de proporcionarnos tu número de móvil. Serás responsable y estarás obligado por cualquier pérdida directa o indirecta y cualquier consecuencia adversa resultante de no registrarte.</p>
<p>
	<br>
</p>
<p>Es tu responsabilidad actualizar tu información de registro para asegurarte de que permanezca precisa. Eres responsable de la autenticidad, integridad y precisión de la información de registro, y asumes cualquier pérdida directa o indirecta y las consecuencias adversas causadas por información de registro inexacta.</p>
<p>
	<br>
</p>
<p>Después de que se verifique y apruebe la creación de la cuenta en la plataforma, puedes transferir activos digitales a la cuenta en la plataforma y usar el servicio. Aceptas recibir correos electrónicos y/o información de nuestra parte relacionada con la administración y operación de la Plataforma.</p>
<p>
	<br>
</p>
<p>4.2 Uso de la Cuenta en la Plataforma</p>
<p>
	<br>
</p>
<p>Tienes derecho a usar la cuenta en la plataforma para acceder a la plataforma y usar los servicios, incluyendo:</p>
<p>
	<br>
</p>
<p>(i) usar los Productos en la Plataforma;</p>
<p>
	<br>
</p>
<p>(ii) enviar transacciones de activos digitales, ya sea depositados, bloqueados, hipotecados, prestados, tomados prestados u otros (cada uno una "Transacción");</p>
<p>
	<br>
</p>
<p>(iii) Completar la Transacción;</p>
<p>
	<br>
</p>
<p>(iv) usar la cuenta en la plataforma para ver la información proporcionada al usuario;</p>
<p>
	<br>
</p>
<p>(v) participar en eventos organizados por nosotros de acuerdo con las reglas de eventos relevantes; y/o</p>
<p>
	<br>
</p>
<p>(vi) otros servicios que podamos proporcionarte de vez en cuando.</p>
<p>
	<br>
</p>
<p>4.3 Seguridad de la Cuenta en la Plataforma</p>
<p>
	<br>
</p>
<p>No puedes donar, prestar, arrendar, ceder o transferir de otra manera tu Cuenta en la Plataforma a ninguna otra persona excepto con nuestro consentimiento.</p>
<p>
	<br>
</p>
<p>No estamos obligados a publicar ningún activo digital sustituto si se pierde, se roba, falla, se destruye o no está disponible de otra manera cualquier activo digital, contraseña o clave.</p>
<p>
	<br>
</p>
<p>4.4 Suspensión, Terminación o Cierre</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de suspender la provisión de algunos o todos los servicios a la Cuenta en la Plataforma, congelar activos digitales en la Cuenta en la Plataforma, terminar la Cuenta en la Plataforma, cerrar la Cuenta en la Plataforma y/o notificar a las autoridades pertinentes ("Gestión de la Cuenta en la Plataforma") en las siguientes circunstancias:</p>
<p>
	<br>
</p>
<p>(i) tenemos razones para creer que necesitamos hacerlo para proteger nuestra reputación;</p>
<p>
	<br>
</p>
<p>(ii) el uso de tu Cuenta en la Plataforma está sujeto a cualquier litigio pendiente, investigación o procedimiento gubernamental, y/o creemos que la actividad de tu Cuenta en la Plataforma tiene un alto riesgo de violar leyes o regulaciones;</p>
<p>
	<br>
</p>
<p>(iii) no eres elegible para crear una cuenta en la plataforma según lo establecido en la cláusula 4.1;</p>
<p>
	<br>
</p>
<p>(iv) creas una Cuenta en la Plataforma en nombre de una entidad legal que no sea una persona autorizada para actuar en nombre de esa entidad legal;</p>
<p>
	<br>
</p>
<p>(v) a nuestra entera discreción, no eres apto para usar los Servicios;</p>
<p>
	<br>
</p>
<p>(vi) no eres la persona indicada en la información de registro de la cuenta en la plataforma pertinente;</p>
<p>
	<br>
</p>
<p>(vii) no has iniciado sesión en la cuenta en la plataforma durante un año consecutivo;</p>
<p>
	<br>
</p>
<p>(viii) usas la

 Cuenta en la Plataforma para participar en actividades ilegales o impropias, como el lavado de dinero y el soborno;</p>
<p>
	<br>
</p>
<p>(ix) usas tu cuenta en la plataforma para participar en manipulación de mercado, comercio desleal u otras actividades comerciales ilegales, como el lavado de dinero, el contrabando y el soborno comercial; y/o</p>
<p>
	<br>
</p>
<p>(x) Tu incumplimiento de este Acuerdo o cualquier otra ley, norma o regulación aplicable.</p>

<p>
	<br>
</p>
<p>No obstante lo anterior, nos reservamos el derecho de cerrar y terminar tu cuenta en la plataforma con un aviso de 7 días hábiles para ti. Luego, tienes 7 días hábiles para tomar cualquier acción necesaria, incluida la cancelación de cualquier orden o la realización de cualquier operación ("Tiempo Pre-Cierre"). Después del tiempo antes de cerrar la posición, tenemos el derecho de cancelar tu orden, cerrar la posición y devolver los activos digitales en tu cuenta en la plataforma. No obstante cualquier cosa en este Acuerdo, si tu cuenta en la plataforma ha sido cerrada de acuerdo con estos Términos, todos los saldos de la cuenta (incluidas las tarifas y las deudas que nos debas) se nos pagarán de inmediato.</p>
<p>
	<br>
</p>
<p>No somos responsables de ninguna consecuencia adversa y/o pérdidas asociadas con el procesamiento de cuentas en la Plataforma.</p>
<p>
	<br>
</p>
<p>En caso de suspensión o cierre de cuentas en la plataforma debido a investigaciones de fraude, investigaciones ilegales o violaciones de este Acuerdo, tendremos plena custodia de activos digitales, fondos e información/datos de usuario que puedan ser entregados a las autoridades gubernamentales.</p>
<p>
	<br>
</p>
<p>5. Depósito, Fideicomiso, Retiro y Transferencia</p>
<p>
	<br>
</p>
<p>Los servicios solo están disponibles para activos digitales que respaldamos, y dichos activos digitales pueden cambiar de vez en cuando. En ningún caso debes usar tu cuenta en la Plataforma para almacenar, enviar, solicitar o recibir activos digitales en ninguna forma que no respaldemos. No somos responsables ni estamos obligados por ningún intento de utilizar cuentas en la plataforma para activos digitales que no respaldemos. Te proporcionamos servicios y no somos compradores o vendedores de ninguna transacción de activos digitales. Los servicios no incluyen ningún depósito o retiro o custodia de ninguna moneda fiduciaria.</p>
<p>
	<br>
</p>
<p>A menos que la Plataforma o este Acuerdo dispongan lo contrario, todos los activos digitales mantenidos en la Cuenta en la Plataforma se mantienen en fideicomiso por nosotros. Es decir:</p>
<p>
	<br>
</p>
<p>(i) La titularidad de los activos digitales permanece contigo y no se transfiere a nosotros. Dado que el propietario de los activos digitales es tu cuenta en la plataforma, debes asumir todo el riesgo de pérdida de dichos activos digitales. No representamos que los activos en la cuenta en la plataforma nos pertenezcan, ni consideramos tales activos como propios;</p>
<p>
	<br>
</p>
<p>(ii) No venderemos, transferiremos, prestaremos, hipotecaremos o transferiremos de otra manera activos en la Cuenta en la Plataforma a menos que lo exijan las autoridades pertinentes o se disponga de otra manera en este Acuerdo, excepto según lo indiques tú o lo ordene un tribunal de jurisdicción competente;</p>
<p>
	<br>
</p>
<p>(iii) Tú eres responsable de los activos digitales en tu cuenta en la plataforma. Puedes retirar Activos Digitales en cualquier momento enviando Activos Digitales a otra cuenta de Huobi y/o otra dirección blockchain controlada por ti o un tercero, sujeto a interrupciones, tiempo de inactividad y otras políticas aplicables;</p>
<p>
	<br>
</p>
<p>(iv) Con el fin de mantener de manera más segura los activos en las cuentas de la Plataforma, las entradas de contabilidad separadas para tu cuenta y la nuestra separan tus activos digitales de nuestros propios activos digitales o fondos. No obstante lo anterior, no estamos obligados a usar diferentes direcciones blockchain para almacenar tus activos digitales y los de otros clientes o los nuestros.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de establecer y ajustar el volumen de transacciones diarias y/o el volumen de operaciones diarias en función de consideraciones internas, incluida cualquier investigación por parte de las autoridades pertinentes, la seguridad y/o el estado de la red de activos digitales relevante y/o cualquier préstamo adeudado y pagadero (como se define en este Acuerdo) Límite de retiro.</p>
<p>
	<br>
</p>
<p>6. Hipoteca</p>
<p>
	<br>
</p>
<p>La Plataforma te permite apostar activos digitales desde una cuenta de spot ("Cuenta de Spot") a través de la Plataforma para recibir una recompensa ("Apuesta") por la verificación de la red blockchain disponible en ese momento. La plataforma te proporciona garantías con servicios técnicos. Al usar una hipoteca, aceptas:</p>
<p>
	<br>
</p>
<p>(i) eres elegible para usar la hipoteca;</p>
<p>
	<br>
</p>
<p>(ii) comprendes los riesgos de usar la garantía;</p>
<p>
	<br>
</p>
<p>(iii) no somos responsables de ninguna pérdida que puedas sufrir como resultado de la garantía, incluidos cualquier problema relacionado con la blockchain subyacente del activo digital hipotecado;</p>
<p>
	<br>
</p>
<p>(iv) Los activos digitales hipotecados pueden estar bloqueados durante un período de tiempo y es posible que no puedas retirarlos después de ser bloqueados;</p>
<p>
	<br>
</p>
<p>(v) Las recompensas mostradas en la Plataforma son solo con fines informativos y no somos responsables de ninguna discrepancia entre las recompensas mostradas y las recompensas reales que recibas;</p>
<p>
	<br>
</p>
<p>(vi) Algunos activos digitales pueden tener un período de liberación, y es posible que no recibas ninguna recompensa durante el período de liberación;</p>
<p>
	<br>
</p>
<p>(vii) Es posible que necesites hipotecar activos digitales en el menor tiempo posible;</p>
<p>
	<br>
</p>
<p>(viii) no somos responsables de ninguna pérdida que sufras como resultado de la recepción tardía de activos digitales y/o recompensas;</p>
<p>
	<br>
</p>
<p>(ix) tenemos el derecho de suspender y/o cancelar tu uso de la hipoteca en cualquier momento a nuestra entera discreción y no somos responsables de ninguna pérdida que puedas sufrir como resultado de dicha suspensión o cancelación;</p>
<p>
	<br>
</p>
<p>(x) Nos reservamos el derecho de suspender y/o eliminar cualquier activo digital disponible para el bloqueo en la Plataforma en cualquier momento a nuestra entera discreción, y no somos responsables de ninguna pérdida que sufras como resultado de dicha suspensión o eliminación.</p>
<p>
	<br>
</p>
<p>7. Primepool</p>
<p>
	<br>
</p>
<p>La plataforma te permite bloquear activos digitales en la cuenta conjunta de la plataforma por un período de tiempo ("Cuenta Conjunta") para recibir recompensas ("Primepool"). Cuantos más activos digitales bloquees, más recompensas recibirás. Puede haber un límite en la cantidad total de activos digitales que se pueden bloquear. Al usar Primepool, aceptas:</p>
<p>
	<br>
</p>
<p>(i) eres elegible para crear una cuenta conjunta y usar Primepool;</p>
<p>
	<br>
</p>
<p>(ii) comprendes los riesgos de usar Primepool;</p>
<p>
	<br>
</p>
<p>(iii) no somos responsables de ninguna pérdida que puedas sufrir por Primepool, incluidos cualquier problema relacionado con la blockchain subyacente del activo digital;</p>
<p>
	<br>
</p>
<p>(iv) Las recompensas mostradas en la Plataforma son solo con fines informativos y no somos responsables de ninguna discrepancia entre las recompensas mostradas y las recompensas reales que recibas;</p>
<p>
	<br>
</p>
<p>(v) La plataforma registrará la cantidad de tus activos digitales bloqueados diariamente. Si tus activos digitales se desbloquean durante el período de registro diario, es posible que no puedas recibir recompensas en la fecha relevante;</p>
<p>
	<br>
</p>
<p>(vi) Al final de este período, es posible que no se emitan activos digitales y/o recompensas. No somos responsables de ninguna pérdida que sufras como resultado de la recepción tardía de Activos Digitales y/o Recompensas;</p>
<p>
	<br>
</p>
<p>(vii) tenemos el derecho de suspender y/o cancelar tu uso de Primepool en cualquier momento a nuestra entera discreción y no somos responsables de ninguna pérdida que puedas sufrir como resultado de dicha suspensión o cancelación;</p>
<p>
	<br>
</p>
<p>(viii) Nos reservamos el derecho de suspender y/o eliminar cualquier activo digital disponible en la Plataforma para los Servicios de Primepool en cualquier momento a nuestra entera discreción, y no seremos responsables ante ti por cualquier pérdida sufrida como resultado de dicha suspensión o eliminación.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>8. Huobi Income</p>
<p>
	<br>
</p>
<p>La Plataforma te permite depositar activos digitales en la cuenta "Huobi Yield" ("Cuenta de Rendimiento Huobi") en la Plataforma por un período de tiempo para recibir recompensas ("Rendimiento Huobi"). Al hacer un depósito, puedes elegir un depósito fijo para bloquear activos digitales por un período de tiempo fijo; también puedes elegir un depósito actual para retirar activos digitales en cualquier momento. En general, los bonos de depósito fijo son más altos que los bonos de depósito a la vista. Además, cuanto más tiempo mantengas el depósito a plazo, más recompensas recibirás. Si la cuenta de la plataforma cumple con ciertos requisitos de token de Huobi, es posible que disfrutes de precios preferenciales. Al usar "Huobi Earnings", aceptas:</p>
<p>
	<br>
</p>
<p>(i) Eres elegible para crear una cuenta de "Huobi Earnings" y usar "Huobi Earnings";</p>
<p>
	<br>
</p>
<p>(ii) Comprendes los riesgos asociados con el uso de "Huobi Income";</p>
<p>
	<br>
</p>
<p>(iii) No somos responsables de ninguna pérdida que puedas sufrir por "Huobi Earnings", incluidos cualquier problema relacionado con la blockchain subyacente del activo digital;</p>
<p>
	<br>
</p>
<p>(iv) Si eliges un depósito fijo, los activos digitales pueden estar bloqueados por un período de tiempo y es posible que no puedas retirarlos después de bloquearlos;</p>
<p>
	<br>
</p>
<p>(v) Las recompensas mostradas en la Plataforma son solo con fines informativos y no somos responsables de ninguna discrepancia entre las recompensas mostradas y las recompensas reales que recibas;</p>
<p>
	<br>
</p>
<p>(vi) Es posible que necesites hipotecar activos digitales en el menor tiempo posible;</p>
<p>
	<br>
</p>
<p>(vii) no somos responsables de ninguna pérdida que sufras como resultado de la recepción tardía de activos digitales y/o recompensas;</p>
<p>
	<br>
</p>
<p>(viii) tenemos el derecho de suspender y/o cancelar tu uso de "Huobi Earnings" en cualquier momento a nuestra entera discreción, y no somos responsables de ninguna pérdida que puedas sufrir como resultado de dicha suspensión o cancelación;</p>
<p>
	<br>
</p>
<p>(ix) Nos reservamos el derecho de suspender y/o eliminar cualquier activo digital disponible en la plataforma para "Huobi Earnings" en cualquier momento a nuestra entera discreción, y no somos responsables de ninguna pérdida que puedas sufrir como resultado de dicha suspensión o eliminación.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>9. Préstamo de Criptomonedas</p>
<p>
	<br>
</p>
<p>La Plataforma te permite empeñar activos digitales como garantía ("Margen") para tomar prestados otros activos digitales ("Préstamos de Criptomonedas"). Después de seleccionar el activo digital como garantía, la cantidad del activo digital y el plazo del préstamo, puedes empeñar el activo digital y luego obtener un préstamo ("préstamo") en el activo digital de tu elección. Puedes usar el préstamo a tu discreción, incluida la ejecución de transacciones y la retirada del préstamo en la plataforma. Al usar préstamos de criptomonedas, aceptas:</p>
<p>
	<br>
</p>
<p>(i) eres elegible para préstamos de criptomonedas;</p>
<p>
	<br>
</p>
<p>(ii) comprendes los riesgos asociados con el uso de préstamos de criptomonedas;</p>
<p>
	<br>
</p>
<p>(iii) no somos responsables de ninguna pérdida que puedas sufrir por préstamos de criptomonedas;</p>
<p>
	<br>
</p>
<p>(iv) tenemos el derecho de establecer el valor de tu Margen a nuestra entera discreción y no tenemos la obligación de informarte sobre cualquier cambio en el valor del Margen;</p>
<p>
	<br>
</p>
<p>(v) Todos los depósitos están sujetos a gravámenes generales y derechos de compensación a nuestro favor con respecto a cualquier y todas las obligaciones, pasivos o fondos adeudados a ti en cualquier momento y adeudados a nosotros bajo este Acuerdo o de otra manera. Tenemos el derecho de honrar tu depósito de seguridad de acuerdo con las disposiciones de este Acuerdo;</p>
<p>
	<br>
</p>
<p>(vi) tenemos el derecho de depositar, empeñar nuevamente, hipotecar, empeñar nuevamente, invertir o prestar cualquier margen proporcionado a nosotros, pero no tenemos la obligación de informarte sobre cualquier interés, ingreso o beneficio que pueda surgir de ello.</p>
<p>
	<br>
</p>
<p>(vii) no te pagaremos ningún interés sobre ningún margen depositado por ti en virtud de este Acuerdo y retendremos todo ese interés y beneficio para nuestro propio beneficio;</p>
<p>
	<br>
</p>
<p>(viii) no seremos responsables de ninguna pérdida de margen que surja directa o indirectamente de cualquier cierre de terceros, quiebra, insolvencia, liquidación, administración judicial u otro evento en el que se deposite o empeñe el margen;</p>
<p>
	<br>
</p>
<p>(ix) nos reservamos el derecho de variar o cancelar, en parte o en su totalidad, el monto de cualquier préstamo que te otorguemos;</p>
<p>
	<br>
</p>
<p>(x) no estamos obligados a proporcionar o continuar proporcionando ningún préstamo a ti;</p>
<p>
	<br>
</p>
<p>(xi) aceptas mantener un monto mínimo de margen en todo momento para lograr la Proporción de Margen de la Plataforma ("Proporción de Margen") que estipulamos de vez en cuando en relación con el Préstamo.</p>
<p>
	<br>
</p>
<p>(xii) serás responsable de transferir margen adicional para mantener el préstamo;</p>
<p>
	<br>
</p>
<p>(xiii) si la Proporción de Margen asociada con el Préstamo cae por debajo de la Proporción de Margen Mínima, tenemos el derecho, a nuestra entera discreción, de liquidar tu Margen sin previo aviso a ti ("Liquidación");</p>
<p>
	<br>
</p>
<p>(xiv) deberás asumir todos los costos y/o pérdidas asociados con cualquier liquidación;</p>
<p>
	<br>
</p>
<p>(xv) si el margen en la venta es insuficiente para pagar el préstamo y los intereses devengados, serás responsable de cualquier deuda pendiente;</p>
<p>
	<br>
</p>
<p>(xvi) tenemos el derecho de suspender y/o cancelar tu uso de Préstamos de Criptomonedas en cualquier momento a nuestra entera discreción y no somos responsables de ninguna pérdida que puedas sufrir como resultado de dicha suspensión o cancelación;</p>
<p>
	<br>
</p>
<p>(xvii) Nos reservamos el derecho de suspender y/o eliminar cualquier activo digital disponible para préstamos de criptomonedas en la Plataforma en cualquier momento a nuestra entera discreción y no somos responsables de ninguna pérdida que puedas sufrir como resultado de dicha suspensión o eliminación.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>10. Cálculo</p>
<p>
	<br>
</p>
<p>No obstante cualquier cosa en este Acuerdo y las Reglas de Negociación de la Plataforma, a menos que indiquemos lo contrario, aceptas que todos los cálculos realizados por la Plataforma son cálculos finales y concluyentes.</p>
<p>
	<br>
</p>
<p>11. Tarifas y Seguro</p>
<p>
	<br>
</p>
<p>Tenemos el derecho de cobrar intereses sobre cualquier préstamo que te hagamos a una tasa que podamos determinar de vez en cuando.</p>
<p>
	<br>
</p>
<p>Debes pagar puntualmente cualquier tarifa, interés y comisión ("Tarifas") adeudadas a nosotros y/o a nuestros Proveedores de Servicios de la manera que podamos estipular de vez en cuando en relación con el uso de los Servicios. Nos reservamos el derecho de modificar las tarifas a nuestra entera discreción sin previo aviso a ti, y es tu responsabilidad asegurarte de estar al tanto de las tarifas asociadas con los Servicios.</p>
<p>
	<br>
</p>
<p>Aceptas que cualquier tarifa pendiente puede ser liquidada debitando la cantidad relevante de tu cuenta de Plataforma u otras cuentas que puedas tener con nosotros; también aceptas que las tarifas pendientes son inmediatamente exigibles y pagaderas a las partes pertinentes.</p>
<p>
	<br>
</p>
<p>Si no puedes pagar alguna tarifa pendiente debido a los activos digitales relevantes, tenemos el derecho de pagar al tipo de cambio que determinemos en el momento del pago a cualquier otra cantidad retenida en tu Cuenta de Plataforma o otras cuentas que tengas con nosotros en activos digitales para el pago o la devolución.</p>
<p>
	<br>
</p>
<p>Todos los impuestos, derechos, gastos, costos y/o otras tarifas aplicables que incurramos como resultado de tu uso de los Servicios son tu responsabilidad.</p>
<p>
	<br>
</p>
<p>12. Conducta Inadecuada en el Mercado</p>

<p>
	<br>
</p>
<p>Bajo ninguna circunstancia debes participar en lo que consideramos ser conducta inadecuada en el mercado ("Conducta Inadecuada en el Mercado"), incluyendo pero no limitado a:</p>
<p>
	<br>
</p>
<p>(i) participar en cualquier forma de manipulación del mercado, como órdenes falsas, transacciones ficticias o manipulación de precios;</p>
<p>
	<br>
</p>
<p>(ii) tomar cualquier acción que cree una representación falsa o engañosa de una transacción o precio;</p>
<p>
	<br>
</p>
<p>(iii) tomar cualquier acción para mantener precios artificiales o manipular precios;</p>
<p>
	<br>
</p>
<p>(iv) divulgar información falsa o engañosa sobre la Plataforma; y/o</p>
<p>
	<br>
</p>
<p>(v) tomar o abstenerse de tomar acciones que puedan constituir fraude.</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de utilizar sistemas de monitoreo para supervisar tu actividad en la Plataforma y tomar cualquier acción que consideremos necesaria para abordar cualquier conducta inadecuada en el mercado real o potencial.</p>
<p>
	<br>
</p>
<p>13. Acceso a la API</p>
<p>
	<br>
</p>
<p>Dependiendo de tus calificaciones, se te puede otorgar acceso a la API para usar servicios en la plataforma. Nos reservamos el derecho de limitar, suspender o terminar cualquier acceso a la API otorgado a ti. Si utilizas la API para acceder a los Servicios, aceptas que no seremos responsables de ninguna pérdida que sufras por tu uso de la API relevante.</p>
<p>
	<br>
</p>
<p>14. Sin Recompensas de Airdrop</p>
<p>
	<br>
</p>
<p>Aceptas que eres elegible para recibir cualquier Recompensa de Airdrop ("Recompensas de Airdrop"); no tienes derechos legales ni equitativos sobre tales Recompensas de Airdrop ("Eventos de No Elegibilidad") con respecto a cualquier activo digital que tomes prestado.</p>
<p>
	<br>
</p>
<p>Si recibes cualquier recompensa de airdrop basada en cualquier evento de no elegibilidad, es tu responsabilidad devolver cualquier recompensa de airdrop a nosotros y nos reservamos el derecho de transferir las recompensas de airdrop de tu cuenta de plataforma a nuestra billetera propietaria a nuestra entera discreción. Podemos, a nuestra entera discreción, restringir tu retiro de activos digitales de la Plataforma antes de realizar dichas transferencias.</p>
<p>
	<br>
</p>
<p>&nbsp;</p>
<p>
	<br>
</p>
<p>15. Terminación y Remedios por Incumplimiento</p>
<p>
	<br>
</p>
<p>Tenemos el derecho de terminar todos los servicios que te proporcionamos en cualquier momento de acuerdo con este Acuerdo, y este Acuerdo también se terminará en la fecha relevante de terminación de todos los servicios. Tienes el derecho de terminar el uso de los servicios que te proporcionamos en cualquier momento, y este acuerdo también se terminará en la fecha relevante cuando termines el uso de los servicios. Después de la terminación de este acuerdo, aceptas que no tienes derecho a exigirnos que continuemos proporcionándote servicios o que realicemos cualquier otra obligación, incluida pero no limitada a exigirnos que retengamos cualquier información en la cuenta de la plataforma o que divulguemos tal información a ti, o que te o cualquier tercero divulgue cualquier información no leída o no enviada en la cuenta de la plataforma.</p>
<p>
	<br>
</p>
<p>16. Incumplimiento del Acuerdo</p>
<p>
	<br>
</p>
<p>Si violas este Acuerdo o cualquier ley, regla o regulación aplicable, deberás pagarnos al menos $2 millones en daños y serás responsable de todos los costos, incluidos los honorarios legales ("Indemnización"), que incurramos como resultado de dicho incumplimiento. Si la compensación es insuficiente para cubrir nuestras pérdidas, serás responsable de cualquier cantidad más allá de la compensación.</p>
<p>
	<br>
</p>
<p>Para evitar dudas, la terminación de este Acuerdo no impedirá que la parte no observante requiera que la parte incumplidora asuma otras responsabilidades que surjan fuera de este Acuerdo.</p>
<p>
	<br>
</p>
<p>17. Notificaciones y Comunicaciones</p>
<p>
	<br>
</p>
<p>Los usuarios se comprometen a verificar los anuncios, materiales e información en la plataforma de manera oportuna y regular. No seremos responsables de ninguna forma de compensación si sufres pérdidas personales debido a la negligente omisión de leer anuncios, materiales e información.</p>
<p>
	<br>
</p>
<p>18. Otros</p>
<p>
	<br>
</p>
<p>(i) Cesión</p>
<p>
	<br>
</p>
<p>Los derechos y obligaciones estipulados en este Acuerdo son igualmente vinculantes para los cesionarios, sucesores, ejecutores y administradores que se beneficien de dichos derechos y obligaciones. No puedes ceder ninguno de tus derechos u obligaciones bajo este Acuerdo a ningún tercero sin nuestro consentimiento, sin embargo, podemos transferir nuestros derechos u obligaciones bajo este Acuerdo en cualquier momento con 30 días de antelación a ti. Los derechos y obligaciones se asignan a cualquier tercero.</p>
<p>
	<br>
</p>
<p>(ii) Fuerza Mayor</p>
<p>
	<br>
</p>
<p>Debido al mantenimiento del equipo de la red de información, falla de conexión de la red de información, errores informáticos, fallas del sistema como comunicaciones, fallas de energía, condiciones climáticas, accidentes, acciones laborales, disputas laborales, rebeliones, levantamientos, disturbios, falta de productividad o materiales de producción, incendios, inundaciones, tormentas, explosiones, guerras, fallas de bancos u otros socios, colapso de mercados de activos digitales, acciones de gobiernos, autoridades judiciales o ejecutivas, otras acciones más allá de nuestro control o más allá de nuestro control, o debido a terceros, En caso de que no podamos proporcionar servicios, no seremos responsables de dicha falla o demora en la prestación de servicios para ti, ni de ninguna pérdida que puedas sufrir como resultado de dicha falla o demora en la prestación de servicios.</p>
<p>
	<br>
</p>
<p>(iii) Relación entre las partes</p>
<p>
	<br>
</p>
<p>Este Acuerdo solo es vinculante en los derechos y obligaciones entre tú y nosotros, y no implica relaciones legales y disputas legales que surjan de o estén relacionadas con transacciones entre usuarios de este sitio web y otras partes contigo.</p>
<p>
	<br>
</p>
<p>Excepto según se disponga en este Acuerdo, nada en este Acuerdo se considerará que nos hace, implica que somos, o de otra manera nos trata como tu agente, fiduciario u otro representante.</p>
<p>
	<br>
</p>
<p>(iv) Acuerdo Completo</p>
<p>
	<br>
</p>
<p>Si alguna disposición de este Acuerdo es considerada por cualquier tribunal de jurisdicción competente como inaplicable, inválida o ilegal, la validez de las disposiciones restantes de este Acuerdo no se verá afectada.</p>
<p>
	<br>
</p>
<p>(v) Enmiendas</p>
<p>
	<br>
</p>
<p>Nos reservamos el derecho de determinar, enmendar o modificar cualquier contenido de este Acuerdo en cualquier momento a nuestra entera discreción. La fecha y hora mostradas en la sección "Última actualización" de este Acuerdo se refiere al momento en que ocurrieron cualquier cambio en los términos contenidos en este Acuerdo y el contenido de este Acuerdo. El acuerdo revisado entrará en vigor inmediatamente después de su publicación en la plataforma. Aunque hemos tomado medidas razonables para garantizar la precisión de la información en la Plataforma, no garantizamos el nivel de precisión y no somos responsables de ningún retraso o falla causada por la información en la Plataforma o por la imposibilidad de conectarse a Internet, transmitir o recibir cualquier aviso e información y cualquier pérdida incurrida directa o indirectamente.</p>
<p>
	<br>
</p>
<p>Es tu responsabilidad estar al tanto de las últimas revisiones de este Acuerdo. Si no estás de acuerdo con el contenido revisado, deja de utilizar el servicio inmediatamente. Al continuar utilizando el servicio, se considera que aceptas los términos de este acuerdo.</p>
<p>
	<br>
</p>
<p>(vi) Supervivencia</p>
<p>
	<br>
</p>
<p>Todas las disposiciones de este Acuerdo que, por su naturaleza, sobrevivan a la expiración o terminación de este Acuerdo, sobrevivirán a la expiración o terminación de este Acuerdo y sobrevivirán a la expiración o terminación de este Acuerdo.</p>
<p>
	<br>
</p>
<p>(vii) No Renuncia</p>
<p>
	<br>
</p>
<p>Nuestra o tu renuncia a la responsabilidad de la otra parte por incumplimiento de este acuerdo o los términos de este acuerdo no se interpretará ni se considerará como una renuncia al derecho de la otra parte de perseguir cualquier otra responsabilidad por incumplimiento de contrato. El no ejercicio de ningún derecho o la adopción de cualquier remedio no se interpretará de ninguna manera como una renuncia a dicho derecho o remedio.</p>
<p>
	<br>
</p>
<p>(viii) Ley Aplicable</p>
<p>
	<br>
</p>
<p>Este Acuerdo y la relación con nosotros se rigen y se interpretan de acuerdo con las leyes de Seychelles.</p>

 
`
}