
// Format Time
export function formatDate(cellValue) {
    if (cellValue == null || cellValue == "") return "";
    var date = new Date(cellValue) 
    var year = date.getFullYear()
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() 
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() 
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() 
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  }


  function countTIme(e){
    const offsetMinutes = new Date().getTimezoneOffset();
    // 计算时区（注意：这只是一种近似方法，可能不够精确）
    const hours = Math.abs(offsetMinutes / 60);
    const suffix = offsetMinutes < 0 ? "+" : "-";
    const timeZone = Number(`${suffix}${hours}`);

    const originalTime = new Date(e).getTime()/1000;
    const originalOffset =  localStorage.getItem('timeZone') * 60; // -4时区的偏移量（以分钟为单位）
    const targetOffset = timeZone * 60; // +8时区的偏移量（以分钟为单位）

    const targetTime = convertTimezone(originalTime, originalOffset, targetOffset);
    return formatDate(targetTime)
  }
  function convertTimezone(originalTimeInSeconds, originalOffsetMinutes, targetOffsetMinutes) {
   // 将原始时间戳转换为毫秒
   const originalTimeInMilliseconds = originalTimeInSeconds * 1000;
  
  // 计算原始时区偏移量和目标时区偏移量之间的差异（以毫秒为单位）
  const offsetDifferenceMilliseconds = (targetOffsetMinutes - originalOffsetMinutes) * 60000;
  
  // 计算目标时区的时间戳（以毫秒为单位）
  const targetTimeInMilliseconds = originalTimeInMilliseconds + offsetDifferenceMilliseconds;
  
  // 创建目标时区的日期对象
  const targetDate = new Date(targetTimeInMilliseconds);
  
  // 返回目标时区的时间字符串
  return targetDate.toISOString();
}
export default{
    formatDate,
    countTIme
}