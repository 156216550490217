const getUrl = ()=>{
	const hostArr = window.location.hostname.split('.')
	let str = 'https://'
	hostArr.forEach((host,k) => {
			if ( k === 0 ) {
					str += `api`
			}else{
					str += `.${host}`
			}

	})
  console.log(str)
	return str
}

export default {
	baseUrl: process.env.NODE_ENV ==='development' ? 'http://192.168.0.29:8081' : getUrl(),
	version: '1.0.0',
	tokenKey: 'cx-token',
	userInfoKey: 'cx-userInfo'
}
